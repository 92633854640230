<template>
  <div
    class="message-row"
    v-bind:class="{ 'highlighted-message': !!message.bodyWithHighlight }"
  >
    <div
      v-if="isFirstUnread(message, prevMessage)"
      class="unread-messages-separator"
    >
      {{ t('messages.unread') }}
    </div>
    <div
      v-if="!prevMessage || shouldInsertDate(message, prevMessage)"
      class="message-block-date"
    >
      {{ formatDateSeparator(message.createdAt) }}
    </div>
    <div
      v-if="
        !prevMessage ||
        message.user.id !== prevMessage?.user.id ||
        shouldSplitMessages(message, prevMessage, nextMessage, false) ||
        isFirstUnread(message, prevMessage)
      "
    >
      <div
        v-if="isCurrentUser(message.user.id) || isServiceMessage(message.type)"
        class="message-info"
      />
      <div v-else class="message-info" :class="{ large }">
        <Avatar
          :small="!large"
          :src="message.user.avatar"
          :archived="message.user.archived"
        />
        <span class="user-name" :class="{ archived: message.user.archived }">
          {{ message.user.name }}
        </span>
        <span class="user-last-seen">
          {{ time(new Date(message.createdAt), locale) }}
        </span>
      </div>
    </div>
    <div
      class="message-text"
      v-bind:class="{
        'current-user-message':
          isCurrentUser(message.user.id) && !isServiceMessage(message.type),
        'service-message': isServiceMessage(message.type),
      }"
    >
      <div class="message-text-container">
        <div v-if="message.status === 'ERROR'" class="information-icon">
          <InformationIcon />
        </div>
        <div
          class="message-text-content"
          :class="{
            large,
            sent: message.status === 'PROCESSING',
            error: message.status === 'ERROR',
          }"
          @click.stop="(event) => openDropdownIfAvailable(event, message)"
        >
          <div
            v-if="message.parent"
            class="reply-message-info"
            @click.stop="clickReplyMessage()"
          >
            <div class="reply-user-name">
              {{ message.parent.user.name }}
            </div>
            <div class="reply-user-message" v-html="message.parent.body" />
          </div>
          <div></div>
          <div
            v-html="
              isServiceMessage(message.type)
                ? generatedMessage &&
                  convertSymbolToCharacterSequence(generatedMessage.body)
                : highlightedText
            "
          ></div>
        </div>
      </div>
      <div
        v-if="shouldShowDate(message, prevMessage, nextMessage)"
        class="self-message-date"
      >
        <div>
          {{ time(new Date(message.createdAt), locale) }}
        </div>
        <div class="icon-container">
          <ClockIcon v-if="message.status === 'PROCESSING'" class="clock" />
          <DoneIcon v-else-if="message.status === 'SENT'" class="done" />
          <DoneAllIcon v-else-if="message.status === 'READ'" class="done-all" />
          <span v-else-if="message.status === 'ERROR'">
            {{ t('messages.error') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, Ref, inject, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import useTextHighlight from '@/compositions/useTextHighlight'
  import useServiceMessage from '@/compositions/message/useServiceMessage'
  import { serviceType } from '@/const'
  import ClockIcon from '@/icons/clock.svg'
  import DoneIcon from '@/icons/done.svg'
  import DoneAllIcon from '@/icons/doneAll.svg'
  import InformationIcon from '@/icons/information.svg'
  import { MessageType, UserType } from '@/types'
  import {
    time,
    fullStringDate,
    convertToLocaleDateString,
    convertSymbolToCharacterSequence,
  } from '@/utils'

  export default defineComponent({
    components: {
      Avatar,
      ClockIcon,
      DoneIcon,
      DoneAllIcon,
      InformationIcon,
    },
    props: {
      message: {
        type: Object as PropType<MessageType>,
        required: true,
      },
      nextMessage: Object as PropType<MessageType>,
      prevMessage: Object as PropType<MessageType>,
      large: {
        type: Boolean,
        default: false,
      },
      searchMessageText: {
        type: String,
        default: '',
      },
    },
    emits: ['openDropdown', 'clickReplyMessage'],
    setup(props, { emit }) {
      const { t, locale } = useI18n()
      const { message, searchMessageText } = toRefs(props)
      const currentUser = inject<Ref<UserType>>('currentUser')
      const currentUserId = computed(() => currentUser?.value.id)
      const highlightedText = useTextHighlight(
        message.value.body,
        searchMessageText,
      )
      const { buildMessage } = useServiceMessage()
      const generatedMessage = computed(() => buildMessage(message.value))

      const isCurrentUser = (userId: string) => {
        return userId === currentUserId.value
      }

      const isServiceMessage = (messageType: string | undefined) => {
        return messageType === serviceType
      }

      const openDropdownIfAvailable = (
        event: UIEvent,
        message: MessageType,
      ) => {
        if (!isServiceMessage(message.type)) {
          emit('openDropdown', event, message)
        }
      }

      const shouldSplitMessages = (
        curMessage: MessageType,
        prevMessage: MessageType | undefined,
        nextMessage: MessageType | undefined,
        self: boolean,
      ) => {
        const curDate = convertToLocaleDateString(
          new Date(curMessage.createdAt),
        )
        const prevDate =
          prevMessage?.createdAt &&
          convertToLocaleDateString(new Date(prevMessage?.createdAt))
        const nextDate =
          nextMessage?.createdAt &&
          convertToLocaleDateString(new Date(nextMessage?.createdAt))

        if (self) {
          return (
            (nextMessage && curMessage.user.id !== nextMessage.user.id) ||
            curDate !== nextDate ||
            !nextMessage
          )
        }

        return (
          (curDate !== prevDate &&
            !isCurrentUser(curMessage.user.id) &&
            !isCurrentUser(prevMessage?.user.id ?? '0')) ||
          prevMessage?.type === 'SERVICE'
        )
      }

      const shouldInsertDate = (
        curMessage: MessageType,
        prevMessage: MessageType,
      ) => {
        const curDate = fullStringDate(
          new Date(curMessage.createdAt),
          locale.value,
        )
        const prevDate = fullStringDate(
          new Date(prevMessage?.createdAt),
          locale.value,
        )

        return curDate !== prevDate
      }

      const formatDateSeparator = (date: string) => {
        return fullStringDate(new Date(date), locale.value) ===
          fullStringDate(new Date(), locale.value)
          ? t('messages.today')
          : fullStringDate(new Date(date), locale.value)
      }

      const isFirstUnread = (
        curMessage: MessageType,
        prevMessage?: MessageType,
      ) => {
        return (
          !isCurrentUser(curMessage.user.id) &&
          !curMessage.read &&
          prevMessage &&
          prevMessage.read
        )
      }

      const clickReplyMessage = () => {
        emit('clickReplyMessage', message?.value?.parent)
      }

      const shouldShowDate = (
        message: MessageType,
        prevMessage?: MessageType,
        nextMessage?: MessageType,
      ) => {
        if (isServiceMessage(message.type)) {
          return false
        }
        return (
          (!prevMessage && isCurrentUser(message.user.id)) ||
          (isCurrentUser(message.user.id) &&
            shouldSplitMessages(message, prevMessage, nextMessage, true))
        )
      }

      return {
        currentUserId,
        highlightedText,
        generatedMessage,
        isCurrentUser,
        isServiceMessage,
        openDropdownIfAvailable,
        shouldSplitMessages,
        shouldInsertDate,
        formatDateSeparator,
        isFirstUnread,
        clickReplyMessage,
        shouldShowDate,
        time,
        fullStringDate,
        convertToLocaleDateString,
        t,
        locale,
        convertSymbolToCharacterSequence,
      }
    },
  })
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .message-row {
    transition: background 2500ms ease-in-out;
    &.highlighted-message {
      background: $conversation-selected;
      transition: background 250ms ease-in-out;
    }
    :deep(.highlighted) {
      background: $away-point;
    }
  }

  .unread-messages-separator {
    @include font-caption-3;
    margin: 24px 16px;
    color: $secondary-text;
    display: flex;
    height: 15px;

    &::before {
      content: '';
      background: linear-gradient(to left, $message-background, $background);
      flex: 1;
      height: 1px;
      margin-top: 6px;
      margin-right: 12px;
    }
    &::after {
      content: '';
      background: linear-gradient(to right, $message-background, $background);
      flex: 1;
      height: 1px;
      margin-top: 6px;
      margin-left: 12px;
    }
  }

  .message-info {
    @include font-caption-3;
    display: flex;
    position: relative;
    margin-top: 20px;
    padding-left: 42px;
    height: 15px;

    &.large {
      padding-left: 55px;
      padding-top: 4px;

      .avatar {
        position: absolute;
        top: -2px;
        left: 0;
      }
    }

    .avatar {
      position: absolute;
      top: -2px;
      left: 9px;
    }

    .user-name {
      color: $primary-text;
      font-weight: $font-weight-bold;
      margin-right: 8px;

      &.archived {
        color: $secondary-text;
      }
    }

    .user-last-seen {
      color: $secondary-text;
    }
  }

  .message-block-date {
    @include font-caption-3;
    color: $secondary-text;
    text-align: center;
    width: 100%;
    height: 15px;
    margin: 18px 0 22px 0;
  }

  .message-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message-text-content {
      @include font-body-1;
      font-size: 15px;
      border-radius: 10px;
      border-top-left-radius: 0;
      padding: 12px 16px;
      margin: 4px;
      margin-left: 42px;
      margin-right: 38px;
      background: $message-background;
      text-align: left;
      word-break: break-word;
      white-space: pre-wrap;

      &.large {
        margin-left: 52px;
      }
    }

    .reply-message-info {
      border-left: 1px solid $toggle-button;
      padding: 4px 0 4px 8px;
      margin-bottom: 8px;
      min-width: 160px;
      cursor: pointer;

      .reply-user-name {
        @include font-caption-3;
        width: 0;
        color: $toggle-button;
        font-weight: $font-weight-bold;
        min-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .reply-user-message {
        @include font-body-2;
        width: 0;
        min-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.current-user-message {
      text-align: right;
      align-items: flex-end;

      .message-text-container {
        display: flex;
        .information-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            fill: $error;
            height: 17px;
            width: 17px;
          }
        }

        .message-text-content {
          @include font-body-1;
          font-size: 15px;
          border-radius: 10px;
          border-bottom-right-radius: 0;
          background: $user-message-background;
          margin-right: 16px;

          &.sent {
            opacity: 0.5;
          }

          &.error {
            background: rgba($error, 0.1);
            margin-left: 10px;
          }
        }
      }
    }

    &.service-message {
      & .message-text-content {
        background: none;
        text-align: center;
        font-family: Lato;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: #8c8f94;
        padding: 4px 16px;
      }
      & .message-text-container {
        margin: auto;
      }
    }

    .self-message-date {
      @include font-caption-3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary-text;
      text-align: right;
      margin: 8px 16px 8px 0;

      .icon-container {
        @include font-caption-3;
        display: flex;
        min-width: 15px;
        min-height: 15px;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        color: $error;

        svg {
          fill: $secondary-text;

          &.clock {
            height: 13px;
            width: 13px;
          }

          &.done {
            height: 8px;
            width: 11px;
          }

          &.done-all {
            height: 8px;
            width: 15px;
          }
        }
      }
    }
  }
</style>
