import { gql } from '@apollo/client/core'

export const UPDATE_USER_LAST_SEEN_AT = gql`
  mutation {
    updateUserLastSeenAt {
      lastSeenAt
    }
  }
`

export const UPDATE_USER_SETTINGS = gql`
  mutation updateWidgetSettings($input: UpdateWidgetSettingsInput!) {
    updateWidgetSettings(input: $input) {
      widgetSettings {
        coordinates {
          x
          y
        }
        expanded
      }
    }
  }
`

export const UPDATE_USER_FULLSCREEN_SETTINGS = gql`
  mutation updateFullScreenSettings($input: UpdateFullScreenSettingsInput!) {
    updateFullScreenSettings(input: $input) {
      fullScreenSettings {
        showSidebar
      }
    }
  }
`

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings(
    $input: UpdateNotificationSettingsInput!
  ) {
    updateNotificationSettings(input: $input) {
      notificationSettings {
        muteAll
      }
    }
  }
`
