<template>
  <BackArrow class="back-to-main-button" alt="Go back" />
</template>

<script lang="ts">
  import BackArrow from '@/icons/back.svg'

  export default {
    components: {
      BackArrow,
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';

  .back-to-main-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $secondary-text;
    &:hover {
      fill: $primary-text;
    }
  }
</style>
