<template>
  <div
    class="conversation-list-item"
    :class="{
      selected: conversation.id === currentConversationId,
    }"
  >
    <div class="conversation-item">
      <div class="avatar-layout">
        <Avatar
          :name="isGroup && conversationName"
          :src="
            isGroup
              ? conversation.messages?.nodes[0]?.user.avatar
              : conversation.avatarUrl
          "
          :group="isGroup"
          :lastSeen="
            conversation.type === 'PRIVATE'
              ? conversation.users?.edges[0]?.node.lastSeenAt
              : ''
          "
          :status="
            conversation.type === 'PRIVATE'
              ? conversation.users?.edges[0]?.node.status
              : ''
          "
          :mute="conversation.mute"
          :archived="
            (conversation.type === 'PRIVATE' &&
              !conversation.users?.edges[0]) ||
            conversation.users?.edges[0]?.node.archived ||
            conversation.messages?.nodes[0]?.user.archived
          "
        />
      </div>
      <div class="info-layout">
        <div
          :class="{
            'conversation-info': true,
            search: input && input.trim() != '',
          }"
        >
          <div class="conversation-name-container">
            <div v-if="isSyncGroup" class="sync-icon">
              <SynchronizeIcon />
            </div>
            <span
              class="conversation-name truncate"
              :class="{ 'full-screen': fullScreen, mute: conversation.mute }"
              v-html="input ? conversationSearchName : conversationName"
            ></span>
            <div v-if="conversation.mute" class="icon">
              <UnmuteIcon />
            </div>
          </div>
          <div
            v-if="conversation.messages?.nodes[0]"
            :class="
              fullScreen ? 'last-message full-screen' : 'last-message widget'
            "
          >
            <div
              v-if="!isServiceMessage(conversation.messages?.nodes[0]?.type)"
            >
              <div
                v-if="
                  conversation.messages?.nodes[0]?.user.id === currentUser?.id
                "
                class="last-message-body"
              >
                {{ t('conversation.you') }}&nbsp;
              </div>
              <div v-else>
                <div v-if="isGroup" class="last-message-body">
                  {{
                    truncateUserName(
                      conversation.messages?.nodes[0]?.user.name,
                    )
                  }}:&nbsp;
                </div>
              </div>
            </div>
            <div
              class="last-message-body"
              :class="fullScreen ? 'truncate full-screen' : 'truncate widget'"
              v-html="
                lastMessage && isServiceMessage(lastMessage.type)
                  ? convertSymbolToCharacterSequence(lastMessage.body)
                  : lastMessage?.body
              "
            ></div>
          </div>
        </div>
        <div
          v-if="conversation.messages?.nodes[0]"
          class="conversation-sidebar"
        >
          <div class="last-message-data">
            {{ dateFormat(conversation.messages?.nodes[0]?.createdAt, locale) }}
          </div>
          <UnreadCount
            v-if="conversation.unreadMessagesCount"
            :unreadCount="conversation.unreadMessagesCount"
            :mute="conversation.mute"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, PropType, Ref, toRefs, computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import UnreadCount from '@/components/UnreadCount.vue'
  import useTextHighlight from '@/compositions/useTextHighlight'
  import useServiceMessage from '@/compositions/message/useServiceMessage'
  import { serviceType } from '@/const'
  import SynchronizeIcon from '@/icons/synchronize.svg'
  import UnmuteIcon from '@/icons/unmute.svg'
  import { DialogType, UserType } from '@/types'
  import {
    dateFormat,
    getConversationName,
    truncate,
    truncateUserName,
    convertSymbolToCharacterSequence,
  } from '@/utils'

  export default defineComponent({
    components: {
      Avatar,
      UnreadCount,
      UnmuteIcon,
      SynchronizeIcon,
    },
    props: {
      conversation: {
        type: Object as PropType<DialogType>,
        required: true,
      },
      input: String,
      fullScreen: Boolean,
      currentConversationId: String,
    },
    setup(props) {
      const { t, locale } = useI18n()
      const { conversation, input } = toRefs(props)
      const currentUser = inject<Ref<UserType>>('currentUser')

      const { buildMessage } = useServiceMessage()
      const lastMessage = computed(() =>
        buildMessage(conversation.value.messages?.nodes[0]),
      )

      const defaultConversationName = computed(() => {
        if (conversation.value.users && currentUser) {
          const { name, count } = getConversationName(
            conversation.value.users,
            currentUser.value.id,
          )
          return count
            ? t('conversation.andMore', { name, count })
            : t('conversation.andYou', { name })
        }

        return ''
      })

      const conversationName = computed(
        () => conversation.value.name || defaultConversationName.value,
      )

      const isGroup = computed<boolean>(() => {
        return (
          conversation?.value?.type === 'GROUP' ||
          conversation?.value?.type === 'CUSTOM'
        )
      })

      const isSyncGroup = computed<boolean>(
        () => conversation?.value?.type === 'GROUP',
      )

      const conversationSearchName = useTextHighlight(
        conversationName.value ?? '',
        input,
      )

      return {
        conversationName,
        conversationSearchName,
        lastMessage,
        currentUser,
        isGroup,
        isSyncGroup,
        locale,
        t,
        convertSymbolToCharacterSequence,
      }
    },
    methods: {
      truncate,
      dateFormat,
      truncateUserName,
      isServiceMessage(messageType: string | undefined): boolean {
        return messageType === serviceType
      },
    },
  })
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .conversation-list-item {
    display: flex;
    width: 100%;

    &:hover {
      background-color: $conversation-hover;
    }

    &.selected {
      background-color: $conversation-selected;
    }

    .conversation-item {
      cursor: pointer;
      display: flex;
      width: 100%;
      padding: 14px 16px;
      height: 42px;
      box-sizing: content-box;

      .info-layout {
        display: flex;
        flex: 1;
        align-items: space-between;
        justify-content: space-between;
        width: 100%;
      }

      .conversation-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        &.search {
          color: $secondary-text;
        }
      }
    }
  }

  .conversation-name-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .conversation-name {
      @include font-caption-2;
      line-height: 19px;
      display: inline-block;
      align-items: center;
      max-width: 180px;

      & :deep(.highlighted) {
        display: contents;
        color: $primary-text;
        background-color: $background;
      }

      &.mute {
        color: $secondary-text;
      }
    }

    .sync-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      fill: $secondary-text;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      fill: $secondary-text;
      width: 19px;
      height: 19px;
      transform: scale(0.7);
    }
  }

  .last-message {
    @include font-body-2;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $secondary-text;
  }

  .truncate {
    width: inherit;
    max-width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .last-message-body {
    @include font-body-2;
    line-height: 17px;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .full-screen {
    max-width: 220px;
  }

  .widget {
    max-width: 180px;
  }

  .conversation-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }

  .last-message-data {
    @include font-caption-3;
    display: flex;
    align-items: center;
    color: $secondary-text;
  }

  .avatar-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;

    :deep(.avatar) {
      margin: 0;
    }
  }

  .fullscreen {
    width: 400px;
    height: 100%;
  }
</style>
