<template>
  <div ref="conversationBodyContainerRef" class="conversation">
    <div class="conversation-container">
      <ConversationHeader
        :currentConversation="currentConversationRef"
        :buttons="['kebab']"
        :conversationBodyContainerRef="conversationBodyContainerRef"
        :hideGroupInfoButton="hideGroupInfoButton"
        :hideSearchButton="hideSearchButton"
        @toggleGroupInfo="$emit('toggleGroupInfo')"
        @setConversationMute="setConversationMute"
        @onDialogOpen="onDialogOpen"
        @onSearchMessage="$emit('toSearchMessage')"
      />
    </div>
    <div class="conversation-body-container">
      <Messages
        v-if="messages.length && !loading"
        :messages="messages"
        :pageInfo="pageInfo"
        :conversationBodyContainerRef="conversationBodyContainerRef"
        :conversationId="conversationId"
        :currentConversation="currentConversationRef"
        :actionPermission="actionPermission"
        :initialLoad="initialLoad"
        :messageToJump="messageToJump"
        :searchMessageText="searchMessageText"
        large
        @fetchAfter="fetchAfter"
        @fetchBefore="fetchBefore"
        @setReplyMessage="setReplyMessage"
        @setMessagesListRef="setMessagesListRef"
        @setToBottomButton="setToBottomButton"
        @clickReplyMessage="clickReplyMessage"
      />
      <div v-else-if="loading" class="messages-empty"></div>
      <div v-else class="messages-empty">
        <Conversation />
        <span>{{ t('conversation.start') }}</span>
      </div>
      <div v-if="toBottomButton" class="scroll-button-container">
        <div class="scroll">
          <button class="scroll-button" @click="toBottomButtonClick">
            <ArrowDown />
          </button>
        </div>
      </div>
      <div class="conversation-message-input-container">
        <transition name="reply" mode="out-in">
          <ReplyMessage
            v-if="replyMessage"
            :message="replyMessage"
            large
            @click="clickReplyMessage(replyMessage)"
            @clearReplyMessage="setReplyMessage"
          />
        </transition>
        <ArchivedMessageInput
          v-if="
            currentConversation?.type === 'PRIVATE' &&
            (!currentConversation?.users?.edges[0] ||
              currentConversation?.users?.edges[0]?.node.archived)
          "
        />
        <MessageInput
          v-else
          :key="conversationId"
          :inputFocus="inputFocus"
          :draftMessages="draftMessages"
          :currentDialogId="conversationId"
          bordered
          @focused="(focus: boolean) => setFocus(focus)"
          @changeDraftMessages="changeDraftMessages"
          @removeFromDraftMessages="removeFromDraftMessages"
          @scrollMessagesOnInput="scrollMessagesOnInput"
          @send="(message: string) => sendMessage(message)"
        />
      </div>
    </div>
    <transition name="appear">
      <Dialog
        v-if="currentConversation?.creator?.id === currentUser?.id && dialog"
        :title="t('popups.delete.title')"
        :text="t('popups.delete.text')"
        :confirmText="t('popups.delete.confirmText')"
        modal
        :loading="removeLoading"
        @confirm="onDialogConfirm(removeConversation)"
        @cancel="onDialogClose"
      />
      <Dialog
        v-else-if="dialog"
        :title="t('popups.leave.title')"
        :confirmText="t('popups.leave.confirmText')"
        modal
        :loading="leaveLoading"
        @confirm="onDialogConfirm(leaveConversation)"
        @cancel="onDialogClose"
      />
    </transition>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    inject,
    PropType,
    ref,
    toRefs,
    Ref,
    watch,
    onBeforeMount,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Dialog from '@/components/Dialog.vue'
  import ConversationHeader from '@/components/conversation/ConversationHeader.vue'
  import ArchivedMessageInput from '@/components/message/ArchivedMessageInput.vue'
  import MessageInput from '@/components/message/MessageInput.vue'
  import Messages from '@/components/message/Messages.vue'
  import ReplyMessage from '@/components/message/ReplyMessage.vue'
  import useConversationDelete from '@/compositions/conversation/useConversationDelete'
  import useDraftMessage from '@/compositions/message/useDraftMessage'
  import useMessages from '@/compositions/message/useMessages'
  import useReplyMessage from '@/compositions/message/useReplyMessage'
  import useScrollMessage from '@/compositions/message/useScrollMessage'
  import useSendMessage from '@/compositions/message/useSendMessage'
  import useDialog from '@/compositions/useDialog'
  import ArrowDown from '@/icons/arrowDown.svg'
  import Conversation from '@/icons/conversation.svg'
  import { DialogType, MessageType, UserType, DraftMessageType } from '@/types'
  import { fixMessagesOrder } from '@/utils'

  const ConversationDisplay = defineComponent({
    components: {
      ConversationHeader,
      MessageInput,
      Messages,
      ReplyMessage,
      Conversation,
      ArrowDown,
      Dialog,
      ArchivedMessageInput,
    },
    props: {
      currentConversation: Object as PropType<DialogType>,
      conversations: Object as PropType<Array<DialogType>>,
      messageToJump: Object as PropType<MessageType>,
      actionPermission: Boolean,
      hideGroupInfoButton: Boolean,
      hideSearchButton: Boolean,
      searchMessageText: {
        type: String,
        default: '',
      },
    },
    emits: [
      'toContactsList',
      'toConversation',
      'toConversationsList',
      'loadMoreConversations',
      'toggleGroupInfo',
      'setConversationMute',
      'refetchConversations',
      'toSearchMessage',
      'setJumpingMessage',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const { currentConversation, conversations, messageToJump } =
        toRefs(props)
      const conversationId = computed(() => currentConversation?.value?.id)

      const currentConversationRef = ref(currentConversation)
      const inputFocus = ref<boolean>(false)
      const { messagesListRef, setMessagesListRef, scrollMessagesOnInput } =
        useScrollMessage()

      const {
        messages,
        loading,
        initialLoad,
        pageInfo,
        fetchAfter,
        fetchBefore,
        highlightMessage,
        setTimestamp,
      } = useMessages({
        conversations,
        currentConversation,
        conversationId,
        chatVisible: ref(true),
        inputFocus,
        paginationLength: 20,
        messageToJump,
        clearMessageToJump: () => {
          emit('setJumpingMessage', undefined)
        },
        errorCallback: () => {
          emit('toConversation', { conversationId: undefined })
          conversations?.value?.length
            ? emit('toConversationsList')
            : emit('toContactsList')
        },
      })

      const { replyMessage, replyMessageId, setReplyMessage, setFocus } =
        useReplyMessage(conversationId, inputFocus)

      const { sendMessage } = useSendMessage(
        messages,
        currentConversation,
        conversationId,
        messagesListRef,
        inputFocus,
        replyMessage,
        replyMessageId,
        emit,
        pageInfo,
        (message) => {
          setTimestamp(message.createdAt)
        },
      )

      const {
        draftMessages,
        changeDraftMessages,
        removeFromDraftMessages,
        setDraftMessages,
      } = useDraftMessage()

      onBeforeMount(() => {
        const draft = localStorage.getItem('draft')
        if (draft) {
          setDraftMessages(JSON.parse(draft) as Array<DraftMessageType>)
        }
      })

      const toBottomButton = ref<boolean>(false)

      const setToBottomButton = (visible: boolean) => {
        toBottomButton.value = visible
      }

      const toBottomButtonClick = () => {
        if (messagesListRef?.value) {
          emit('setJumpingMessage', undefined)
          if (pageInfo.value.hasPreviousPage) {
            setTimestamp(new Date().toISOString())
            setToBottomButton(false)
          } else {
            setToBottomButton(false)
            messagesListRef.value.scrollTo({
              top: messagesListRef.value.scrollHeight,
              behavior: 'smooth',
            })
          }
        }
      }

      watch(
        () => currentConversation?.value?.id,
        () => (toBottomButton.value = false),
      )

      const toggleGroupInfo = () => {
        emit('toggleGroupInfo', true)
      }

      const setConversationMute = () => {
        emit(
          'setConversationMute',
          currentConversation?.value?.id,
          !currentConversation?.value?.mute,
        )
      }

      const conversationBodyContainerRef = ref()

      const { dialog, onDialogOpen, onDialogClose, onDialogConfirm } =
        useDialog()

      const {
        leaveLoading,
        removeLoading,
        leaveGroupConversation,
        removeGroupConversation,
      } = useConversationDelete()

      const leaveConversation = () => {
        if (currentUser?.value && currentConversation?.value) {
          leaveGroupConversation(
            currentConversation.value.id,
            currentUser.value.id,
            () =>
              emit('refetchConversations', () => {
                emit('toConversation', { conversationId: undefined })
                emit('toConversationsList')
                onDialogClose()
              }),
          )
        }
      }

      const removeConversation = () => {
        if (currentConversation?.value) {
          removeGroupConversation(currentConversation.value.id, () =>
            emit('refetchConversations', () => {
              emit('toConversation', { conversationId: undefined })
              emit('toConversationsList')
              onDialogClose()
            }),
          )
        }
      }

      const clickReplyMessage = (message: MessageType | null) => {
        if (message) {
          highlightMessage(message)
          message.bodyWithHighlight = message.body
          emit('setJumpingMessage', message)
        }
      }

      watch(
        () => messages?.value,
        (newMessages) => {
          if (newMessages) {
            const order = fixMessagesOrder(newMessages)
            if (order.shouldAssign) {
              messages.value = order.messages
            }
          }
        },
        {
          deep: true,
        },
      )

      return {
        currentUser,
        conversationId,
        messages,
        loading,
        pageInfo,
        fetchAfter,
        fetchBefore,
        sendMessage,
        replyMessage,
        setReplyMessage,
        conversationBodyContainerRef,
        setMessagesListRef,
        setFocus,
        inputFocus,
        draftMessages,
        changeDraftMessages,
        removeFromDraftMessages,
        scrollMessagesOnInput,
        currentConversationRef,
        toBottomButton,
        setToBottomButton,
        toBottomButtonClick,
        toggleGroupInfo,
        setConversationMute,
        dialog,
        onDialogOpen,
        onDialogConfirm,
        onDialogClose,
        leaveLoading,
        removeLoading,
        leaveConversation,
        removeConversation,
        initialLoad,
        clickReplyMessage,
        t,
      }
    },
  })

  export default ConversationDisplay
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';
  @import '@/styles/_variables.scss';

  .conversation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .conversation-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 93px;
    width: 100%;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(167, 167, 167);

    .conversations-list {
      overflow-y: auto;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    .conversations-list::-webkit-scrollbar {
      display: none;
    }

    .conversation-preview {
      cursor: pointer;
      margin: 11px 6px 0;
    }

    .back-arrow-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 66px;
      border-bottom: 1px solid rgb(167, 167, 167);
    }
    .go-to-contacts-button {
      color: $secondary-text;
      fill: $secondary-text;
      padding: 0.5em;
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      background: none;
      border: none;
      &:hover {
        color: $secondary-text-hover;
        fill: $secondary-text-hover;
      }
    }
  }

  .conversation-header-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border;

    .heading-name {
      font-weight: 600;
    }
  }

  .conversation-body-container {
    height: 349px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    :deep(.send-button-container) {
      min-height: 75px;
    }

    .messages-empty {
      color: $secondary-text;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .conversation-message-input-container {
      @include scroll;
      border-top: 1px solid $border;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 81px;
      box-sizing: border-box;
    }
  }

  .scroll-button-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 0;

    .scroll {
      z-index: 10;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0 24px 22px 0;
    }

    .scroll-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background: $background;
      border: 1px solid $border;
      border-radius: 50%;
      box-shadow: 0 5px 4px -1px $border;

      svg {
        width: 12px;
        height: 8px;
      }
    }
  }

  .reply-enter-active,
  .reply-leave-active {
    transition: opacity 0.2s;
  }

  .reply-enter-from,
  .reply-leave-to {
    opacity: 0;
  }

  .appear-enter-from,
  .appear-leave-to {
    opacity: 0;
  }

  .appear-enter-active {
    transition: all 0.2s ease-out;
  }

  .appear-leave-active {
    transition: all 0.2s ease-out;
  }
</style>
