import { gql } from '@apollo/client/core'

import { PAGE_INFO_FRAGMENT } from '@/graphql/common'
import { CONVERSATION_FRAGMENT } from '@/graphql/conversations/queries'

export const USER_FRAGMENT = gql`
  fragment userFields on Conversation {
    id
    name
    avatar
    lastSeenAt
  }
`

export const TAKE_ALL_USERS = gql`
  query ($name: SearchString, $first: Int, $after: String) {
    users(
      filters: { name: $name, onlyActive: true }
      pagination: { first: $first, after: $after }
    ) {
      pageInfo {
        ...pageInfoFields
      }
      edges {
        cursor
        node {
          id
          name
          avatar
          lastSeenAt
          privateConversation {
            ...conversationFields
          }
        }
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`

export const TAKE_USER = gql`
  query ($userId: ID) {
    user(id: $userId) {
      ...userFields
    }
  }
  ${USER_FRAGMENT}
`

export const TAKE_CURRENT_USER = gql`
  query {
    currentUser {
      id
      avatar
      lastSeenAt
      name
    }
  }
`

export const TAKE_SETTINGS = gql`
  query {
    widgetSettings {
      coordinates {
        x
        y
      }
      expanded
    }
  }
`

export const TAKE_FULLSCREEN_SETTINGS = gql`
  query {
    fullScreenSettings {
      showSidebar
    }
  }
`

export const TAKE_NOTIFICATION_SETTINGS = gql`
  query {
    notificationSettings {
      muteAll
    }
  }
`
