import { PageInfo } from '@octokit/graphql-schema'
import { useMutation } from '@vue/apollo-composable'
import { ComputedRef, inject, nextTick, Ref } from 'vue'

import { textType } from '@/const'
import { SEND_MESSAGE } from '@/graphql/messages/mutations'
import { ContactType, Cursor, DialogType, MessageType, UserType } from '@/types'
import { convertSymbolToCharacterSequence } from '@/utils'

import { setErrorStatus } from './messageStatusUtils'

type SendMessageProps = {
  toConversation: (id: string) => void
  sendMessage: (message: string) => void
}

type Events = 'toConversation' | 'refetchConversations'

export default function useSendMessage(
  messages: Ref<Array<Cursor<MessageType>>>,
  currentConversation: Ref<DialogType | undefined> | undefined,
  conversationId: ComputedRef<string | undefined>,
  messagesListRef: Ref<HTMLDivElement | undefined>,
  inputFocus: Ref<boolean>,
  replyMessage: Ref<MessageType | null>,
  replyMessageId: ComputedRef<number>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit: (event: Events, ...args: any[]) => void,
  pageInfo: Ref<PageInfo>,
  callback?: (data: MessageType) => void,
): SendMessageProps {
  const currentUser = inject<Ref<UserType>>('currentUser')
  // Send message (conversationId, currentUser, messagesListRef, inputFocus, replyMessage)
  const { mutate: mutateMessage, onDone, onError } = useMutation(SEND_MESSAGE)

  const sendMessage = (message: string) => {
    if (
      currentConversation?.value?.type === 'GROUP' ||
      currentConversation?.value?.type === 'CUSTOM'
    ) {
      mutateMessage({
        input: {
          conversationId: conversationId.value,
          body: message,
          parentId: replyMessageId.value,
        },
      })
    } else {
      mutateMessage({
        input: {
          recipientId: currentConversation?.value?.users?.edges[0].node.id,
          body: message,
          parentId: replyMessageId.value,
        },
      })
    }

    const sentMessage: Cursor<MessageType> = {
      cursor: '',
      node: {
        conversation: {
          id: conversationId.value ? conversationId.value : '0',
        },
        id: 0,
        user: {
          id: currentUser?.value.id,
          avatar: currentUser?.value.avatar,
          name: currentUser?.value.name,
        } as ContactType,
        body: convertSymbolToCharacterSequence(message),
        read: true,
        createdAt: new Date().toISOString(),
        status: 'PROCESSING',
        type: textType,
        parent: replyMessage.value ? replyMessage.value : undefined,
        bodyParameters: null,
      },
    }

    if (pageInfo.value.hasPreviousPage) {
      replyMessage.value = null
      inputFocus.value = false
      return
    }
    messages.value = [sentMessage, ...messages.value]

    nextTick(() => {
      if (messagesListRef.value) {
        messagesListRef.value.scrollTo({
          top: messagesListRef.value.scrollHeight,
          behavior: 'smooth',
        })
      }
    })

    replyMessage.value = null
    inputFocus.value = false
  }

  const toConversation = (id: string) => {
    emit('toConversation', { conversationId: id })
    inputFocus.value = true
  }

  onDone((result) => {
    if (conversationId.value === '0') {
      emit('refetchConversations', () =>
        toConversation(result.data.sendMessage.message.conversation.id),
      )
    }
    if (pageInfo.value.hasPreviousPage) {
      callback && callback(result.data.sendMessage.message)
    }
  })

  onError(() => {
    setErrorStatus(messages)
  })

  return {
    toConversation,
    sendMessage,
  }
}
