import { gql } from '@apollo/client/core'

export type UserActivityChangedType = {
  userActivityChanged: {
    userId: string
    activity: {
      lastSeenAt: string
      status: string
    }
  }
}

export const SUBSCRIBE_LAST_SEEN_UPDATED = gql`
  subscription {
    userActivityChanged {
      activity {
        lastSeenAt
        status
      }
      userId
    }
  }
`
