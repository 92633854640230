import { gql } from '@apollo/client/core'

export const TAKE_CONVERSATION_MESSAGES = gql`
  query (
    $conversationId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
    $body: SearchString
  ) {
    messages(
      filters: { conversationId: $conversationId, body: $body }
      pagination: { first: $first, last: $last, before: $before, after: $after }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
  }
`

export const TAKE_FILTERED_CONVERSATION_MESSAGES = gql`
  query (
    $conversationId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
    $body: SearchString
  ) {
    messages(
      filters: { conversationId: $conversationId, body: $body }
      pagination: { first: $first, last: $last, before: $before, after: $after }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          bodyWithHighlight
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
      totalCount
    }
  }
`

export const TAKE_UNREAD_MESSAGES = gql`
  query ($conversationId: ID, $last: Int) {
    messages(
      filters: { conversationId: $conversationId, onlyUnread: true }
      pagination: { last: $last }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
  }
`

export const AROUND_TIMESTAMP_MESSAGES = gql`
  query (
    $conversationId: ID
    $first: Int
    $last: Int
    $timestamp: ISO8601DateTime
  ) {
    newMessages: messages(
      filters: {
        conversationId: $conversationId
        createdAt: { gteq: $timestamp }
      }
      pagination: { last: $last }
    ) {
      pageInfo {
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            conversation {
              id
            }
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
    oldMessages: messages(
      filters: {
        conversationId: $conversationId
        createdAt: { lteq: $timestamp }
      }
      pagination: { first: $first }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            conversation {
              id
            }
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
  }
`

export const AROUND_TIMESTAMP_MESSAGES_NEXT_PAGE = gql`
  query (
    $conversationId: ID
    $first: Int
    $timestamp: ISO8601DateTime
    $after: String
  ) {
    messages(
      filters: {
        conversationId: $conversationId
        createdAt: { lteq: $timestamp }
      }
      pagination: { after: $after, first: $first }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            conversation {
              id
            }
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
  }
`

export const AROUND_TIMESTAMP_MESSAGES_PREV_PAGE = gql`
  query (
    $conversationId: ID
    $last: Int
    $timestamp: ISO8601DateTime
    $before: String
  ) {
    messages(
      filters: {
        conversationId: $conversationId
        createdAt: { gteq: $timestamp }
      }
      pagination: { before: $before, last: $last }
    ) {
      pageInfo {
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          conversation {
            id
          }
          user {
            id
            name
            avatar
            archived
            lastSeenAt
          }
          body
          createdAt
          status
          read
          type
          parent {
            id
            body
            createdAt
            conversation {
              id
            }
            user {
              id
              name
              avatar
              archived
            }
          }
          bodyParameters
        }
      }
    }
  }
`
