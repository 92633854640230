import { useI18n } from 'vue-i18n'

import { MessageType } from '@/types'
import { serviceType } from '@/const'

export default function useServiceMessage(): {
  buildMessage: (message?: MessageType) => MessageType | null
} {
  const { t } = useI18n()

  const buildMessage = (message?: MessageType) => {
    if (!message) {
      return null
    }

    return message.type === serviceType
      ? {
          ...message,
          body: t(`messages.service.${message.body}`, {
            ...message.bodyParameters,
          }),
        }
      : message
  }

  return { buildMessage }
}
