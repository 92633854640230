<template>
  <div class="search-layout">
    <div class="search-message-header">
      <MainHeader
        :title="t('search.header')"
        :buttons="[]"
        :muteAll="currentConversation?.mute"
        :backAction="'toConversation'"
        :currentConversation="currentConversation"
        :showMute="showMute"
        :searchPlaceholder="t('search.searchPlaceholder')"
        @onDragWidget="(e: MouseEvent) => $emit('onDragWidget', e)"
        @search="searchCallback"
        @toggleMuteAll="setConversationMute"
        @toConversation="toConversation"
      />
    </div>
    <div v-if="loading" class="spinner-container">
      <Spinner primary />
    </div>
    <div v-if="!loading && text && text.trim() != ''">
      <div v-if="totalCount ?? 0 > 0" class="found-messages-label-container">
        {{ t('search.found', { count: totalCount }) }}
      </div>
      <div v-if="totalCount == 0" class="found-messages-label-container">
        {{ t('common.noResults') }}
      </div>
    </div>
    <div v-if="text && !loading" class="chat-sidebar">
      <div class="search-results-layout">
        <div
          v-for="message in messagesQuery.messages"
          :key="`${message.id}-${message.conversation.id}`"
          class="message-row"
          @click="clickResultsRow(message)"
        >
          <div class="message-info">
            <Avatar
              :name="message.user.name"
              :lastSeen="message.user.lastSeenAt"
              :status="message.user.status"
              :src="message.user.avatar"
              :archived="message.user.archived"
            />
            <div class="message-user-last-seen-container">
              <span
                class="user-name"
                :class="{ archived: message.user.archived }"
              >
                {{ message.user.name }}
              </span>
              <span class="user-last-seen">
                {{ dateFormat(message.createdAt, locale) }}
              </span>
            </div>
          </div>
          <div class="message-text">
            <div class="message-text-container">
              <div class="message-text-content">
                <div>
                  <span v-if="isCurrentUser(message.user.id)">
                    {{ t('conversation.you') }}&nbsp;
                  </span>
                  <span v-html="message.bodyWithHighlight"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Blur />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    Ref,
    toRefs,
    computed,
    inject,
    watch,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import Blur from '@/components/Blur.vue'
  import Spinner from '@/components/Spinner.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useMessageSearch from '@/compositions/message/useMessageSearch'
  import { DialogType, UserType, MessageType } from '@/types'
  import { dateFormat } from '@/utils'

  const SearchMessage = defineComponent({
    components: {
      MainHeader,
      Avatar,
      Blur,
      Spinner,
    },
    props: {
      currentConversation: Object as PropType<DialogType>,
      conversations: Object as PropType<Array<DialogType>>,
      showMute: Boolean,
      muteAll: Boolean,
      mute: Boolean,
    },
    emits: [
      'toConversation',
      'setConversationMute',
      'onDragWidget',
      'toggleMuteAll',
      'toggleGroupInfo',
      'setJumpingMessage',
      'setSearchMessageText',
    ],
    setup(props, { emit }) {
      const { t, locale } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const currentUserId = computed(() => currentUser?.value.id)

      const { currentConversation } = toRefs(props)
      const currentConversationId = computed(
        () => currentConversation?.value?.id,
      )

      const currentConversationMute = computed(
        () => currentConversation?.value?.mute,
      )

      const { text, searchCallback, messagesQuery, loading } = useMessageSearch(
        currentConversationId,
      )

      const totalCount = computed(() => messagesQuery.value.totalCount)

      watch(text, (newValue) => emit('setSearchMessageText', newValue))

      const toConversation = () => {
        emit('toggleGroupInfo', false)
        emit('setSearchMessageText', '')
      }

      const setConversationMute = () => {
        emit(
          'setConversationMute',
          currentConversationId.value,
          !currentConversationMute.value,
        )
      }

      const isCurrentUser = (userId: string) => {
        return userId === currentUserId.value
      }

      const clickResultsRow = (message: MessageType) => {
        emit('setJumpingMessage', message)
      }

      return {
        searchCallback,
        currentConversationId,
        currentConversationMute,
        messagesQuery,
        text,
        currentUserId,
        totalCount,
        loading,
        toConversation,
        setConversationMute,
        isCurrentUser,
        clickResultsRow,
        dateFormat,
        locale,
        t,
      }
    },
  })

  export default SearchMessage
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';
  @import '@/styles/_variables.scss';

  .search-layout {
    width: 300px;
    height: 100%;

    :deep(.highlighted-match) {
      background: #f6c846;
    }

    & .search-message-header {
      padding-top: 6px;
    }

    & .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }

    & .chat-sidebar {
      width: 100%;
      max-height: calc(100% - 123px);
      overflow-y: auto;

      & .search-results-layout {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        width: 100%;
        overflow-y: auto;
        & .message-row {
          cursor: pointer;

          &:hover {
            background-color: $conversation-hover;
          }

          &.selected {
            background-color: $conversation-selected;
          }
        }
      }
    }

    & .found-messages-label-container {
      @include font-caption-3;
      color: #8c8f94;
      padding: 16px;
      padding-bottom: 0;
    }

    & .message-info {
      @include font-caption-3;
      display: flex;
      position: relative;
      margin-top: 20px;
      padding-left: 42px;

      &.large {
        padding-left: 55px;
        padding-top: 4px;

        .avatar {
          position: absolute;
          top: -2px;
          left: 0;
        }
      }

      .avatar {
        position: absolute;
        top: -2px;
        left: 9px;
      }
      .message-user-last-seen-container {
        margin-left: 26px;
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-right: 18px;

        .user-name {
          @include font-caption-2;

          color: $primary-text;
          margin-right: 8px;

          &.archived {
            color: $secondary-text;
          }
        }

        .user-last-seen {
          color: $secondary-text;
          min-width: 56px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    & .message-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .message-text-content {
        margin: 4px;
        margin-left: 68px;
        margin-right: 38px;
        text-align: left;
        word-break: break-word;
        white-space: pre-wrap;

        @include font-body-2;
      }
    }
  }

  @media (max-width: $small-screen) {
    .search-layout {
      width: 250px;
    }
  }

  @include scroll;
</style>
