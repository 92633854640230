import { useMutation } from '@vue/apollo-composable'
import { Ref } from 'vue'

import { UPDATE_CONVERSATION } from '@/graphql/conversations/mutations'
import { DialogType } from '@/types'
import { updateConversationMute } from '@/utils'

type ConversationMuteProps = {
  conversations: Ref<Array<DialogType>>
  conversationRef: Ref<DialogType | undefined>
}

export default function useConversationMute({
  conversations,
  conversationRef,
}: ConversationMuteProps): {
  setConversationMute: (conversationId: number, mute: boolean) => void
} {
  const { mutate: updateConversation, onDone } =
    useMutation(UPDATE_CONVERSATION)

  const setConversationMute = (conversationId: number, mute: boolean) => {
    updateConversation({
      input: {
        conversationId,
        mute,
      },
    })
  }

  onDone(({ data }) => {
    updateConversationMute(
      conversations,
      data.updateConversation.conversation,
      conversationRef,
    )
  })

  return {
    setConversationMute,
  }
}
