import { fullDate } from '@/utils/date/fullDate'
import { notToday } from '@/utils/date/notToday'
import { shortDate } from '@/utils/date/shortDate'
import { thisYear } from '@/utils/date/thisYear'
import { time } from '@/utils/date/time'

export const dateFormat = (stringDate: string, locale: string): string => {
  const date = new Date(stringDate)
  return !thisYear(date)
    ? fullDate(date, locale)
    : notToday(date)
    ? shortDate(date, locale)
    : time(date, locale)
}
