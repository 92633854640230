<template>
  <div
    :class="{
      opened: chatVisible,
      closed: !chatVisible,
      'toggle-button-layout': true,
    }"
  >
    <div v-if="chatVisible">
      <HideIcon alt="Hide" />
    </div>
    <div v-else>
      <OpenIcon alt="Open" />
      <UnreadCount
        v-if="unreadCount"
        class="unread-count"
        :unreadCount="unreadCount"
      />
    </div>
    <div class="cover" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  import UnreadCount from '@/components/UnreadCount.vue'
  import HideIcon from '@/icons/hide.svg'
  import OpenIcon from '@/icons/open.svg'
  import { truncateUnreadCount } from '@/utils'

  const ChatToggleButton = defineComponent({
    components: {
      HideIcon,
      OpenIcon,
      UnreadCount,
    },
    props: {
      chatVisible: Boolean,
      unreadCount: Number,
    },
    methods: {
      truncateUnreadCount,
    },
  })

  export default ChatToggleButton
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';

  .toggle-button-layout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background-color: $toggle-button;
    cursor: pointer;
    transition: border-radius 0.3s ease-in;
    user-select: none;

    svg {
      width: 24px;
      height: 24px;
      fill: $background;
    }
  }

  .unread-count {
    position: absolute;
    right: 6px;
    bottom: 30px;
    border: 2px solid $toggle-button;
  }

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
