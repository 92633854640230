import { computed, ComputedRef, Ref, ref, watch } from 'vue'

import { MessageType } from '@/types'

type ReplyMessageProps = {
  replyMessage: Ref<MessageType | null>
  replyMessageId: ComputedRef<number>
  setReplyMessage: (message: MessageType | null) => void
  setFocus: (focus: boolean) => void
}

export default function useReplyMessage(
  conversationId: ComputedRef<string | undefined>,
  inputFocus: Ref<boolean>,
): ReplyMessageProps {
  // Reply message (conversationId, inputFocus)
  const replyMessage = ref<MessageType | null>(null)
  const replyMessageId = computed(() => Number(replyMessage?.value?.id))

  const setReplyMessage = (message: MessageType | null) => {
    replyMessage.value = message
    inputFocus.value = true
  }

  const setFocus = (focus: boolean) => {
    inputFocus.value = focus
  }

  watch(conversationId, (id, prevId) => {
    if (prevId !== id && replyMessage.value) {
      replyMessage.value = null
    }
  })
  return {
    replyMessage,
    replyMessageId,
    setReplyMessage,
    setFocus,
  }
}
