import { gql } from '@apollo/client/core'

export const PAGE_INFO_FRAGMENT = gql`
  fragment pageInfoFields on PageInfo {
    endCursor
    startCursor
    hasPreviousPage
    hasNextPage
  }
`
