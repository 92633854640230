import { Ref, ref } from 'vue'

type Style = {
  top: string
  left: string
}

export default function usePopup(
  containerRef: Ref<HTMLDivElement | null>,
  popupRef: Ref<HTMLDivElement | null>,
): [Ref<boolean>, () => void, () => void, Ref<Style>] {
  const isPopupVisible = ref<boolean>(false)
  const popupTimeout = ref<number | null>(null)
  const popupPosition = ref<Style>({
    top: '',
    left: '',
  })

  const showPopup = (): void => {
    if (containerRef.value && popupRef.value) {
      const { width, height, x, y } = containerRef.value.getBoundingClientRect()
      const { width: popupWidth, height: popupHeight } =
        popupRef.value.getBoundingClientRect()

      popupPosition.value.top = `${height + y - popupHeight - 10}px`
      popupPosition.value.left = `${width / 2 + x - popupWidth / 2}px`

      if (popupTimeout.value) {
        clearTimeout(popupTimeout.value)
      }

      isPopupVisible.value = true
      popupTimeout.value = window.setTimeout(
        () => (isPopupVisible.value = false),
        2000,
      )
    }
  }

  const hidePopup = (): void => {
    isPopupVisible.value = false
    if (popupTimeout.value) {
      clearTimeout(popupTimeout.value)
    }
  }

  return [isPopupVisible, showPopup, hidePopup, popupPosition]
}
