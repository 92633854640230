import { Ref } from 'vue'

import { DialogType } from '@/types'

export const updateConversationMute = (
  conversations: Ref<Array<DialogType>>,
  updateConversation: DialogType | undefined,
  currentConversation: Ref<DialogType | undefined>,
): void => {
  if (updateConversation) {
    const conversation = conversations.value.find(
      (conversation) => conversation.id === updateConversation.id,
    )
    if (conversation) {
      conversation.mute = updateConversation.mute
    }

    if (currentConversation?.value) {
      currentConversation.value.mute = updateConversation.mute
    }
  }
}
