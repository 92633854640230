import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { watch, reactive, Ref, ref } from 'vue'

import {
  border,
  smallWidgetSide,
  fullWidgetHeight,
  dragStartTime,
} from '@/const'
import { UPDATE_USER_SETTINGS } from '@/graphql/users/mutations'
import { TAKE_SETTINGS } from '@/graphql/users/queries'
import { SettingsType } from '@/types'

type Position = {
  width: number
  height: number
  x: number
  y: number
  left: boolean
  top: boolean
  drag: Record<string, number> | undefined
  wasMoving: boolean
  timeout: number
}

function updatePosition(
  left: boolean,
  top: boolean,
  newX: number,
  newY: number,
  width: number,
  height: number,
): [boolean, boolean] {
  return [
    left ? newX < border * 2 : newX < width + border,
    top
      ? newY < border * 2 + fullWidgetHeight
      : newY < height + border + fullWidgetHeight,
  ]
}

function updateCoordinates(
  newX: number,
  newY: number,
  width: number,
  height: number,
): [number, number] {
  return [
    Math.max(Math.min(newX, width - border), border),
    Math.max(Math.min(newY, height - border), border),
  ]
}

export default function useDraggable(
  main: Ref<HTMLElement | undefined>,
  actionPermission: Ref<boolean>,
): [Position, (arg: MouseEvent) => void] {
  const { result } = useQuery(TAKE_SETTINGS)
  const settings = useResult<SettingsType>(result)

  const { mutate: updateSettings } = useMutation(UPDATE_USER_SETTINGS)

  const height = ref<number>(smallWidgetSide)
  const width = ref<number>(smallWidgetSide)

  const position = reactive<Position>({
    width: window.innerWidth,
    height: window.innerHeight,
    x: window.innerWidth - border,
    y: window.innerHeight - border,
    left: false,
    top: false,
    drag: undefined,
    wasMoving: false,
    timeout: 0,
  })

  watch(settings, () => {
    if (Object.prototype.hasOwnProperty.call(settings.value, 'coordinates')) {
      if (settings.value?.coordinates) {
        height.value = main?.value?.offsetHeight ?? smallWidgetSide
        width.value = main?.value?.offsetWidth ?? smallWidgetSide
        ;[position.left, position.top] = updatePosition(
          position.left,
          position.top,
          settings.value?.coordinates.x ?? width.value,
          settings.value?.coordinates.y ?? height.value,
          width.value,
          height.value,
        )
        ;[position.x, position.y] = updateCoordinates(
          settings.value?.coordinates.x ?? width.value,
          settings.value?.coordinates.y ?? height.value,
          position.width,
          position.height,
        )
      }
    }
  })

  window.addEventListener('resize', (e) => {
    position.width = (e.target as unknown as { innerWidth: number }).innerWidth
    position.height = (
      e.target as unknown as { innerHeight: number }
    ).innerHeight
    ;[position.x, position.y] = updateCoordinates(
      position.x,
      position.y,
      position.width,
      position.height,
    )
  })

  const onMouseDown = (e: MouseEvent) => {
    actionPermission.value = false
    position.timeout = window.setTimeout(() => {
      if (main.value) {
        position.drag = {
          x: e.x - main.value.offsetLeft,
          y:
            e.y -
            main.value.offsetTop -
            Number(position.top && smallWidgetSide),
          top: Number(position.top),
        }
        height.value = main?.value?.offsetHeight ?? smallWidgetSide
        width.value = main?.value?.offsetWidth ?? smallWidgetSide
      }
    }, dragStartTime)
  }

  window.addEventListener('mouseup', () => {
    actionPermission.value = true
    clearTimeout(position.timeout)
    if (position.wasMoving) {
      updateSettings({
        input: { coordinates: { x: position.x, y: position.y } },
      })
      position.wasMoving = false
      window.getSelection()?.removeAllRanges()
    }
    position.drag = undefined
  })

  document.addEventListener('mousemove', (e) => {
    if (position.drag) {
      position.wasMoving = true
      const newX =
        e.clientX -
        (position.left ? position.drag.x : position.drag.x - width.value)
      const newY =
        e.clientY -
        (position.top
          ? position.drag.y + smallWidgetSide
          : position.drag.y - height.value)
      height.value = main?.value?.offsetHeight ?? smallWidgetSide
      width.value = main?.value?.offsetWidth ?? smallWidgetSide
      ;[position.left, position.top] = updatePosition(
        position.left,
        position.top,
        newX,
        newY,
        width.value,
        height.value,
      )
      ;[position.x, position.y] = updateCoordinates(
        newX,
        position.drag.top
          ? position.top
            ? newY
            : newY - smallWidgetSide
          : position.top
          ? newY + smallWidgetSide
          : newY,
        position.width,
        position.height,
      )
    }
  })

  return [position, onMouseDown]
}
