import { useMutation } from '@vue/apollo-composable'
import { Ref } from 'vue'

import {
  LEAVE_CONVERSATION,
  REMOVE_CONVERSATION,
  UpdateConversationType,
  RemoveCoversationType,
} from '@/graphql/conversations/mutations'

export default function useConversationDelete(): {
  leaveLoading: Ref<boolean>
  removeLoading: Ref<boolean>
  leaveGroupConversation: (
    conversationId: string,
    userId: string,
    callback?: () => void,
  ) => void
  removeGroupConversation: (
    conversationId: string,
    callback?: () => void,
  ) => void
} {
  const { mutate: leaveConversation, loading: leaveLoading } =
    useMutation<UpdateConversationType>(LEAVE_CONVERSATION)

  const { mutate: removeConversation, loading: removeLoading } =
    useMutation<RemoveCoversationType>(REMOVE_CONVERSATION)

  const leaveGroupConversation = (
    conversationId: string,
    userId: string,
    callback?: () => void,
  ) => {
    leaveConversation({
      input: {
        conversationId,
        userIds: { remove: [userId] },
      },
    }).then(() => callback && callback())
  }

  const removeGroupConversation = (
    conversationId: string,
    callback?: () => void,
  ) => {
    removeConversation({ input: { id: conversationId } }).then(
      () => callback && callback(),
    )
  }

  return {
    leaveLoading,
    removeLoading,
    leaveGroupConversation,
    removeGroupConversation,
  }
}
