import { PageInfo } from '@octokit/graphql-schema'
import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ref, ComputedRef, Ref, inject, watch, computed, nextTick } from 'vue'

import { TOUCH_CONVERSATION } from '@/graphql/conversations/mutations'
import {
  AROUND_TIMESTAMP_MESSAGES,
  AROUND_TIMESTAMP_MESSAGES_NEXT_PAGE,
  AROUND_TIMESTAMP_MESSAGES_PREV_PAGE,
} from '@/graphql/messages/queries'
import {
  MessageUpdatedType,
  UPDATE_MESSAGE,
  UPDATE_MESSAGES_IN_CONVERSATION,
} from '@/graphql/messages/subscriptions'
import {
  Cursor,
  CursorPaginationData,
  DialogType,
  MessageType,
  UserType,
} from '@/types'

import { setReadStatus, setSentStatus } from './messageStatusUtils'

export type PaginationProps = {
  first?: number
  last?: number
  after?: Maybe<string>
  before?: Maybe<string>
}

type SupscribtionalProps = {
  conversations: Ref<Array<DialogType> | undefined> | undefined
  currentConversation: Ref<DialogType | undefined> | undefined
  conversationId: ComputedRef<string | undefined>
  chatVisible: Ref<boolean>
  inputFocus: Ref<boolean>
  paginationLength: number
  messageToJump: Ref<MessageType | undefined> | undefined
  clearMessageToJump: () => void
  errorCallback?: () => void
}

export default function useMessages<T>({
  conversations,
  currentConversation,
  conversationId,
  chatVisible,
  inputFocus,
  paginationLength,
  messageToJump,
  clearMessageToJump,
  errorCallback,
}: SupscribtionalProps): {
  messages: Ref<Array<Cursor<MessageType>>>
  loading: Ref<boolean>
  initialLoad: Ref<boolean>
  pageInfo: Ref<
    PageInfo & { loadingPreviousPage: boolean; loadingNextPage: boolean }
  >
  fetchAfter: () => void
  fetchBefore: () => void
  highlightMessage: (message: MessageType) => void
  setTimestamp: (value?: string) => void
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messages = ref<Array<Cursor<MessageType>>>([])
  const pageInfo = ref<
    PageInfo & { loadingPreviousPage: boolean; loadingNextPage: boolean }
  >({
    startCursor: '',
    endCursor: '',
    loadingPreviousPage: false,
    loadingNextPage: false,
    hasPreviousPage: false,
    hasNextPage: false,
  })

  const currentUser = inject<Ref<UserType>>('currentUser')

  const loadUnread = ref<boolean>(false)
  const initialLoad = ref<boolean>(false)
  const endCursor = ref<string>('')
  const timestampToUse = ref<string>()

  watch(conversationId, () => {
    loadUnread.value = false
    initialLoad.value = false
    timestampToUse.value = undefined
    clearMessageToJump && clearMessageToJump()
  })

  watch(
    () => messageToJump?.value,
    () => {
      timestampToUse.value = undefined
    },
  )

  const setStartCursorAndHasPrevPage = (data: CursorPaginationData<T>) => {
    pageInfo.value.startCursor = data.pageInfo.startCursor || ''
    pageInfo.value.hasPreviousPage = data.pageInfo.hasPreviousPage ?? true
  }

  const setEndCursorAndHasNextPage = (data: CursorPaginationData<T>) => {
    pageInfo.value.endCursor = data.pageInfo.endCursor
    pageInfo.value.hasNextPage = data.pageInfo.hasNextPage
  }

  const { mutate: mutateConversation, onError } =
    useMutation(TOUCH_CONVERSATION)
  const touchConversation = (messageId: number) =>
    mutateConversation({
      input: { messageId },
    })
  onError(() => errorCallback && errorCallback())

  watch(chatVisible, (visible) => {
    if (visible) {
      const message = messages.value.find(
        (message) => message.node.user.id !== currentUser?.value.id,
      )

      if (message && initialLoad.value) {
        touchConversation(message?.node.id)
      }
      nextTick(() => (inputFocus.value = true))
    }
  })

  const timestamp = computed(() => {
    if (!timestampToUse.value) {
      if (messageToJump?.value) {
        timestampToUse.value = messageToJump.value.createdAt
      } else {
        timestampToUse.value =
          currentConversation?.value?.lastReadAt || new Date().toISOString()
      }
    }
    return timestampToUse.value
  })

  const setTimestamp = (value?: string) => {
    timestampToUse.value = value
  }

  const {
    onResult: onLoad,
    loading,
    refetch,
  } = useQuery(
    AROUND_TIMESTAMP_MESSAGES,
    {
      conversationId,
      timestamp,
      first: paginationLength,
      last: paginationLength ? paginationLength : undefined,
    },
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
    },
  )

  const callback = (data: CursorPaginationData<MessageType>) => {
    if (
      conversationId.value !== '0' &&
      data.edges[0] &&
      !initialLoad.value &&
      chatVisible.value
    ) {
      touchConversation(data.edges[0]?.node.id).then((response) => {
        if (currentConversation?.value) {
          currentConversation.value.unreadMessagesCount =
            response?.data.touchConversation.conversation.unreadMessagesCount
          currentConversation.value.lastReadAt =
            response?.data.touchConversation.conversation.lastReadAt
        }
      })
    }
    if (!loadUnread.value) {
      endCursor.value = data.pageInfo.endCursor
      loadUnread.value = true
      initialLoad.value = true
    }
  }

  onLoad(({ data }) => {
    setEndCursorAndHasNextPage(data['oldMessages'])
    setStartCursorAndHasPrevPage(data['newMessages'])
    if (callback) {
      callback(data['newMessages'])
    }

    messages.value = [
      ...data['oldMessages'].edges,
      ...data['newMessages'].edges,
    ]

    if (messageToJump?.value) {
      highlightMessage(messageToJump.value)
    }
  })

  const {
    onResult: onUpdate,
    start: startSubscription,
    stop: stopSubscription,
  } = useSubscription(UPDATE_MESSAGES_IN_CONVERSATION, {
    input: { conversationId },
  })

  onUpdate(({ data }) => {
    if (
      pageInfo.value.hasPreviousPage &&
      data.messageReceived.node.user.id !== currentUser?.value.id
    ) {
      return
    }

    if (currentUser) {
      const message = setSentStatus(data, messages, currentUser)
      if (!message) {
        messages.value = [data.messageReceived, ...messages.value]
      }

      const conversation = conversations?.value?.find(
        (conversation) => conversation.id === currentConversation?.value?.id,
      )

      if (conversation?.messages?.nodes) {
        conversation.messages = {
          nodes: [data.messageReceived.node, ...conversation.messages.nodes],
        }
      }
    }

    if (currentConversation?.value?.messages) {
      currentConversation.value.messages = {
        nodes: [messages.value[0].node],
      }
    }

    if (chatVisible?.value && initialLoad.value) {
      touchConversation(data.messageReceived.node.id)
    }
  })

  const {
    onResult: onUpdateMessage,
    start: startUpdateMessage,
    stop: stopUpdateMessage,
  } = useSubscription<MessageUpdatedType>(UPDATE_MESSAGE, {
    input: {
      conversationId,
    },
  })

  onUpdateMessage(({ data }) => {
    if (data) {
      setReadStatus(data, messages)
    }
  })

  const { fetchMore: fetchMoreAfter } = useQuery(
    AROUND_TIMESTAMP_MESSAGES_NEXT_PAGE,
    {
      conversationId: conversationId.value,
      timestamp: timestamp.value,
      after: pageInfo.value.endCursor,
      first: paginationLength,
    },
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
    },
  )

  const fetchAfter = () => {
    if (pageInfo.value.hasNextPage) {
      pageInfo.value.loadingNextPage = true
      fetchMoreAfter({
        variables: {
          conversationId: conversationId.value,
          timestamp: timestamp.value,
          after: pageInfo.value.endCursor,
          first: paginationLength,
        },
        updateQuery() {
          return
        },
      })?.then(({ data }) => {
        pageInfo.value.loadingNextPage = false
        setEndCursorAndHasNextPage(data.messages)
        messages.value = [...messages.value, ...data.messages.edges]
        callback && callback(data.messages)
      })
    }
  }

  const { fetchMore: fetchMoreBefore } = useQuery(
    AROUND_TIMESTAMP_MESSAGES_PREV_PAGE,
    {
      conversationId: conversationId.value,
      timestamp: timestamp.value,
      before: pageInfo.value.startCursor,
      last: paginationLength,
    },
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
    },
  )

  const fetchBefore = () => {
    if (pageInfo.value.hasPreviousPage) {
      pageInfo.value.loadingPreviousPage = true
      fetchMoreBefore({
        variables: {
          conversationId: conversationId.value,
          timestamp: timestamp.value,
          before: pageInfo.value.startCursor,
          last: paginationLength,
        },
        updateQuery() {
          return
        },
      })?.then(({ data }) => {
        pageInfo.value.loadingPreviousPage = false
        setStartCursorAndHasPrevPage(data.messages)
        messages.value = [...data.messages.edges, ...messages.value]
        if (
          !messages.value[0].node.read &&
          messages.value[0].node.user.id !== currentUser?.value.id
        ) {
          touchConversation(messages.value[0].node.id)
        }
        callback && callback(data.messages)
      })
    }
  }

  const enabled = inject<Ref<boolean>>('enabled')
  watch(
    () => enabled?.value,
    (value) => {
      if (value) {
        const beforeTimestamp = messages.value.findIndex(
          (message) => message.node.createdAt < timestamp.value,
        )
        let paginationBefore: number = paginationLength
        let paginationAfter: number = paginationLength
        if (beforeTimestamp !== -1) {
          paginationBefore =
            messages.value.length - beforeTimestamp + paginationLength
          paginationAfter = beforeTimestamp
        }
        let variables
        if (pageInfo.value.hasPreviousPage) {
          variables = {
            timestamp: timestamp.value as unknown as ComputedRef<string>,
            conversationId: conversationId.value as unknown as ComputedRef<
              string | undefined
            >,
            first: paginationBefore,
            last: paginationAfter,
          }
        } else {
          variables = {
            timestamp: timestamp.value as unknown as ComputedRef<string>,
            conversationId: conversationId.value as unknown as ComputedRef<
              string | undefined
            >,
            first: paginationBefore,
            last: undefined,
          }
        }
        refetch(variables)?.then(({ data }) => {
          if (data['newMessages'].edges[0] && !pageInfo.value.hasPreviousPage) {
            touchConversation(data['newMessages'].edges[0].node.id)
          }
        })
        startSubscription()
        startUpdateMessage()
      } else {
        stopSubscription()
        stopUpdateMessage()
      }
    },
  )

  const highlightMessage = (message: MessageType) => {
    const messageToHighlight = messages.value.find((value) => {
      return value.node.id === message.id
    })
    if (messageToHighlight) {
      const bodyWithHighlightToSet =
        message?.bodyWithHighlight || messageToHighlight.node.body
      messageToHighlight.node.bodyWithHighlight = bodyWithHighlightToSet
      setTimeout(() => {
        unhighlightMessage(message)
      }, 1500)
    }
  }

  const unhighlightMessage = (message: MessageType) => {
    const messageToHighlight = messages.value.find((value) => {
      return value.node.id === message.id
    })

    if (messageToHighlight) {
      messageToHighlight.node.bodyWithHighlight = undefined
    }
  }

  return {
    messages,
    loading,
    initialLoad,
    pageInfo,
    fetchAfter,
    fetchBefore,
    highlightMessage,
    setTimestamp,
  }
}
