<template>
  <div class="toggle-container">
    <input
      type="checkbox"
      class="toggle-button"
      :checked="modelValue"
      :value="modelValue"
      @change="
        $emit('update:modelValue', ($event.target as HTMLInputElement)?.checked)
      "
    />
    <label v-if="label" for="toggle-button" class="toggle-label">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  const ToggleButton = defineComponent({
    props: {
      label: String,
      modelValue: Boolean,
    },
  })

  export default ToggleButton
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .toggle-container {
    display: flex;
    align-items: center;
    width: auto;
    height: 30px;

    .toggle-button {
      position: relative;
      width: 42px;
      height: 24px;
      background: $secondary-text;
      border-radius: 50px;
      outline: none;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

      &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        width: 20px;
        height: 20px;
        background-color: $background;
        border-radius: 50%;
        transform: translateX(0);
        transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }

      &:checked {
        background-color: $secondary-text-hover;

        &::after {
          transform: translateX(calc(100% - 2px));
          background-color: $background;
        }
      }
    }

    .toggle-label {
      cursor: pointer;
      margin-left: 10px;
      font-weight: $font-weight-bold;
    }
  }
</style>
