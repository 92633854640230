import { useLazyQuery } from '@vue/apollo-composable'
import { Ref, ref } from 'vue'

import { TAKE_CONVERSATION } from '@/graphql/conversations/queries'
import { DialogType } from '@/types'

type Conversation = {
  conversation: DialogType
}

export default function useConversation(conversationId?: string): {
  conversation: Ref<DialogType | undefined>
  loadConversation: (id: string) => void
  conversationLoading: Ref<boolean>
} {
  const conversation = ref<DialogType | undefined>()

  const {
    load,
    loading: conversationLoading,
    onError,
    onResult,
  } = useLazyQuery<Conversation>(
    TAKE_CONVERSATION,
    {
      id: conversationId,
      first: 1,
      after: '',
      includeCurrent: false,
    },
    {
      fetchPolicy: 'no-cache',
    },
  )

  onResult(({ data }) => (conversation.value = data?.conversation))

  onError(() => (conversation.value = undefined))

  const loadConversation = (id: string) => {
    load(TAKE_CONVERSATION, {
      id,
      first: 3,
      after: '',
      includeCurrent: false,
    })
  }

  return {
    conversation,
    loadConversation,
    conversationLoading,
  }
}
