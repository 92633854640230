import { useResult, useLazyQuery } from '@vue/apollo-composable'
import { computed, ComputedRef, ref, watch } from 'vue'

import { QUERY_CONVERSATIONS } from '@/graphql/conversations/queries'
import { DialogType, ContactType, CursorPaginationData, Cursor } from '@/types'

export default function useConversationSearch(): {
  searchCallback: (input: string) => void
  name: ComputedRef<string>
  conversationsQuery: ComputedRef<DialogType[]>
  contactsQuery: ComputedRef<Cursor<ContactType>[] | undefined>
  refetch: () => void
} {
  const params = ref<{ name: string }>({
    name: '',
  })
  const searchCallback = (input: string) => {
    params.value = { name: input }
  }
  const name = computed<string>(() => params.value.name)

  const { result, load, start, refetch } = useLazyQuery(
    QUERY_CONVERSATIONS,
    { name },
    {
      fetchPolicy: 'no-cache',
    },
  )

  const data = useResult<{
    conversations: CursorPaginationData<DialogType>
    users: CursorPaginationData<ContactType>
  }>(result)

  watch(name, () => {
    if (name.value && name.value.trim()) {
      start()
      load()
    }
  })

  const contactsQuery = computed(() =>
    data.value?.users.edges?.filter((item) => !item.node.privateConversation),
  )
  const conversationsQuery = computed(() =>
    [
      ...(data.value?.conversations.edges?.map((item) => item.node) ?? []),
      ...(data.value?.users.edges
        ?.filter((item) => item.node.privateConversation)
        .map((item) => item.node.privateConversation) ?? []),
    ].sort((a, b) => {
      if (a.messages?.nodes[0]?.createdAt && b.messages?.nodes[0]?.createdAt) {
        return b.messages?.nodes[0]?.createdAt.localeCompare(
          a.messages?.nodes[0]?.createdAt,
        )
      }
      return 0
    }),
  )

  return {
    searchCallback,
    name,
    conversationsQuery,
    contactsQuery,
    refetch,
  }
}
