<template>
  <div class="contacts-list-container">
    <MainHeader
      :title="t('contacts.header')"
      :buttons="['create', 'mute', 'fullscreen']"
      :muteAll="muteAll"
      :backAction="conversations?.length && 'toConversationsList'"
      @toConversationsList="$emit('toConversationsList')"
      @toContactsList="toContactsList"
      @toNewChat="$emit('toNewChat')"
      @search="searchCallback"
      @onDragWidget="(e: MouseEvent) => $emit('onDragWidget', e)"
      @toggleMuteAll="$emit('toggleMuteAll')"
    />
    <div v-if="name && name.trim() !== ''" class="chat-sidebar">
      <div
        v-if="conversationsQuery?.length || contactsQuery?.length"
        class="search-results-layout"
      >
        <Contact
          v-for="contact in contactsQuery"
          :key="contact.node.id"
          :contact="contact.node"
          :input="name"
          @click="$emit('toConversation', { user: contact.node })"
        />
        <div
          v-if="conversationsQuery?.length"
          class="search-results-group-header"
        >
          {{ t('contacts.recent') }}
        </div>
        <Conversation
          v-for="(conversation, index) in conversationsQuery"
          :key="`${conversation.id}-${index}`"
          :conversation="conversation"
          :input="name"
          @click="
            $emit('toConversation', {
              conversationId: conversation.id,
              conversationsQuery,
            })
          "
          @loadMoreConversations="() => $emit('loadMoreConversations')"
        />
        <Blur />
      </div>
      <div
        v-if="!conversationsQuery?.length && !contactsQuery?.length"
        class="no-results-label"
      >
        {{ t('common.noResults') }}
      </div>
    </div>
    <div
      v-if="contacts.length && (!name || name.trim() == '')"
      class="chat-sidebar"
    >
      <div class="contacts-list-body" @scroll="handleScroll">
        <Contact
          v-for="contact in contacts"
          :key="contact.node.id"
          :contact="contact.node"
          :input="name"
          @click="$emit('toConversation', { user: contact.node })"
        />
        <Blur />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  /* eslint-disable vue/script-indent */
  import {
    defineComponent,
    ref,
    computed,
    PropType,
    toRefs,
    watch,
    onActivated,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Conversation from '@/components/conversation/Conversation.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useConversationSearch from '@/compositions/conversation/useConversationSearch'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import useSubscriptionalPagination from '@/compositions/useSubscriptionalPagination'
  import { TAKE_ALL_USERS } from '@/graphql/users/queries'
  import {
    SUBSCRIBE_LAST_SEEN_UPDATED,
    UserActivityChangedType,
  } from '@/graphql/users/subscriptions'
  import { ContactType, Cursor, DialogType } from '@/types'

  const ContactsList = defineComponent({
    components: {
      Contact,
      Conversation,
      MainHeader,
      Blur,
    },
    props: {
      conversations: Object as PropType<Array<DialogType>>,
      muteAll: Boolean,
      currentConversation: Object as PropType<DialogType>,
    },
    emits: [
      'toConversation',
      'toConversationsList',
      'toContactsList',
      'toNewChat',
      'loadMoreConversations',
      'onDragWidget',
      'toggleMuteAll',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { currentConversation } = toRefs(props)

      const {
        name,
        searchCallback,
        contactsQuery,
        conversationsQuery,
        refetch,
      } = useConversationSearch()

      onActivated(() => {
        name.value && refetch()
      })

      const { values: contacts, loadMore } = useSubscriptionalPagination<
        ContactType,
        Cursor<ContactType>
      >({
        request: ref(TAKE_ALL_USERS),
        subscription: SUBSCRIBE_LAST_SEEN_UPDATED,
        fieldname: 'users',
        params: { name: computed(() => '') },
        pagination: ref({ first: 10 }),
        updateCallback: (values, data) => {
          const { activity, userId } = (data as UserActivityChangedType)
            .userActivityChanged
          const user = (values.value as Array<Cursor<ContactType>>).find(
            (user) => user.node.id === userId,
          )
          if (user) {
            user.node.lastSeenAt = activity.lastSeenAt
            user.node.status = activity.status
          }
        },
      })

      const handleScroll = useHandleScroll(loadMore)

      watch(
        () => currentConversation,
        (conversation) => {
          if (contacts?.value) {
            const contact = (
              contacts.value as unknown as Array<Cursor<ContactType>>
            ).find((contact) => {
              const currentConversation =
                contact.node?.privateConversation?.id ===
                conversation?.value?.id

              const currentUser =
                contact.node?.id ===
                conversation?.value?.users?.edges[0]?.node.id

              return currentConversation || currentUser
            })

            if (contact?.node.privateConversation && conversation?.value) {
              contact.node.privateConversation.mute = conversation?.value.mute
            }
          }
        },
        {
          deep: true,
        },
      )

      const toContactsList = () => {
        emit('toContactsList', 'ContactsList')
      }

      return {
        contacts,
        searchCallback,
        loadMore,
        name,
        handleScroll,
        toContactsList,
        contactsQuery,
        conversationsQuery,
        t,
      }
    },
  })

  export default ContactsList
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .contacts-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    :deep(.mute-button) {
      margin-left: 8px;
      margin-right: 12px;
    }

    :deep(.search-row) {
      height: 37px;
    }

    .chat-sidebar {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .search-results-layout {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    width: 100%;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .search-results-group-header {
    color: $secondary-text;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 16px;
  }

  .contacts-list-body {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .no-results-label {
    line-height: 19px;
    width: 100%;
    padding: 24px 16px;
    color: $secondary-text;
  }

  @include scroll;
</style>
