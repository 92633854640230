import { Ref, ref } from 'vue'

import { DraftMessageType } from '@/types'

type DraftMessageProps = {
  draftMessages: Ref<Array<DraftMessageType>>
  changeDraftMessages: (message: string, currentDialogId: string) => void
  removeFromDraftMessages: (dialog: string) => void
  setDraftMessages: (draft: Array<DraftMessageType>) => void
}

export default function useDraftMessage(): DraftMessageProps {
  const draftMessages = ref<Array<DraftMessageType>>([])

  const changeDraftMessages = (message: string, currentDialogId: string) => {
    const unsendMessageIndex = draftMessages.value.findIndex(
      (el: DraftMessageType) => el.dialog == currentDialogId,
    )
    if (unsendMessageIndex >= 0) {
      draftMessages.value[unsendMessageIndex].message = message
    } else {
      draftMessages.value.push({
        dialog: currentDialogId,
        message: message,
      })
    }

    localStorage.setItem('draft', JSON.stringify(draftMessages.value))
  }

  const removeFromDraftMessages = (dialog: string) => {
    draftMessages.value = draftMessages.value.filter(
      (el: DraftMessageType) => el.dialog !== dialog,
    )

    localStorage.setItem('draft', JSON.stringify(draftMessages.value))
  }

  const setDraftMessages = (draft: Array<DraftMessageType>) => {
    draftMessages.value = draft
  }

  return {
    draftMessages,
    changeDraftMessages,
    removeFromDraftMessages,
    setDraftMessages,
  }
}
