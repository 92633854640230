import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  split,
  HttpLink,
  DocumentNode,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import {
  getMainDefinition,
  offsetLimitPagination,
} from '@apollo/client/utilities'
import ActionCable from 'actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import throttle from 'lodash/throttle'
import { Emitter } from 'mitt'
import { Ref } from 'vue'

import { EmitterEvents } from './types'

export function createApolloClient(
  userToken: Ref<string>,
  emitter?: Emitter<EmitterEvents>,
): [ActionCable.Cable, ApolloClient<NormalizedCacheObject>] {
  const apolloLink = new ApolloLink((operation, forward) => {
    operation.setContext(
      ({ headers }: { headers: Record<string, string> }) => ({
        headers: {
          ...headers,
          authorization: userToken.value ? `Bearer ${userToken.value}` : null,
        },
      }),
    )
    return forward(operation)
  })

  const onUnauthorized = throttle(() => {
    if (emitter) {
      emitter.emit('unauthorizedCaught')
    }
  }, 10000)

  const resetToken = onError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ networkError = {} as any, operation }) => {
      if (networkError && networkError?.statusCode === 401 && emitter) {
        onUnauthorized()

        operation.setContext(
          ({ headers }: { headers: Record<string, string> }) => ({
            headers: {
              ...headers,
              authorization: userToken.value
                ? `Bearer ${userToken.value}`
                : null,
            },
          }),
        )
      }
    },
  )

  const wsClient = ActionCable.createConsumer(
    `${import.meta.env.VITE_WEBSOCKET_URL}`,
  )

  const wsLink = new ActionCableLink({ cable: wsClient })

  const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL })

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: false,
    },
    attempts: {
      max: 5,
      retryIf: (error) => !!error,
    },
  })

  const isSubscription = ({ query }: { query: DocumentNode }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  }

  const link = split(
    isSubscription,
    wsLink,
    from([apolloLink.concat(retryLink).concat(resetToken), httpLink]),
  )

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            users: offsetLimitPagination(['name']),
          },
        },
      },
    }),
    connectToDevTools: import.meta.env.VITE_APOLLO_DEVTOOLS === 'true',
    queryDeduplication: false,
  })

  return [wsClient, apolloClient]
}
