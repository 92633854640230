<template>
  <div
    ref="messagesListRef"
    :class="
      !popupVisible && actionPermission
        ? 'messages-layout'
        : 'messages-layout-strict'
    "
    @scroll="handleScroll"
  >
    <div v-if="pageInfo?.hasPreviousPage" class="loading-icon">
      <Spinner primary :size="14" />
    </div>
    <div class="messages-container">
      <Message
        v-for="({ node: message }, index) in messages"
        :key="`${message.id}-${message.conversation.id}-${message.createdAt}`"
        :ref="(el: any) => setFirstUnreadRef(el, message)"
        :message="message"
        :nextMessage="messages ? messages[index - 1]?.node : undefined"
        :prevMessage="messages ? messages[index + 1]?.node : undefined"
        :searchMessageText="searchMessageText"
        @openDropdown="onOpenDropdown"
        @clickReplyMessage="clickReplyMessage"
      />
      <div v-if="pageInfo?.hasNextPage" class="loading-icon">
        <Spinner primary :size="14" />
      </div>
      <transition name="dropdown" mode="out-in">
        <MessageDropdown
          v-if="popupVisible"
          v-mousedown-outside="onHideDropdown"
          :style="style"
          :message="selectedMessage"
          :showReply="showReply"
          @hide="onHideDropdown"
          @setReplyMessage="setReplyMessage"
          @showCopyPopup="showCopyPopup"
        />
      </transition>
      <transition name="copy" mode="out-in">
        <div
          v-if="isCopyPopupVisible"
          class="copy-popup"
          :style="copyPopupPosition"
          @click="hideCopyPopup"
        >
          {{ t('popups.copy') }}
        </div>
      </transition>

      <MessageDropdown
        ref="messageDropdownRef"
        v-mousedown-outside="onHideDropdown"
        class="chat-dropdown-hidden"
        :style="style"
        :message="selectedMessage"
        showReply
        @hide="onHideDropdown"
        @setReplyMessage="setReplyMessage"
        @showCopyPopup="showCopyPopup"
      />

      <div
        ref="copyPopupRef"
        class="copy-popup hidden"
        :style="copyPopupPosition"
        @click="hideCopyPopup"
      >
        {{ t('popups.copy') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PageInfo } from '@octokit/graphql-schema'
  import {
    defineComponent,
    PropType,
    onMounted,
    ref,
    watch,
    toRefs,
    computed,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Spinner from '@/components/Spinner.vue'
  import Message from '@/components/message/Message.vue'
  import MessageDropdown from '@/components/message/MessageDropdown.vue'
  import useContextMenu from '@/compositions/message/useContextMenu'
  import usePopup from '@/compositions/usePopup'
  import { scrollOffset } from '@/const'
  import { MessageType, DialogType, Cursor } from '@/types'

  export default defineComponent({
    components: {
      Message,
      MessageDropdown,
      Spinner,
    },
    directives: {
      'mousedown-outside': {
        beforeMount: (el, binding) => {
          el.clickOutsideEvent = (event: MouseEvent) => {
            if (!(el == event.target || el.contains(event.target))) {
              binding.value()
            }
          }
          document.addEventListener('mousedown', el.clickOutsideEvent)
        },
        unmounted: (el) => {
          document.removeEventListener('mousedown', el.clickOutsideEvent)
        },
      },
    },
    props: {
      messages: Object as PropType<Array<Cursor<MessageType>>>,
      messageToJump: Object as PropType<MessageType>,
      pageInfo: Object as PropType<
        PageInfo & { loadingPreviousPage: boolean; loadingNextPage: boolean }
      >,
      conversationBodyContainerRef: HTMLDivElement,
      conversationId: String,
      actionPermission: Boolean,
      large: {
        type: Boolean,
        default: false,
      },
      initialLoad: Boolean,
      currentConversation: {
        type: Object as PropType<DialogType>,
      },
      searchMessageText: {
        type: String,
        default: '',
      },
    },
    emits: [
      'fetchAfter',
      'fetchBefore',
      'setReplyMessage',
      'setMessagesListRef',
      'setToBottomButton',
      'clickReplyMessage',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const {
        conversationId,
        conversationBodyContainerRef,
        initialLoad,
        currentConversation,
        pageInfo,
        messageToJump,
      } = toRefs(props)

      const messagesListRef = ref<HTMLDivElement | null>(null)
      const copyPopupRef = ref<HTMLDivElement | null>(null)

      const [
        isCopyPopupVisible,
        showCopyPopup,
        hideCopyPopup,
        copyPopupPosition,
      ] = usePopup(messagesListRef, copyPopupRef)

      onMounted(() => {
        emit('setMessagesListRef', messagesListRef.value, firstUnreadRef.value)
      })

      const firstUnreadRef = ref<HTMLDivElement | null>(null)
      const lastMessageRef = ref<HTMLDivElement | null>(null)
      const lastMessageId = ref<number>(0)
      const setFirstUnreadRef = (
        el: { $el: HTMLDivElement },
        message: MessageType,
      ) => {
        const isMessageToJumpOrNotRead =
          (messageToJump?.value && messageToJump?.value?.id == message.id) ||
          !message.read
        if (el && isMessageToJumpOrNotRead) {
          firstUnreadRef.value = el.$el
        }
        if (+message.id > +lastMessageId.value) {
          lastMessageRef.value = el.$el
          lastMessageId.value = message.id
        }
      }

      const ignoreScroll = ref(false)
      const handleScroll = () => {
        if (ignoreScroll.value) {
          ignoreScroll.value = false
          return
        }

        if (messagesListRef?.value) {
          const offset =
            messagesListRef.value.offsetHeight - messagesListRef.value.scrollTop

          if (offset >= scrollOffset || pageInfo?.value?.hasPreviousPage) {
            emit('setToBottomButton', true)
          } else {
            emit('setToBottomButton', false)
          }
          if (
            offset + 34 >= messagesListRef.value.scrollHeight &&
            initialLoad.value &&
            !pageInfo?.value?.loadingNextPage
          ) {
            emit('fetchAfter')
          }

          if (
            messagesListRef.value.scrollTop >= -34 &&
            initialLoad.value &&
            !pageInfo?.value?.loadingPreviousPage
          ) {
            emit('fetchBefore')
          }
        }
      }

      watch(
        () => pageInfo?.value?.loadingPreviousPage,
        () => {
          if (
            !pageInfo?.value?.loadingPreviousPage &&
            lastMessageRef.value &&
            messagesListRef.value
          ) {
            ignoreScroll.value = true
            lastMessageRef.value.scrollIntoView({ block: 'end' })
            lastMessageRef.value = null
          }
        },
      )

      const messageDropdownRef = ref()
      const dropdownContainer = computed(() => messageDropdownRef?.value?.$el)

      const [
        popupVisible,
        widgetPosition,
        selectedMessage,
        onOpenDropdown,
        onHideDropdown,
        style,
      ] = useContextMenu(conversationBodyContainerRef, dropdownContainer)

      const setReplyMessage = (message: MessageType) => {
        emit('setReplyMessage', message)
      }

      const clickReplyMessage = (message: MessageType) => {
        emit('clickReplyMessage', message)
        emit('setMessagesListRef', messagesListRef.value, firstUnreadRef.value)
      }

      watch(
        () => conversationId?.value,
        (cur, prev) => {
          cur !== prev && hideCopyPopup()
        },
      )

      const showReply = computed<boolean>(() => {
        if (
          (currentConversation?.value?.type === 'PRIVATE' &&
            !(
              currentConversation?.value?.users?.edges[0] ||
              currentConversation?.value?.users?.edges[0]?.node.archived
            )) ||
          (selectedMessage.value?.status !== 'SENT' &&
            selectedMessage.value?.status !== 'READ')
        ) {
          return false
        }

        return true
      })

      return {
        popupVisible,
        widgetPosition,
        selectedMessage,
        onOpenDropdown,
        onHideDropdown,
        handleScroll,
        setReplyMessage,
        messagesListRef,
        copyPopupRef,
        firstUnreadRef,
        setFirstUnreadRef,
        isCopyPopupVisible,
        showCopyPopup,
        hideCopyPopup,
        copyPopupPosition,
        messageDropdownRef,
        style,
        showReply,
        clickReplyMessage,
        t,
      }
    },
  })
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .messages-layout,
  .messages-layout-strict {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    .messages-container {
      display: flex;
      flex-direction: column-reverse;
      max-width: 600px;
      width: 100%;
    }
  }

  .copy-popup {
    @include font-body-2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 34px;
    text-align: center;
    background-color: $primary-text;
    color: $background;
    border-radius: 3px;
    padding: 0 8px;
    box-sizing: border-box;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }

  .messages-layout {
    overflow-y: scroll;
  }

  .messages-layout-strict {
    overflow: hidden;
    padding-right: 16px;
    box-sizing: border-box;
  }

  .chat-dropdown-hidden {
    visibility: hidden;
    opacity: 0;
  }

  .dropdown-enter-from,
  .dropdown-leave-to {
    transform: scale(0.1);
    transform-origin: top left;
  }

  .dropdown-enter-active {
    transition: all 0.2s ease-out;
  }

  .dropdown-leave-active {
    transition: all 0.2s ease-in;
  }

  .copy-enter-from,
  .copy-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .copy-enter-active {
    transition: all 0.2s ease-out;
  }

  .copy-leave-active {
    transition: all 0.2s ease-out;
  }

  .loading-icon {
    cursor: progress;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .spinner {
      position: static;
    }
  }

  @include scroll;
</style>
