import { DocumentNode } from '@apollo/client/core'
import { PageInfo } from '@octokit/graphql-schema'
import { useQuery, useSubscription } from '@vue/apollo-composable'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ref, ComputedRef, Ref, inject, watch, computed } from 'vue'

import { CursorPaginationData } from '@/types'

export type PaginationProps = {
  first?: number
  last?: number
  after?: Maybe<string>
  before?: Maybe<string>
}

type SupscribtionalProps = {
  request: Ref<DocumentNode>
  subscription: DocumentNode
  fieldname: string
  params: Record<string, ComputedRef<string | number | undefined>>
  pagination: ComputedRef<PaginationProps> | Ref<PaginationProps>
  callback?: (data: unknown) => boolean | void
  updateCallback?: (values: Ref<unknown>, data: unknown) => void
}

export default function useSubscriptionalPagination<T, E>({
  request,
  subscription,
  fieldname,
  params,
  pagination,
  callback,
  updateCallback,
}: SupscribtionalProps): {
  values: Ref<Array<E>>
  pageInfo: Ref<PageInfo & { loadingNextPage: boolean }>
  loadMore: () => void
  loading: Ref<boolean>
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const values = ref<Array<any>>([])
  const pageInfo = ref<PageInfo & { loadingNextPage: boolean }>({
    startCursor: '',
    endCursor: '',
    loadingNextPage: false,
    hasPreviousPage: false,
    hasNextPage: false,
  })
  const enabled = inject<Ref<boolean>>('enabled')

  const setEndCursorAndHasNextPage = (data: CursorPaginationData<T>) => {
    pageInfo.value.endCursor = data.pageInfo.endCursor
    pageInfo.value.hasNextPage = data.pageInfo.hasNextPage
  }

  const variables = computed(() => ({
    ...Object.keys(params).reduce<Record<string, string | number | undefined>>(
      (prev, key) => {
        prev[key] = params[key].value
        return prev
      },
      {},
    ),
    ...pagination.value,
  }))

  const {
    onResult: onLoad,
    fetchMore,
    loading,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useQuery<any, PaginationProps>(request, variables, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
  })
  const {
    onResult: onUpdate,
    start: startSubscription,
    stop: stopSubscription,
  } = useSubscription(subscription, {
    input: params,
  })

  onLoad(({ data }) => {
    setEndCursorAndHasNextPage(data[fieldname])
    callback && callback(data)
    values.value = data[fieldname].edges
  })
  onUpdate(({ data }) => {
    if (!pageInfo.value.hasPreviousPage) {
      updateCallback && updateCallback(values, data)
    }
  })

  const loadMore = () => {
    if (pageInfo.value.hasNextPage) {
      pageInfo.value.loadingNextPage = true
      fetchMore({
        variables: { after: pageInfo.value.endCursor, first: 10 },
        updateQuery() {
          return
        },
      })?.then(({ data }) => {
        pageInfo.value.loadingNextPage = false
        setEndCursorAndHasNextPage(data[fieldname])
        values.value = [...values.value, ...data[fieldname].edges]
        callback && callback(data)
      })
    }
  }

  watch(
    () => enabled?.value,
    (value) => {
      value ? startSubscription() : stopSubscription()
    },
  )

  return {
    values,
    pageInfo,
    loadMore,
    loading,
  }
}
