/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClients } from '@vue/apollo-composable'
import mitt, { Emitter } from 'mitt'
import {
  createApp,
  ref,
  h,
  provide,
  App as Application,
  Ref,
  toRefs,
  PropType,
  watch,
} from 'vue'

import App from './App.vue'
import { createApolloClient } from './clients'
import { minutes } from './const'
import { EmitterEvents } from './types'
import { setupI18n } from './i18n'
import { locales } from './const'

class IHCHat {
  private vueApplication?: Application
  private fullScreen?: Ref<boolean>
  private emitter?: Emitter<EmitterEvents>
  private token?: Ref<string>

  bootstrap = (
    wrapper: string,
    userToken: string,
    fullScreen: boolean,
    locale = locales.EN,
  ): void => {
    this.fullScreen = ref<boolean>(fullScreen)
    this.emitter = mitt<EmitterEvents>()
    this.token = ref<string>(userToken)
    const [wsClient, apolloClient] = createApolloClient(
      this.token,
      this.emitter,
    )

    this.vueApplication = createApp(
      {
        props: {
          token: Object as PropType<Ref<string>>,
        },
        setup(props) {
          const { token } = toRefs(props)
          const authenticated = ref<boolean>(false)
          const shouldAuthenticate = ref(true)
          const enabled = ref<boolean>(false)
          let timeout: number | undefined

          const authChannel = wsClient.subscriptions.create(
            'AuthenticationChannel',
            {
              connected() {
                const authenticate = () => {
                  if (shouldAuthenticate.value) {
                    authChannel.perform('authenticate', {
                      token: token.value,
                    })
                    if (timeout) {
                      clearTimeout(timeout)
                    }

                    timeout = setTimeout(
                      authenticate,
                      minutes,
                    ) as unknown as number
                  }
                }

                authenticate()
              },
              received() {
                clearTimeout(timeout)
                timeout = undefined

                authenticated.value = true
                shouldAuthenticate.value = false
                enabled.value = true
              },
              disconnected() {
                enabled.value = false
                shouldAuthenticate.value = true
              },
            },
          )

          watch(
            () => token.value,
            () => {
              authChannel.disconnected()
              wsClient.disconnect()
              setTimeout(() => {
                wsClient.connect()
              }, 0)
            },
          )

          provide(ApolloClients, {
            default: apolloClient,
          })

          provide('enabled', enabled)

          return {
            authenticated,
          }
        },
        render() {
          return this.authenticated ? h(App) : h('div')
        },
      },
      {
        token: this.token,
      },
    )

    const i18n = setupI18n(locale)

    this.vueApplication.use(i18n)
    this.vueApplication.provide('fullScreen', this.fullScreen)
    this.vueApplication.provide('emitter', this.emitter)
    this.vueApplication.mount(wrapper)
  }

  unmount = () => {
    if (this.emitter) {
      this.emitter.all.clear()
    }
    if (this.vueApplication) {
      this.vueApplication.unmount()
    }
  }

  showFullScreen = () => {
    if (this.fullScreen && this.vueApplication) {
      this.fullScreen.value = true
    }
  }

  exitFullScreen = () => {
    if (this.fullScreen && this.vueApplication) {
      this.fullScreen.value = false
    }
  }

  updateToken = (token: string) => {
    if (this.token) {
      this.token.value = token
    }
  }
}

declare global {
  interface Window {
    IHChat: IHCHat
  }
}

const chatApp = new IHCHat()
window.IHChat = chatApp
