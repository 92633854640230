import { gql } from '@apollo/client/core'

import { DialogType } from '@/types'

import { CONVERSATION_FRAGMENT } from './queries'

export type CreateConversationType = {
  createConversation: {
    conversation: DialogType
  }
}

export type UpdateConversationType = {
  updateConversation: {
    conversation: DialogType
  }
}

export type RemoveCoversationType = {
  removeConversation: {
    conversation: DialogType
  }
}

export const TOUCH_CONVERSATION = gql`
  mutation touchConversation($input: TouchConversationInput!) {
    touchConversation(input: $input) {
      conversation {
        id
        unreadMessagesCount
        lastReadAt
      }
    }
  }
`

export const UPDATE_CONVERSATION = gql`
  mutation updateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      conversation {
        ...conversationFields
        messages(pagination: { first: 1 }) {
          nodes {
            id
            body
            createdAt
            type
            user {
              id
              name
              avatar
              archived
            }
          }
        }
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`

export const CREATE_CONVERSATION = gql`
  mutation createConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      conversation {
        ...conversationFields
        messages(pagination: { first: 1 }) {
          nodes {
            id
            body
            createdAt
            type
            user {
              id
              name
              avatar
              archived
            }
          }
        }
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`

export const REMOVE_CONVERSATION = gql`
  mutation removeConversation($input: RemoveConversationInput!) {
    removeConversation(input: $input) {
      conversation {
        id
      }
    }
  }
`

export const LEAVE_CONVERSATION = gql`
  mutation updateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      conversation {
        id
      }
    }
  }
`
