<template>
  <div
    v-if="!fullScreen && !loading"
    ref="main"
    class="main-web"
    :style="positionStyle as any"
    @click="updateActivity"
    @keyup="updateActivity"
  >
    <transition :name="`widget-${left ? 'l' : 'r'}${top ? 't' : 'b'}`">
      <WidgetRoot
        v-show="chatVisible"
        :actionPermission="actionPermission"
        :style="widgetStyle"
        :chatVisible="chatVisible"
        :conversationId="conversationId"
        :muteAll="muteAll"
        @toggleMuteAll="toggleMuteAll(!muteAll)"
        @onDragWidget="onMouseDown"
        @unreadValue="updateUnreadValue"
        @notifications="onNotificationsUpdate"
        @setConversationId="(id: string) => (conversationId = id)"
      />
    </transition>

    <ChatToggleButton
      :chatVisible="chatVisible"
      :style="buttonStyle"
      :unreadCount="unreadCount"
      @click="toggleChatVisibility"
      @mousedown="onDragWidgetButton"
    />
  </div>
  <teleport to="#app-fullscreen">
    <FullScreenRoot
      v-if="fullScreen"
      class="main-web-fullscreen"
      :actionPermission="actionPermission"
      :muteAll="muteAll"
      @toggleMuteAll="toggleMuteAll(!muteAll)"
      @click="updateActivity"
      @keyup="updateActivity"
      @unreadValue="updateUnreadValue"
    />

    <transition :name="`widget-${left ? 'l' : 'r'}${top ? 't' : 'b'}`">
      <Notifications
        v-if="!chatVisible && notifications.length && !muteAll && !fullScreen"
        :notifications="notifications"
        @open="
          (id: string) => {
            conversationId = id
            chatVisible = true
          }
        "
        @toggleMuteAll="toggleMuteAll(true)"
      />
    </transition>
  </teleport>
</template>

<script lang="ts">
  /* eslint-disable vue/script-indent */
  import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
  import { useI18n } from 'vue-i18n'
  import cloneDeep from 'lodash/cloneDeep'
  import throttle from 'lodash/throttle'
  import { Emitter } from 'mitt'
  import {
    defineComponent,
    ref,
    computed,
    watch,
    provide,
    inject,
    Ref,
    onMounted,
  } from 'vue'

  import Notifications from '@/components/Notifications.vue'
  import ChatToggleButton from '@/components/button/ChatToggleButton.vue'
  import useDraggable from '@/compositions/useDraggable'
  import useMuteAll from '@/compositions/useMuteAll'
  import {
    UPDATE_USER_LAST_SEEN_AT,
    UPDATE_USER_SETTINGS,
  } from '@/graphql/users/mutations'
  import { TAKE_SETTINGS, TAKE_CURRENT_USER } from '@/graphql/users/queries'
  import FullScreenRoot from '@/pages/fullScreen/FullScreenRoot.vue'
  import WidgetRoot from '@/pages/widget/WidgetRoot.vue'
  import { askNotificationPermission, truncateUserName } from '@/utils'

  import {
    sevenSeconds,
    notificationsPermissions,
    tabStates,
    dragStartTime,
  } from './const'
  import {
    NotificationInfo,
    SettingsType,
    UserType,
    EmitterEvents,
  } from './types'

  const App = defineComponent({
    components: {
      ChatToggleButton,
      FullScreenRoot,
      Notifications,
      WidgetRoot,
    },
    setup() {
      const { result: userResult } = useQuery(TAKE_CURRENT_USER)
      const user = useResult<UserType>(userResult)
      const { t } = useI18n()
      const chatVisible = ref<boolean>(false)
      const conversationId = ref<string>()
      const fullScreen = inject<Ref<boolean>>('fullScreen')
      const isTabActive = ref<boolean>(true)
      const unreadCount = ref<number>(0)
      const emitter = inject<Emitter<EmitterEvents>>('emitter')
      const drag = ref<boolean>(false)
      const dragTimeout = ref<number>()

      onMounted(() => {
        askNotificationPermission()
      })

      const updateUnreadValue = (count: number) => {
        unreadCount.value = count

        if (emitter) {
          emitter.emit('unreadCountChanged', count)
        }
      }

      const { result: settingsResult, loading } = useQuery(TAKE_SETTINGS)
      const settings = useResult<SettingsType>(settingsResult)

      watch(settings, () => {
        chatVisible.value = settings.value?.expanded ?? true
      })

      document.addEventListener('visibilitychange', () => {
        document.visibilityState === tabStates.VISIBLE
          ? (isTabActive.value = true)
          : (isTabActive.value = false)
      })

      const { mutate: updateLastSeenAt } = useMutation(UPDATE_USER_LAST_SEEN_AT)
      const { mutate: updateSettings } = useMutation(UPDATE_USER_SETTINGS)

      const updateActivity = throttle(() => {
        updateLastSeenAt()
      }, 60000)

      provide('currentUser', user)

      const actionPermission = ref(true)

      const main = ref<HTMLElement>()
      const [position, onMouseDown] = useDraggable(main, actionPermission)

      const positionStyle = computed(() => ({
        'flex-direction': position.top ? 'column-reverse' : 'column',
        'align-items': position.left ? 'flex-start' : 'flex-end',
        [position.top ? 'top' : 'bottom']: `${
          position.top ? position.y : position.height - position.y
        }px`,
        [position.left ? 'left' : 'right']: `${
          position.left ? position.x : position.width - position.x
        }px`,
        opacity: position.drag ? '0.8' : 'initial',
        'user-select': position.drag ? 'none' : null,
        cursor: position.drag ? 'move' : 'initial',
      }))

      const br = (first: boolean, second: boolean) =>
        first && second ? '0px' : '8px'
      const widgetStyle = computed(() => ({
        'border-radius': `${br(position.left, position.top)} ${br(
          !position.left,
          position.top,
        )} ${br(!position.left, !position.top)} ${br(
          position.left,
          !position.top,
        )}`,
      }))

      const bbr = (first: boolean, second: boolean, third: boolean) =>
        first && second != third ? '0px' : '10px'
      const buttonStyle = computed(() => ({
        'border-radius': `${bbr(
          position.left,
          position.top,
          chatVisible.value,
        )} ${bbr(!position.left, position.top, chatVisible.value)} ${bbr(
          !position.left,
          !position.top,
          chatVisible.value,
        )} ${bbr(position.left, !position.top, chatVisible.value)}`,
      }))

      const notifications = ref<Array<NotificationInfo>>([])

      const onNotificationsUpdate = (data: Array<NotificationInfo>) => {
        const notificationsCopy = cloneDeep(notifications.value)
        notifications.value = data
          .reduce((prev, notification) => {
            const index = prev.findIndex((item) => item.id === notification.id)
            if (index === -1) {
              const createdTime = new Date(notification.createdAt).getTime()
              const currentTime = new Date().getTime()
              if (currentTime - createdTime > sevenSeconds) {
                notification.mutedAt = notification.createdAt
              }
              prev.push(notification)
            } else if (notification.createdAt !== prev[index].createdAt) {
              prev[index].createdAt = notification.createdAt
              prev[index].length = notification.length
            }
            return prev
          }, notificationsCopy)
          .sort((a, b) => {
            const timeA = new Date(a.createdAt).getTime()
            const timeB = new Date(b.createdAt).getTime()
            return timeA > timeB ? -1 : 1
          })
      }

      watch(
        () => notifications.value,
        (newNotifications, prevNotifications) => {
          if (
            Notification.permission === notificationsPermissions.GRANTED &&
            !chatVisible.value &&
            notifications.value.length &&
            !muteAll.value &&
            !fullScreen?.value &&
            !isTabActive.value &&
            newNotifications[0]?.createdAt > prevNotifications[0]?.createdAt
          ) {
            const { type, length, userName, userAvatar } =
              notifications.value[0]
            // eslint-disable-next-line no-undef
            const options: NotificationOptions = {
              body:
                type === 'PRIVATE'
                  ? t('notifications.user', {
                      user: truncateUserName(userName),
                      count: length,
                    })
                  : t('notifications.group', {
                      count: length,
                    }),
              icon: userAvatar,
              tag: 'chr-chat',
              renotify: true,
            }
            const notification = new Notification(userName, options)

            notification.onclick = () => {
              chatVisible.value = true
              conversationId.value = notifications.value[0].id
              window.focus()
              notification.close()
            }
          }
        },
        {
          deep: true,
        },
      )

      const toggleChatVisibility = () => {
        if (dragTimeout.value) {
          clearTimeout(dragTimeout.value)
        }
        if (!drag.value) {
          chatVisible.value = !chatVisible.value
          updateSettings({
            input: { expanded: chatVisible.value },
          })
          notifications.value.forEach(
            (notification) => (notification.mutedAt = notification.createdAt),
          )
        }
      }

      const onDragWidgetButton = (e: MouseEvent) => {
        drag.value = false
        dragTimeout.value = window.setTimeout(
          () => (drag.value = true),
          dragStartTime,
        )
        onMouseDown(e)
      }

      const { muteAll, toggleMuteAll } = useMuteAll()

      return {
        main,
        left: position.left,
        top: position.top,
        buttonStyle,
        positionStyle,
        widgetStyle,
        onMouseDown,
        chatVisible,
        toggleChatVisibility,
        updateActivity,
        unreadCount,
        fullScreen,
        actionPermission,
        loading,
        notifications,
        onNotificationsUpdate,
        conversationId,
        muteAll,
        toggleMuteAll,
        updateUnreadValue,
        drag,
        onDragWidgetButton,
      }
    },
  })

  export default App
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import url('https://fonts.googleapis.com/css?family=Lato:normal,bold&subset=latin,latin-ext');
  @import '@/styles/_colors.scss';

  .main-web {
    margin: auto;
    position: fixed;
    display: flex;
    will-change: left, right, top, bottom;
    font-family: Lato, Roboto, Ubuntu, 'Open Sans', sans-serif;
    z-index: 20;
  }

  $positions: lt rt lb rb;

  @each $pos in $positions {
    .widget-#{$pos}-enter-from,
    .widget-#{$pos}-leave-to {
      transform: scale(0.3);
      @if $pos == 'lt' {
        transform-origin: left top;
      } @else if $pos == 'lb' {
        transform-origin: left bottom;
      } @else if $pos == 'rt' {
        transform-origin: right top;
      } @else if $pos == 'rb' {
        transform-origin: right bottom;
      }
    }

    .widget-#{$pos}-enter-active {
      transition: all 0.3s ease-out;
    }

    .widget-#{$pos}-leave-active {
      transition: all 0.3s ease-in;
    }
  }

  .main-web-fullscreen {
    margin: auto;
    display: flex;
    height: 100vh;
    width: calc(100vw - 59px);
    font-family: Lato, Roboto, Ubuntu, 'Open Sans', sans-serif;
  }

  .notifications {
    font-family: Lato, Roboto, Ubuntu, 'Open Sans', sans-serif;
    bottom: 90px;
    right: 30px;
    position: fixed;
  }
</style>
