const enLocale = document.getElementById('en-locale')
enLocale.checked = true

function initDevChat(e) {
  e.preventDefault()

  const logoutBtn = document.getElementById('logout-button')
  const userIdInputForm = document.getElementById('user-id-input-form')
  const inputValue = document.getElementById('user-id-input').value
  const checkboxValue = document.getElementById('screen-checkbox').checked
  const locales = document.getElementsByName('locale')
  const locale = Array.from(locales).find((locale) => locale.checked).value

  const appHost = import.meta.env.VITE_HOST

  const getTokenUrl = appHost + '/generate_token/' + inputValue

  fetch(getTokenUrl)
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        alert("Request failed. Maybe user with this id doesn't exist.")
      }
    })
    .then((responseJson) => {
      if (responseJson) {
        window.IHChat.bootstrap(
          '#app',
          responseJson.token,
          checkboxValue,
          locale,
        )
        window.IHChat.emitter.on('unauthorizedCaught', () => {
          fetch(getTokenUrl)
            .then((res) => {
              if (res.ok) {
                return res.json()
              } else {
                alert('Refresh failed.')
              }
            })
            .then((response) => {
              window.IHChat.updateToken(response.token)
            })
        })
        window.IHChat.emitter.on('onFullscreen', () => {
          window.IHChat.showFullScreen()
          document.getElementById('screen-checkbox').checked = true
        })
        userIdInputForm.style.display = 'none'
        logoutBtn.style.display = 'block'
      }
    })

  return false
}

function logoutDevChat() {
  const logoutBtn = document.getElementById('logout-button')
  const userIdInputForm = document.getElementById('user-id-input-form')
  const inputField = document.getElementById('user-id-input')

  window.IHChat.unmount()

  logoutBtn.style.display = 'none'
  userIdInputForm.style.display = 'block'
  inputField.value = ''
}

function toggleAppScreen() {
  const screenCheckbox = document.getElementById('screen-checkbox').checked

  screenCheckbox
    ? window.IHChat.showFullScreen()
    : window.IHChat.exitFullScreen()
}

const userIdInputForm = document.getElementById('user-id-input-form')
userIdInputForm.addEventListener('submit', initDevChat)

const logoutBtn = document.getElementById('logout-button')
logoutBtn.addEventListener('click', logoutDevChat)

const screenCheckbox = document.getElementById('screen-checkbox')
screenCheckbox.addEventListener('click', toggleAppScreen)
