import { Ref, ref } from 'vue'

import { promisify } from '@/utils'

export default function useDialog(): {
  dialog: Ref<boolean>
  onDialogOpen: () => void
  onDialogClose: () => void
  onDialogConfirm: (callback?: () => void) => void
} {
  const dialog = ref<boolean>(false)

  const onDialogOpen = () => {
    dialog.value = true
  }

  const onDialogClose = () => {
    dialog.value = false
  }

  const onDialogConfirm = (callback?: () => void) => {
    if (callback) {
      const promisifiedCallback = promisify(callback)
      promisifiedCallback().then(() => (dialog.value = false))
    } else {
      dialog.value = false
    }
  }

  return {
    dialog,
    onDialogOpen,
    onDialogClose,
    onDialogConfirm,
  }
}
