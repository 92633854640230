<template>
  <div class="blur" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  const Blur = defineComponent({})

  export default Blur
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';

  .blur {
    pointer-events: none;
    position: sticky;
    bottom: 0;
    width: 100%;
    min-height: 36px;
    background: linear-gradient(360deg, $background 0%, $blur 100%);
  }
</style>
