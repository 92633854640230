import cloneDeep from 'lodash/cloneDeep'

import { Cursor, MessageType } from '@/types'

type MessagesOrder = {
  messages: Array<Cursor<MessageType>>
  shouldAssign: boolean
}

const shouldSortMessages = (
  message: Cursor<MessageType>,
  index: number,
  messagesArray: Array<Cursor<MessageType>>,
): boolean => {
  return (
    index + 1 < messagesArray.length &&
    (new Date(message.node.createdAt).getTime <
      new Date(messagesArray[index + 1].node.createdAt).getTime ||
      +message.node.id < +messagesArray[index + 1].node.id) &&
    (message.node.status === 'SENT' || message.node.status === 'READ') &&
    (messagesArray[index + 1].node.status === 'SENT' ||
      messagesArray[index + 1].node.status === 'READ')
  )
}

export const fixMessagesOrder = (
  messages: Array<Cursor<MessageType>>,
): MessagesOrder => {
  const haveWrongOrder = messages.some(shouldSortMessages)

  if (!haveWrongOrder) {
    return { messages, shouldAssign: haveWrongOrder }
  }

  const messagesCopy = cloneDeep(messages)

  messagesCopy.forEach((message, index, messagesArray) => {
    if (shouldSortMessages(message, index, messagesArray)) {
      ;[messagesArray[index], messagesArray[index + 1]] = [
        messagesArray[index + 1],
        messagesArray[index],
      ]
    }
  })

  return { messages: messagesCopy, shouldAssign: haveWrongOrder }
}
