<template>
  <div
    class="contact-container"
    :class="{ selected: userId === contact.id }"
    @mouseenter="showRemoveButton"
    @mouseleave="hideRemoveButton"
  >
    <div class="contact-item">
      <div class="contact-avatar-layout">
        <Avatar
          :src="contact.avatar"
          :lastSeen="contact.lastSeenAt"
          :status="contact.status"
          :border="contactsIds?.includes(contact.id)"
          :inConversation="inConversation"
          :archived="contact.archived"
          sidebar
        />
      </div>
      <div
        :class="{ 'contact-info-section': true, search: input?.trim() != '' }"
      >
        <div class="contact-info">
          <div
            class="contact-name"
            :class="{ mute: contact?.privateConversation?.mute }"
            v-html="contactName"
          ></div>
          <div
            v-if="contact?.privateConversation?.mute"
            data-testid="muted-icon"
            class="icon"
          >
            <UnmuteIcon />
          </div>
        </div>
        <div v-if="archived" class="contact-status">
          {{ t('status.deleted') }}
        </div>
        <div v-else-if="!contact.lastSeenAt"></div>
        <div v-else-if="isAway" class="contact-status">
          {{ isCurrentUser ? t('status.youAway') : t('status.away') }}
        </div>
        <div v-else-if="isOffline" class="contact-status">
          {{
            isCurrentUser
              ? t('status.youOffline', {
                  date: dateFormat(contact.lastSeenAt, locale),
                })
              : t('status.offline', {
                  date: dateFormat(contact.lastSeenAt, locale),
                })
          }}
        </div>
        <div v-else class="contact-status">
          {{ isCurrentUser ? t('status.youOnline') : t('status.online') }}
        </div>
      </div>
      <div v-if="select" class="contact-checkbox">
        <div
          class="checkbox-container"
          data-testid="checkbox"
          :class="{ selected: contactsIds?.includes(contact.id) }"
        >
          <DoneIcon v-if="contactsIds?.includes(contact.id)" />
        </div>
      </div>
      <div v-if="isCreator" class="group-creator">
        <span>{{ t('group.creator') }}</span>
      </div>
      <div v-if="removeButton && canRemove" class="remove-icon-container">
        <Tooltip :content="t('tooltips.remove')" placement="left">
          <button class="remove-icon" @click.stop="$emit('removeUser')">
            <CrossIcon />
          </button>
        </Tooltip>
      </div>
      <div v-if="canLeave" class="remove-icon-container">
        <Tooltip :content="t('tooltips.leave')" placement="left">
          <button class="leave-icon" @click.stop="$emit('leave')">
            <ChatExitIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, toRefs, computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import useStatus from '@/compositions/useStatus'
  import useTextHighlight from '@/compositions/useTextHighlight'
  import ChatExitIcon from '@/icons/chatBubbleExit.svg'
  import CrossIcon from '@/icons/cross.svg'
  import DoneIcon from '@/icons/done.svg'
  import UnmuteIcon from '@/icons/unmute.svg'
  import { ContactType } from '@/types'
  import { dateFormat } from '@/utils'

  const Contact = defineComponent({
    components: {
      Avatar,
      UnmuteIcon,
      DoneIcon,
      CrossIcon,
      ChatExitIcon,
      Tooltip,
    },
    props: {
      contact: {
        type: Object as PropType<ContactType>,
        required: true,
      },
      input: String,
      userId: String,
      select: {
        type: Boolean,
        default: false,
      },
      contactsIds: {
        type: Array as PropType<Array<string>>,
      },
      isCreator: Boolean,
      inConversation: Boolean,
      canRemove: {
        type: Boolean,
        default: false,
      },
      canLeave: {
        type: Boolean,
        default: false,
      },
      isCurrentUser: {
        type: Boolean,
        default: false,
      },
      archived: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['removeUser', 'leave'],
    setup(props) {
      const { t, locale } = useI18n()
      const { contact, input } = toRefs(props)
      const removeButton = ref<boolean>(false)

      const contactName = useTextHighlight(contact.value.name, input)

      const [isAway, isOffline] = useStatus(
        computed(() => contact.value.lastSeenAt),
        computed(() => contact.value.status),
      )

      const showRemoveButton = () => {
        removeButton.value = true
      }

      const hideRemoveButton = () => {
        removeButton.value = false
      }

      return {
        isAway,
        isOffline,
        contactName,
        removeButton,
        showRemoveButton,
        hideRemoveButton,
        dateFormat,
        locale,
        t,
      }
    },
  })

  export default Contact
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .contact-container {
    width: 100%;

    &:hover {
      background-color: $conversation-hover;
    }

    &.selected {
      background-color: $conversation-selected;
    }

    .contact-item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 14px 16px;
      box-sizing: content-box;

      .contact-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;

        .checkbox-container {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background: $background;
          border: 1px solid $secondary-text;
          border-radius: 50%;
          box-sizing: border-box;

          &.selected {
            background: $secondary-text-hover;
            border-color: $secondary-text-hover;
          }

          svg {
            width: 14px;
            height: 11px;
            fill: $background;
          }
        }
      }

      .group-creator {
        @include font-caption-3;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        justify-self: flex-end;
        font-weight: $font-weight-bold;
        color: $secondary-text;
        white-space: pre;
      }

      .remove-icon-container {
        display: flex;
        align-items: center;
        align-self: center;

        .remove-icon {
          cursor: pointer;
          width: 16px;
          height: 16px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background-color: transparent;
          fill: $secondary-text;

          svg {
            width: 11px;
            height: 11px;
          }

          &:hover {
            fill: $secondary-text-hover;
          }
        }

        .leave-icon {
          cursor: pointer;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background-color: transparent;
          fill: $dark-icon;

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            fill: $secondary-text-hover;
          }
        }
      }
    }

    .contact-avatar-layout {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 13px;

      :deep(.avatar) {
        margin: 0;
      }
    }

    .contact-name {
      @include font-caption-2;
      line-height: 19px;
      display: flex;
      align-items: center;
      & :deep(.highlighted) {
        display: contents;
        color: $primary-text;
        background-color: $background;
      }

      &.mute {
        color: $secondary-text;
      }
    }

    .contact-info-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $primary-text;
      gap: 6px;

      &.search {
        color: $secondary-text;
      }

      .contact-info {
        display: flex;

        .icon {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          fill: $secondary-text;
          width: 19px;
          height: 19px;
          transform: scale(0.7);
        }
      }

      .contact-status {
        @include font-body-2;
        color: $secondary-text;
        line-height: 17px;
      }
    }
  }
</style>
