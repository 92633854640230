import { computed, ComputedRef, Ref, ref } from 'vue'

import { AwayIn, OfflineIn, awayStatus, offlineStatus } from '@/const'

const timeNow = ref(new Date().getTime())

setInterval(() => {
  timeNow.value = new Date().getTime()
}, 60000)

export default function useStatus(
  date?: Ref<string | undefined>,
  status?: Ref<string | undefined>,
): Array<ComputedRef<boolean>> {
  const seenAgo = computed((): number | string | undefined => {
      return (
        date && date.value && timeNow.value - new Date(date.value).getTime()
      )
    }),
    isAway = computed((): boolean => {
      if (status && status.value === awayStatus) {
        return true
      }
      return (
        !!seenAgo.value && seenAgo.value >= AwayIn && seenAgo.value < OfflineIn
      )
    }),
    isOffline = computed((): boolean => {
      if (status && status.value === offlineStatus) {
        return true
      }
      return !seenAgo.value || seenAgo.value >= OfflineIn
    })

  return [isAway, isOffline]
}
