<template>
  <div
    :class="{
      avatar: true,
      small,
      border,
      sidebar,
      'grey-border': inConversation,
    }"
  >
    <div v-if="group" class="group-conversation-icon">
      {{ name ? getFirstLetters(name.toString()) : '' }}
      <div v-if="archived" class="group-conversation-avatar">
        <ArchivedUserIcon />
      </div>
      <img
        v-else-if="src"
        class="group-conversation-avatar"
        :src="src"
        alt="foto"
        draggable="false"
      />
    </div>
    <div v-else>
      <div v-if="archived" class="avatar-img">
        <ArchivedUserIcon />
      </div>
      <img
        v-else-if="src"
        class="avatar-img"
        :src="src"
        alt="foto"
        draggable="false"
      />
    </div>
    <div
      v-if="!isOffline"
      data-testid="status"
      :class="{
        status: true,
        away: isAway,
      }"
    />
    <div
      v-if="inConversation"
      data-testid="checkbox"
      :class="{
        done: true,
      }"
    >
      <DoneIcon />
    </div>
    <UnreadCount
      v-if="unreadCount"
      class="unread-count"
      :unreadCount="unreadCount"
      :mute="mute"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs } from 'vue'

  import UnreadCount from '@/components/UnreadCount.vue'
  import useStatus from '@/compositions/useStatus'
  import ArchivedUserIcon from '@/icons/archivedUser.svg'
  import DoneIcon from '@/icons/done.svg'
  import { getFirstLetters } from '@/utils'

  const Avatar = defineComponent({
    components: {
      UnreadCount,
      DoneIcon,
      ArchivedUserIcon,
    },
    props: {
      name: [String, Boolean],
      src: String,
      lastSeen: {
        type: String,
        default: '',
      },
      status: {
        type: String,
        default: '',
      },
      unreadCount: Number,
      group: Boolean,
      small: {
        type: Boolean,
        default: false,
      },
      sidebar: {
        type: Boolean,
        default: false,
      },
      border: {
        type: Boolean,
        default: false,
      },
      mute: {
        type: Boolean,
        default: false,
      },
      inConversation: {
        type: Boolean,
        default: false,
      },
      archived: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { lastSeen, status } = toRefs(props)
      const [isAway, isOffline] = useStatus(lastSeen, status)

      return { isAway, isOffline }
    },
    methods: {
      getFirstLetters,
    },
  })

  export default Avatar
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .avatar-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-sizing: content-box;
    overflow: hidden;

    svg {
      width: 42px;
      height: 42px;
    }
  }

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0.2em;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &.small {
      .group-conversation-icon {
        @include font-caption-1;
        width: 24px;
        height: 24px;
        line-height: 22px;
        font-size: 10px;
      }

      .avatar-img {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.sidebar {
      border: 2px solid transparent;
      box-sizing: content-box;
    }

    &.active:hover {
      border: 2px solid $secondary-text-hover;
      box-sizing: content-box;
    }

    &.border {
      border: 2px solid $secondary-text-hover;
      box-sizing: content-box;
    }

    &.grey-border {
      border: 2px solid $secondary-text;
      box-sizing: content-box;
    }
  }

  .group-conversation-avatar {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid $background;
    background: $border;
    overflow: hidden;

    svg {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .group-conversation-icon {
    @include font-caption-2;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border-radius: 50%;
    color: rgba($primary-text, 0.3);
    text-align: center;
    background: $conversation-avatar;
  }

  .status {
    position: absolute;
    bottom: -2px;
    right: -2px;
    background-color: $online-point;
    width: 8px;
    height: 8px;
    border: 2px solid $background;
    border-radius: 50%;
    box-sizing: content-box;
    &.away {
      background-color: $away-point;
    }
  }

  .done {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background-color: $secondary-text;
    width: 15px;
    height: 15px;
    border: 2px solid $background;
    border-radius: 50%;
    box-sizing: content-box;
    fill: $background;

    svg {
      width: 9px;
      height: 8px;
    }
  }

  .unread-count {
    position: absolute;
    left: -5px;
    top: -5px;
    border: 2px solid $background;
  }
</style>
