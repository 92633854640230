<template>
  <div class="new-messages-count" :class="{ mute }">
    {{ truncateUnreadCount(unreadCount.toString()) }}
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  import { truncateUnreadCount } from '@/utils'

  const UnreadCount = defineComponent({
    props: {
      unreadCount: {
        type: Number,
        required: true,
      },
      mute: Boolean,
    },
    methods: {
      truncateUnreadCount,
    },
  })

  export default UnreadCount
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .new-messages-count {
    @include font-caption-3;
    font-weight: $font-weight-bold;
    color: $background;
    background-color: $unread-badge;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-sizing: content-box;

    &.mute {
      color: $background;
      background-color: $secondary-text;
    }
  }
</style>
