import { CursorPaginationData, UserType } from '@/types'

const namesLength = 3

export function getConversationName(
  users: CursorPaginationData<UserType> | undefined,
  currentUserId: string,
): {
  name: string
  count: number
} {
  let name = ''
  let namesJoined = 0
  for (
    let i = 0;
    namesJoined < namesLength && i < (users?.edges?.length ?? 0);
    i++
  ) {
    if (users?.edges[i].node.id != currentUserId) {
      name += namesJoined ? ', ' : ''
      if (users?.edges[i].node) {
        name += users?.edges[i].node.name
      }
      namesJoined++
    }
  }

  const count =
    (users?.totalCount ?? 0) - namesJoined === 0
      ? 0
      : (users?.totalCount ?? 0) - namesJoined + 1

  return { name, count }
}
