import { useMutation, useLazyQuery, useResult } from '@vue/apollo-composable'
import { ref, Ref, computed, ComputedRef } from 'vue'

import {
  CREATE_CONVERSATION,
  UPDATE_CONVERSATION,
  CreateConversationType,
  UpdateConversationType,
} from '@/graphql/conversations/mutations'
import { TAKE_CONVERSATION_USERS_IDS } from '@/graphql/conversations/queries'
import { ContactType, Cursor, DialogType } from '@/types'

type ConversationCreate = {
  toConversation?: ({
    user,
    newConversation,
  }: {
    user?: ContactType
    newConversation?: DialogType
  }) => void
  contacts?: Ref<Array<Cursor<ContactType>>>
  conversations?: Ref<Array<DialogType> | undefined>
  currentConversation?: Ref<DialogType | undefined>
}

type ConversationUpdate = {
  conversationId: string
  removeId?: string
  callback?: () => void
}

export default function useConversationCreate({
  toConversation,
  contacts,
  conversations,
  currentConversation,
}: ConversationCreate): {
  contactsIds: Ref<Array<string>>
  chatName: Ref<string>
  handleContactsIds: (contactId: string) => void
  clearContactsIds: () => void
  handleChatName: (input: string) => void
  createConversation: (callback?: () => void, name?: string) => void
  updateConversation: ({
    conversationId,
    removeId,
    callback,
  }: ConversationUpdate) => void
  loading: Ref<boolean>
  updateLoading: Ref<boolean>
  loadUserIds: (id: string) => void
  userIds: ComputedRef<Array<string> | undefined>
  refetchUserIds: (conversationId: string) => void
} {
  const contactsIds = ref<Array<string>>([])
  const chatName = ref<string>('')

  const { mutate: createGroupConversation, loading } =
    useMutation<CreateConversationType>(CREATE_CONVERSATION)

  const { mutate: updateGroupConversation, loading: updateLoading } =
    useMutation<UpdateConversationType>(UPDATE_CONVERSATION)

  const { result, load, refetch } = useLazyQuery(TAKE_CONVERSATION_USERS_IDS, {
    id: currentConversation?.value?.id,
  })

  const data = useResult<{
    conversation: {
      users: {
        edges: Array<Cursor<ContactType>>
      }
    }
  }>(result)

  const userIds = computed(() =>
    data.value?.users.edges.map((user) => user.node.id),
  )

  const loadUserIds = (id: string) => {
    load(TAKE_CONVERSATION_USERS_IDS, { id })
  }

  const handleContactsIds = (contactId: string) => {
    const index = contactsIds.value.indexOf(contactId)

    if (index !== -1) {
      contactsIds.value.splice(index, 1)
    } else {
      contactsIds.value.push(contactId)
    }
  }

  const clearContactsIds = () => {
    contactsIds.value = []
  }

  const handleChatName = (input: string) => {
    chatName.value = input
  }

  const createConversation = (callback?: () => void, name?: string) => {
    if (!contactsIds.value.length) {
      return
    }

    if (contactsIds.value.length === 1) {
      const contact = contacts?.value.find(
        (contact) => contact.node.id === contactsIds.value[0],
      )

      if (contact && toConversation) {
        toConversation({ user: contact.node })
        clearContactsIds()
        callback && callback()
      }
    } else {
      createGroupConversation({
        input: { userIds: contactsIds.value, name },
      }).then((response) => {
        toConversation &&
          toConversation({
            newConversation: response?.data?.createConversation.conversation,
          })
        clearContactsIds()
        callback && callback()
      })
    }
  }

  const updateConversation = ({
    conversationId,
    removeId,
    callback,
  }: ConversationUpdate) => {
    if (!contactsIds.value.length && !removeId) {
      return
    }

    updateGroupConversation({
      input: {
        conversationId,
        userIds: { add: contactsIds.value, remove: [removeId] },
      },
    })
      .then((response) => {
        if (conversations && conversations.value) {
          const conversation = conversations.value.find(
            (conversation) =>
              conversation.id ===
              response?.data?.updateConversation.conversation.id,
          )

          if (
            conversation &&
            response?.data?.updateConversation.conversation.users?.totalCount
          ) {
            conversation.users =
              response?.data.updateConversation.conversation.users
          }
        }

        if (
          currentConversation &&
          currentConversation?.value?.users &&
          response?.data?.updateConversation.conversation.users
        ) {
          currentConversation.value.users =
            response?.data.updateConversation.conversation.users
        }

        clearContactsIds()
        callback && callback()
      })
      .then(() => {
        refetch({
          id: currentConversation?.value?.id,
        })
      })
  }

  const refetchUserIds = (conversationId: string) => {
    refetch({ id: conversationId })
  }

  return {
    contactsIds,
    chatName,
    loading,
    updateLoading,
    handleContactsIds,
    clearContactsIds,
    handleChatName,
    createConversation,
    updateConversation,
    loadUserIds,
    userIds,
    refetchUserIds,
  }
}
