import { useQuery, useSubscription } from '@vue/apollo-composable'
import { inject, ref, Ref, watch } from 'vue'

import useNavigation from '@/compositions/useNavigation'
import { TAKE_ALL_CONVERSATIONS } from '@/graphql/conversations/queries'
import { UPDATE_UNREAD_CONVERSATIONS } from '@/graphql/conversations/subscriptions'
import { UPDATE_MESSAGES_IN_CONVERSATION } from '@/graphql/messages/subscriptions'
import {
  SUBSCRIBE_LAST_SEEN_UPDATED,
  UserActivityChangedType,
} from '@/graphql/users/subscriptions'
import {
  ContactType,
  CursorPaginationData,
  DialogType,
  ComponentType,
} from '@/types'
import { toCollection } from '@/utils'

type ConversationsProps = {
  first: number
  after: string
  unreadValueCallback: (unreadCount: number) => void
  redirect: boolean
}

type DialogProps = {
  conversationId?: string
  user?: ContactType
  conversationsQuery?: Array<DialogType>
  newConversation?: DialogType
}

export default function useConversations({
  first,
  after = '',
  unreadValueCallback,
  redirect,
}: ConversationsProps): {
  conversations: Ref<Array<DialogType>>
  conversationRef: Ref<DialogType | undefined>
  currentComponent: Ref<ComponentType>
  toComponent: (component: ComponentType) => void
  toConversation: ({ conversationId, user }: DialogProps) => void
  loading: Ref<boolean>
  loadMoreConversations: () => void
  conversationsCount: Ref<number>
  refetchConversations: (callback: () => void) => void
} {
  const conversations = ref<Array<DialogType>>([])
  const endCursor = ref<string>('')
  const hasNextPage = ref<boolean>(false)
  const conversation = ref<DialogType>()
  const conversationsCount = ref<number>(0)
  const enabled = inject<Ref<boolean>>('enabled')
  const { currentComponent, toComponent } = useNavigation()

  const {
    onResult: onConversationsLoad,
    fetchMore,
    refetch,
    loading,
  } = useQuery(
    TAKE_ALL_CONVERSATIONS,
    {
      first,
      after,
    },
    { fetchPolicy: 'no-cache' },
  )

  onConversationsLoad(({ data }) => {
    unreadValueCallback(data.conversations.totalUnreadMessages)
    setEndCursorAndHasNextPage(data.conversations)
    conversations.value = toCollection<DialogType>(data.conversations.edges)
    if (!conversations.value.length) {
      toComponent('ContactsList')
    }
    conversationsCount.value = data.conversations.totalCount
  })

  const {
    onResult: onUpdateUnreadConversations,
    start: startUnreadSubscription,
    stop: stopUnreadSubscription,
  } = useSubscription(UPDATE_UNREAD_CONVERSATIONS)

  onUpdateUnreadConversations(({ data }) => {
    unreadValueCallback(data.unreadMessages.totalUnreadMessagesCount)
    data.unreadMessages.unreadByConversation.forEach(
      (unread: { conversationId: string; unreadMessagesCount: number }) => {
        const conversation = conversations.value.find(
          (conversation) => unread.conversationId === conversation.id,
        )
        if (conversation) {
          conversation.unreadMessagesCount = unread.unreadMessagesCount
        }
      },
    )

    refetch({ first: conversations.value.length + 1, after: '' })
  })

  const { start: startMessagesSubscription, stop: stopMessagesSubscription } =
    useSubscription(UPDATE_MESSAGES_IN_CONVERSATION)

  const loadMoreConversations = () => {
    if (hasNextPage.value) {
      fetchMore({
        variables: { after: endCursor.value },
        updateQuery: () => {
          return
        },
      })?.then(({ data }) => {
        setEndCursorAndHasNextPage(data.conversations)
        conversations.value = [
          ...conversations.value,
          ...toCollection<DialogType>(data.conversations.edges),
        ]
        conversationsCount.value = data.conversations.totalCount
      })
    }
  }

  const setEndCursorAndHasNextPage = (
    data: CursorPaginationData<DialogType>,
  ) => {
    endCursor.value = data.pageInfo.endCursor
    hasNextPage.value = data.pageInfo.hasNextPage
  }

  const {
    onResult: onUpdateLastSeen,
    start: startLastSeenSubscription,
    stop: stopLastSeenSubscription,
  } = useSubscription(SUBSCRIBE_LAST_SEEN_UPDATED)

  onUpdateLastSeen(({ data }) => {
    const { userId, activity } = (data as UserActivityChangedType)
      .userActivityChanged
    const conv = conversations.value.find((conversation) => {
      return (
        conversation.type === 'PRIVATE' &&
        userId === conversation.users?.edges[0]?.node.id
      )
    })
    if (conv?.users) {
      conv.users = {
        totalCount: conv.users.totalCount,
        pageInfo: conv.users.pageInfo,
        edges: [
          {
            node: {
              ...conv.users.edges[0].node,
              lastSeenAt: (data as UserActivityChangedType).userActivityChanged
                .activity.lastSeenAt,
              status: activity.status,
            },
            cursor: conv.users.edges[0].cursor,
          },
        ],
      }
    }

    if (
      conversation?.value?.users &&
      userId === conversation.value.users?.edges[0]?.node.id &&
      conversation?.value.type === 'PRIVATE'
    ) {
      conversation.value.users = {
        totalCount: conversation.value.users.totalCount,
        pageInfo: conversation.value.users.pageInfo,
        edges: [
          {
            node: {
              ...conversation.value.users.edges[0].node,
              lastSeenAt: (data as UserActivityChangedType).userActivityChanged
                .activity.lastSeenAt,
              status: activity.status,
            },
            cursor: conversation.value.users.edges[0].cursor,
          },
        ],
      }
    }
  })

  watch(
    () => enabled?.value,
    (value) => {
      if (value) {
        refetch({ first: conversations.value.length, after: '' })
        startUnreadSubscription()
        startMessagesSubscription()
        startLastSeenSubscription()
      } else {
        stopUnreadSubscription()
        stopMessagesSubscription()
        stopLastSeenSubscription()
      }
    },
  )

  const toConversation = ({
    conversationId,
    user,
    conversationsQuery,
    newConversation,
  }: DialogProps) => {
    if (conversationId) {
      conversation.value = conversationsQuery
        ? conversationsQuery.find(
            (conversation: DialogType) => conversation.id === conversationId,
          )
        : conversations.value.find(
            (conversation: DialogType) => conversation.id === conversationId,
          )

      if (redirect) {
        toComponent('ConversationDisplay')
      }
    } else if (user) {
      if (user.privateConversation) {
        const privateConversation = conversations.value.find(
          (conversation) => conversation.id === user.privateConversation.id,
        )
        if (!privateConversation) {
          conversations.value = [
            user.privateConversation,
            ...conversations.value,
          ]
          conversation.value = user.privateConversation
        } else {
          conversation.value = privateConversation
        }
      } else {
        conversation.value = {
          id: '0',
          type: 'PRIVATE',
          name: user.name,
          avatarUrl: user.avatar,
          unreadMessagesCount: 0,
          mute: false,
          creator: undefined,
          users: {
            edges: [{ cursor: '', node: user }],
            pageInfo: { endCursor: '', hasNextPage: false },
            totalCount: 0,
          },
        }
      }
      if (redirect) {
        toComponent('ConversationDisplay')
      }
    } else if (newConversation) {
      conversation.value = newConversation
      conversations.value = [conversation.value, ...conversations.value]
      if (redirect) {
        toComponent('ConversationDisplay')
      }
    } else {
      conversation.value = undefined
    }

    if (redirect) {
      localStorage.setItem('currentComponent', 'ConversationDisplay')
    }
  }

  const refetchConversations = (callback: () => void) => {
    refetch()?.then(callback)
  }

  watch(
    () => conversation?.value,
    (newConversation) => {
      newConversation &&
        localStorage.setItem(
          'conversationId',
          JSON.stringify(newConversation?.id),
        )
    },
  )

  watch(
    () => conversations.value,
    (newConversations) => {
      const currentConversation = newConversations.find(
        (c) => c.id === conversation.value?.id,
      )
      if (currentConversation) {
        conversation.value = currentConversation
      }
    },
  )

  return {
    conversations,
    conversationRef: conversation,
    currentComponent,
    toComponent,
    toConversation,
    loading,
    loadMoreConversations,
    conversationsCount,
    refetchConversations,
  }
}
