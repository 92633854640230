<template>
  <div class="notification" @click.stop="$emit('open')">
    <div class="notification-info">
      <Avatar
        :src="notification.userAvatar"
        small
        :name="isGroup && notification.userName"
        :group="isGroup"
      />
      <span class="user-name">
        {{ notification.userName }}
      </span>
      <span class="user-last-seen">
        {{ time(new Date(notification.createdAt), locale) }}
      </span>
    </div>
    <div class="notification-text">
      <div v-if="notification.type === 'PRIVATE'">
        <div class="notification-text-content">
          <div>
            {{
              t('notifications.user', {
                user: truncateUserName(notification.userName),
                count: notification.length,
              })
            }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="notification-text-content">
          {{
            t('notifications.group', {
              count: notification.length,
            })
          }}
        </div>
      </div>
    </div>
    <button class="close-icon" @click.stop="$emit('close')">
      <CrossIcon />
    </button>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, toRefs, watch, PropType } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import { sevenSeconds } from '@/const'
  import CrossIcon from '@/icons/cross.svg'
  import { NotificationInfo } from '@/types'
  import { time, truncateUserName } from '@/utils'

  export default defineComponent({
    components: {
      Avatar,
      CrossIcon,
    },
    props: {
      notification: {
        type: Object as PropType<NotificationInfo>,
        required: true,
      },
    },
    emits: ['open', 'close'],
    setup(props, { emit }) {
      const { t, locale } = useI18n()
      const { notification } = toRefs(props)
      let handler = setTimeout(() => {
        emit('close')
      }, sevenSeconds)

      watch(
        () => notification.value?.createdAt,
        () => {
          clearTimeout(handler)
          handler = setTimeout(() => {
            emit('close')
          }, sevenSeconds)
        },
      )

      watch(
        () => notification.value?.mutedAt,
        () => clearTimeout(handler),
      )

      const isGroup = computed(
        () =>
          notification.value?.type === 'GROUP' ||
          notification.value?.type === 'CUSTOM',
      )

      return {
        time,
        truncateUserName,
        isGroup,
        t,
        locale,
      }
    },
  })
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .notification {
    position: relative;
    margin: 8px 0;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: $background;
    cursor: pointer;
  }

  .notification-info {
    @include font-caption-3;
    display: flex;
    align-items: baseline;
    position: relative;
    margin-top: 16px;
    padding-left: 48px;

    .avatar {
      position: absolute;
      top: -8px;
      left: 16px;
    }

    .user-name {
      @include font-body-2;
      line-height: 17px;
      color: $primary-text;
      font-weight: $font-weight-bold;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 170px;
    }

    .user-last-seen {
      color: $secondary-text;
    }
  }

  .notification-text {
    @include font-body-2;
    line-height: 17px;
    color: $secondary-text;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
    margin-bottom: 12px;
    margin-left: 48px;
  }

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0;
    background: none;
    border: none;

    svg {
      width: 12px;
      height: 12px;
      fill: $secondary-text;

      &:hover {
        fill: $secondary-text-hover;
      }
    }
  }
</style>
