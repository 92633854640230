import { ref, Ref } from 'vue'

import { ComponentType } from '@/types'

export default function useNavigation(): {
  currentComponent: Ref<ComponentType>
  sidebarComponent: Ref<ComponentType>
  toComponent: (component: ComponentType) => void
  toSidebarComponent: (component: ComponentType) => void
} {
  const currentComponent = ref<ComponentType>('ConversationsList')

  const sidebarComponentToSet =
    localStorage.getItem('sidebarComponent') || 'GroupChatSettings'

  const sidebarComponent = ref<ComponentType>(
    sidebarComponentToSet as ComponentType,
  )

  const toComponent = (component: ComponentType) => {
    currentComponent.value = component

    localStorage.setItem('currentComponent', currentComponent.value)
  }

  const toSidebarComponent = (component: ComponentType) => {
    sidebarComponent.value = component

    localStorage.setItem('sidebarComponent', sidebarComponent.value)
  }

  return {
    currentComponent,
    sidebarComponent,
    toComponent,
    toSidebarComponent,
  }
}
