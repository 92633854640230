<template>
  <div v-if="!loading" class="chat-root-component">
    <keep-alive v-if="!conversationLoading">
      <component
        :is="currentComponent"
        :currentConversation="conversationRef"
        :conversations="conversations"
        :muteAll="muteAll"
        :toUpdate="false"
        @toggleMuteAll="$emit('toggleMuteAll')"
        @toContactsList="toComponent('ContactsList')"
        @toNewChat="toComponent('NewChat')"
        @toConversationsList="toComponent('ConversationsList')"
        @toConversation="toConversation"
        @loadMoreConversations="loadMoreConversations"
      />
    </keep-alive>
    <div v-show="conversationRef" class="conversation-container">
      <ConversationDisplay
        :currentConversation="conversationRef"
        :conversations="conversations"
        :actionPermission="actionPermission"
        :hideGroupInfoButton="
          conversationRef &&
          (conversationRef.type === 'GROUP' ||
            conversationRef.type === 'CUSTOM') &&
          showSidebar &&
          sidebarComponentIs('GroupChatSettings')
        "
        :hideSearchButton="showSidebar && sidebarComponentIs('SearchMessage')"
        :messageToJump="messageToJump"
        :searchMessageText="searchMessageText"
        @toggleGroupInfo="toViewGroupInfo"
        @toConversation="toConversation"
        @setConversationMute="setConversationMute"
        @refetchConversations="refetchConversations"
        @toConversationsList="toComponent('ConversationsList')"
        @toSearchMessage="toSearchMessage"
        @setJumpingMessage="setJumpingMessage"
      />
    </div>
    <div v-if="!conversationRef" class="conversation-empty">
      <EmptyChat />
      <span class="conversation-start">{{ t('new.start') }}</span>
    </div>
    <div
      v-if="conversationRef && showSidebar && checkSidebarComponent()"
      class="group-sidebar-container"
    >
      <keep-alive v-if="!conversationLoading">
        <component
          :is="sidebarComponent"
          :currentConversation="conversationRef"
          :conversations="conversations"
          toUpdate
          @toAddMembers="toSidebarComponent('NewChat')"
          @toConversation="toConversation"
          @toggleGroupInfo="toggleGroupInfo"
          @setConversationMute="setConversationMute"
          @toGroupChatSettings="toSidebarComponent('GroupChatSettings')"
          @refetchConversations="refetchConversations"
          @setJumpingMessage="setJumpingMessage"
          @setSearchMessageText="setSearchMessageText"
        />
      </keep-alive>
    </div>
    <div v-else class="empty-sidebar"></div>
  </div>
</template>

<script lang="ts">
  /* eslint-disable vue/script-indent */
  import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
  import { defineComponent, ref, watch, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'

  import useConversation from '@/compositions/conversation/useConversation'
  import useConversationMute from '@/compositions/conversation/useConversationMute'
  import useConversations from '@/compositions/conversation/useConversations'
  import useNavigation from '@/compositions/useNavigation'
  import { UPDATE_USER_FULLSCREEN_SETTINGS } from '@/graphql/users/mutations'
  import { TAKE_FULLSCREEN_SETTINGS } from '@/graphql/users/queries'
  import EmptyChat from '@/icons/emptyChat.svg'
  import { FullscreenSettingsType, ComponentType, MessageType } from '@/types'

  import ContactsList from './ContactsList.vue'
  import ConversationDisplay from './ConversationDisplay.vue'
  import ConversationsList from './ConversationsList.vue'
  import GroupChatSettings from './GroupChatSettings.vue'
  import NewChat from './NewChat.vue'
  import SearchMessage from './SearchMessage.vue'

  const FullScreenRoot = defineComponent({
    components: {
      ContactsList,
      ConversationsList,
      ConversationDisplay,
      GroupChatSettings,
      NewChat,
      EmptyChat,
      SearchMessage,
    },
    props: {
      actionPermission: Boolean,
      muteAll: Boolean,
    },
    emits: ['toggleMuteAll', 'unreadValue'],
    setup(_, { emit }) {
      const { t } = useI18n()
      const showSidebar = ref(true)
      const searchMessageText = ref<string>('')

      const setSearchMessageText = (text: string) => {
        searchMessageText.value = text
      }

      const { sidebarComponent, toSidebarComponent } = useNavigation()

      const unreadValueCallback = (unreadValue: number) => {
        emit('unreadValue', unreadValue)
      }

      const messageToJump = ref<MessageType | undefined>(undefined)

      const {
        conversations,
        currentComponent,
        toComponent,
        loading,
        toConversation,
        conversationRef,
        loadMoreConversations,
        refetchConversations,
      } = useConversations({
        first: 14,
        after: '',
        unreadValueCallback,
        redirect: false,
      })

      const { setConversationMute } = useConversationMute({
        conversations,
        conversationRef,
      })

      const { result: fullScreenResult } = useQuery(
        TAKE_FULLSCREEN_SETTINGS,
        null,
        { fetchPolicy: 'no-cache' },
      )
      const fullScreenSettings =
        useResult<FullscreenSettingsType>(fullScreenResult)

      watch(fullScreenSettings, () => {
        showSidebar.value = fullScreenSettings?.value?.showSidebar ?? true
      })

      const { mutate: updateFullscreenSettings } = useMutation(
        UPDATE_USER_FULLSCREEN_SETTINGS,
      )

      const toggleGroupInfo = (visible: boolean) => {
        showSidebar.value = visible
        updateFullscreenSettings({ input: { showSidebar: visible } })
      }

      const { conversation, loadConversation, conversationLoading } =
        useConversation()

      const toSearchMessage = () => {
        toggleGroupInfo(true)
        toSidebarComponent('SearchMessage')
      }

      const toViewGroupInfo = () => {
        toggleGroupInfo(true)
        toSidebarComponent('GroupChatSettings')
      }

      const sidebarComponentIs = (componentType: ComponentType) => {
        return (sidebarComponent.value as ComponentType) === componentType
      }

      const checkSidebarComponent = () => {
        if (
          conversationRef.value?.type === 'GROUP' ||
          conversationRef.value?.type === 'CUSTOM'
        ) {
          return true
        } else if (conversationRef.value?.type === 'PRIVATE') {
          return !(sidebarComponent.value === 'GroupChatSettings')
        } else {
          return false
        }
      }

      const setJumpingMessage = (message: MessageType) => {
        messageToJump.value = undefined
        setTimeout(() => (messageToJump.value = message), 0)
      }

      onMounted(() => {
        const mainComponent = localStorage.getItem('currentComponent')
        const update = localStorage.getItem('toUpdate')

        if (mainComponent) {
          switch (mainComponent as ComponentType) {
            case 'GroupChatSettings': {
              toSidebarComponent(mainComponent as ComponentType)
              toComponent('ConversationsList')
              break
            }
            case 'ConversationDisplay': {
              toComponent('ConversationsList')
              break
            }
            case 'NewChat': {
              if (update) {
                const toUpdate = JSON.parse(update)
                if (toUpdate) {
                  toComponent('ConversationsList')
                  toSidebarComponent('NewChat')
                } else {
                  toComponent('NewChat')
                }
              }
              break
            }
            case 'SearchMessage': {
              toSidebarComponent(mainComponent as ComponentType)
              toComponent('ConversationsList')
              break
            }
            default: {
              toComponent(mainComponent as ComponentType)
            }
          }
        }

        const conversationId = localStorage.getItem('conversationId')
        if (conversationId) {
          const savedConversationId = JSON.parse(conversationId) as string
          loadConversation(savedConversationId)
        }
      })

      const initialLoad = ref<boolean>(true)

      watch(
        () => [conversationRef?.value?.id, initialLoad.value],
        ([newId, newLoad], [prevId, prevLoad]) => {
          if (newLoad !== prevLoad || newId === prevId) {
            return
          }
          toSidebarComponent('GroupChatSettings')
        },
      )

      watch(conversation, (newValue) => {
        initialLoad.value = false
        if (newValue) {
          conversationRef.value = newValue
        } else {
          conversationRef.value = newValue
          conversations.value.length
            ? toComponent('ConversationsList')
            : toComponent('ContactsList')
        }
      })

      watch(
        () => [showSidebar.value, conversationRef?.value?.id],
        () => setSearchMessageText(''),
      )

      return {
        currentComponent,
        conversationRef,
        conversations,
        loading,
        loadMoreConversations,
        toConversation,
        toComponent,
        toggleGroupInfo,
        showSidebar,
        setConversationMute,
        refetchConversations,
        sidebarComponent,
        toSidebarComponent,
        conversationLoading,
        toSearchMessage,
        toViewGroupInfo,
        sidebarComponentIs,
        checkSidebarComponent,
        messageToJump,
        setJumpingMessage,
        searchMessageText,
        setSearchMessageText,
        t,
      }
    },
  })

  export default FullScreenRoot
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_variables.scss';
  @import '@/styles/_typography.scss';

  .chat-root-component {
    display: flex;
    border-radius: 10px 0 0 0;
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: $background;

    .conversation-container {
      flex: 1;
      min-width: 0;
    }

    .conversation-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 1165px;

      .conversation-start {
        @include font-body-1;

        display: block;
        margin-top: 32px;
        color: $secondary-text;
        opacity: 0.6;
      }
    }

    .empty-sidebar {
      width: 300px;
      min-width: 300px;
      border-left: 1px solid $border;
    }

    .group-sidebar-container {
      border-left: 1px solid $border;
      max-width: 300px;

      :deep(.contacts-list-container) {
        width: 300px;
        min-width: 300px;
        border-right: none;
      }
    }
  }

  @media (max-width: $small-screen) {
    .chat-root-component {
      .empty-sidebar {
        width: 250px;
        min-width: 250px;
      }

      .group-sidebar-container {
        width: 250px;
        min-width: 250px;

        :deep(.contacts-list-container) {
          width: 250px;
          min-width: 250px;
          border-right: none;
        }
      }
    }
  }
</style>
