import { gql } from '@apollo/client/core'

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      message {
        id
        createdAt
        conversation {
          id
        }
      }
    }
  }
`
