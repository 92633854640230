<template>
  <button :class="{ button: true, primary, secondary, danger, disabled }">
    <slot></slot>
  </button>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      primary: Boolean,
      secondary: Boolean,
      danger: Boolean,
      disabled: Boolean,
    },
  })
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .button {
    @include font-body-1;
    color: $primary-text;
    background-color: $background;
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $conversation-hover;
    }
  }

  .primary {
    font-weight: $font-weight-bold;
    border: 1px solid $secondary-text-hover;
    color: $background;
    background-color: $secondary-text-hover;
    &.disabled {
      background-color: $secondary-text;
      border: 1px solid $secondary-text;
      cursor: not-allowed;
    }
    &:hover {
      color: $background;
      background-color: $button-hover;
    }
  }

  .secondary {
    font-weight: $font-weight-bold;
    border: 1px solid $secondary-text-hover;
    color: $secondary-text-hover;
    background-color: $background;

    &.disabled {
      color: $secondary-text;
      background-color: $background;
      border: 1px solid $secondary-text;
      cursor: not-allowed;
    }
  }

  .danger {
    font-weight: $font-weight-bold;
    border: 1px solid $error;
    color: $background;
    background-color: $error;

    &.disabled {
      background-color: $secondary-text;
      border: 1px solid $secondary-text;
      cursor: not-allowed;
    }
    &:hover {
      color: $background;
      background-color: $danger;
    }
  }
</style>
