<template>
  <div class="contacts-list-container">
    <MainHeader
      :title="title"
      :buttons="buttons"
      :muteAll="muteAll"
      :backAction="conversations?.length && 'toConversationsList'"
      :contactsIds="toUpdate && contactsIds"
      :editable="!toUpdate && contactsIds.length > 1"
      @toConversationsList="backAction"
      @search="searchCallback"
      @onDragWidget="(e: MouseEvent) => $emit('onDragWidget', e)"
      @toggleMuteAll="$emit('toggleMuteAll')"
      @rename="chatTitleChange"
    />
    <div
      v-if="contacts.length"
      class="contacts-list-body"
      @scroll="handleScroll"
    >
      <Contact
        v-for="contact in contacts"
        :key="contact.node.id"
        :contact="contact.node"
        :input="name"
        :select="!toUpdate || !userIds?.includes(contact.node.id)"
        :contactsIds="contactsIds"
        :inConversation="toUpdate && userIds?.includes(contact.node.id)"
        @click="
          ;(!toUpdate || !userIds?.includes(contact.node.id)) &&
            handleContactsIds(contact.node.id)
        "
      />
      <Blur :class="{ high: contactsIds.length }" />
    </div>
    <div v-else class="no-results-label">{{ t('common.noResults') }}</div>
    <transition name="appear">
      <div v-if="contactsIds.length" class="buttons-container">
        <Button
          secondary
          class="cancel-button"
          :class="{ wide: !toUpdate }"
          @click="backAction"
        >
          {{ t('popups.defaultCancelText') }}
        </Button>
        <Button
          primary
          @click="
            toUpdate
              ? updateConversation({
                  conversationId: currentConversation?.id ?? '0',
                  callback: backAction,
                })
              : onChatCreate()
          "
        >
          <div v-if="loading || updateLoading" class="spinner-container">
            <Spinner secondary size="20" />
          </div>
          <span v-else>
            {{ buttonText }}
          </span>
        </Button>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  /* eslint-disable vue/script-indent */
  import {
    defineComponent,
    ref,
    computed,
    PropType,
    toRefs,
    watch,
    onDeactivated,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Spinner from '@/components/Spinner.vue'
  import Button from '@/components/button/Button.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useConversationCreate from '@/compositions/conversation/useConversationCreate'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import useSubscriptionalPagination from '@/compositions/useSubscriptionalPagination'
  import { TAKE_ALL_USERS } from '@/graphql/users/queries'
  import {
    SUBSCRIBE_LAST_SEEN_UPDATED,
    UserActivityChangedType,
  } from '@/graphql/users/subscriptions'
  import { ContactType, Cursor, DialogType } from '@/types'
  import {
    convertCharacterSequenceToSymbol,
    convertSymbolToCharacterSequence,
  } from '@/utils/messages/convertSpecialCharacters'

  const NewChat = defineComponent({
    components: {
      Contact,
      MainHeader,
      Blur,
      Button,
      Spinner,
    },
    props: {
      conversations: Object as PropType<Array<DialogType>>,
      muteAll: Boolean,
      currentConversation: Object as PropType<DialogType>,
      toUpdate: Boolean,
    },
    emits: [
      'toConversation',
      'toConversationsList',
      'toGroupChatSettings',
      'onDragWidget',
      'toggleMuteAll',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { currentConversation, toUpdate, conversations } = toRefs(props)

      const buttons = computed<Array<string>>(() =>
        toUpdate?.value ? [] : ['mute', 'fullscreen'],
      )

      const params = ref<{ name: string }>({
        name: '',
      })

      const searchCallback = (input: string) => {
        params.value = { name: input }
      }
      const name = computed<string>(() => params.value.name)

      const { values: contacts, loadMore } = useSubscriptionalPagination<
        ContactType,
        Cursor<ContactType>
      >({
        request: ref(TAKE_ALL_USERS),
        subscription: SUBSCRIBE_LAST_SEEN_UPDATED,
        fieldname: 'users',
        params: { name },
        pagination: ref({ first: 10 }),
        updateCallback: (values, data) => {
          const { activity, userId } = (data as UserActivityChangedType)
            .userActivityChanged
          const user = (values.value as Array<Cursor<ContactType>>).find(
            (user) => user.node.id === userId,
          )
          if (user) {
            user.node.lastSeenAt = activity.lastSeenAt
            user.node.status = activity.status
          }
        },
      })

      const toConversation = ({
        user,
        newConversation,
      }: {
        user?: ContactType
        newConversation?: DialogType
      }) => {
        emit('toConversation', { user, newConversation })
      }

      const {
        contactsIds,
        handleContactsIds,
        clearContactsIds,
        createConversation,
        updateConversation,
        loading,
        updateLoading,
        loadUserIds,
        userIds,
        refetchUserIds,
      } = useConversationCreate({
        contacts,
        toConversation,
        conversations,
        currentConversation,
      })

      watch(
        () => toUpdate?.value,
        (value) => {
          value &&
            currentConversation?.value?.id &&
            loadUserIds(currentConversation.value.id)
        },
        {
          immediate: true,
        },
      )

      watch(
        () => currentConversation?.value?.users?.totalCount,
        () =>
          currentConversation?.value?.id &&
          refetchUserIds(currentConversation.value.id),
      )

      const handleScroll = useHandleScroll(loadMore)

      const buttonText = computed<string>(() => {
        if (toUpdate?.value) {
          return t('add.add')
        }
        return contactsIds?.value.length > 1 ? t('new.create') : t('new.go')
      })

      const title = computed(() => {
        return (
          (contactsIds.value.length > 1 &&
            !toUpdate.value &&
            chatTitle.value) ||
          (toUpdate.value ? t('add.header') : t('new.header'))
        )
      })

      watch(
        () => currentConversation,
        (conversation) => {
          if (contacts?.value) {
            const contact = (
              contacts.value as unknown as Array<Cursor<ContactType>>
            ).find((contact) => {
              const currentConversation =
                contact.node?.privateConversation?.id ===
                conversation?.value?.id

              const currentUser =
                contact.node?.id ===
                conversation?.value?.users?.edges[0]?.node.id

              return currentConversation || currentUser
            })

            if (contact?.node.privateConversation && conversation?.value) {
              contact.node.privateConversation.mute = conversation?.value.mute
            }
          }
        },
        {
          deep: true,
        },
      )

      const backAction = () => {
        clearContactsIds()
        toUpdate?.value
          ? emit('toGroupChatSettings')
          : emit('toConversationsList')
        chatTitle.value = ''
      }

      const chatTitle = ref('')

      const chatTitleChange = (value: string) => {
        chatTitle.value = convertSymbolToCharacterSequence(value)
      }

      const onChatCreate = () => {
        createConversation(
          undefined,
          convertCharacterSequenceToSymbol(chatTitle.value),
        )
      }

      onDeactivated(() => chatTitleChange(''))

      return {
        contacts,
        searchCallback,
        loadMore,
        name,
        handleScroll,
        contactsIds,
        handleContactsIds,
        backAction,
        clearContactsIds,
        onChatCreate,
        updateConversation,
        loading,
        updateLoading,
        buttons,
        buttonText,
        userIds,
        chatTitle,
        chatTitleChange,
        title,
        t,
      }
    },
  })

  export default NewChat
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .contacts-list-container {
    width: 100%;
    position: relative;

    :deep(.mute-button) {
      margin-left: 8px;
      margin-right: 12px;
    }

    :deep(.search-row) {
      height: 37px;
    }

    .contacts-list-body {
      width: 100%;
      height: 360px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .no-results-label {
      @include font-body-1;
      line-height: 19px;
      display: flex;
      align-items: center;
      padding: 24px 16px;
      color: $secondary-text;
    }

    .buttons-container {
      display: flex;
      padding: 12px 16px;
      box-sizing: border-box;
      background: $background;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid $border;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      .cancel-button {
        margin-right: 10px;

        &.wide {
          max-width: 136px;
        }
      }

      .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .high {
    min-height: 63px;
  }

  .appear-enter-from,
  .appear-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .appear-enter-active {
    transition: all 0.1s ease-out;
  }

  .appear-leave-active {
    transition: all 0.1s ease-out;
  }

  @include scroll;
</style>
