import {
  Placement,
  arrow,
  flip,
  hide,
  offset,
  preventOverflow,
  createPopper,
  Instance,
} from '@popperjs/core'
import { toRefs, watch, nextTick, onBeforeUnmount, reactive, Ref } from 'vue'

const toInt = (x: string) => parseInt(x, 10)

type PopperProps = {
  arrowPadding: Ref<string>
  emit: (emitFunc: 'close:popper' | 'open:popper') => void
  offsetDistance: Ref<string>
  offsetSkid: Ref<string>
  placement: Ref<string>
  popperNode: Ref<HTMLElement | null>
  triggerNode: Ref<HTMLElement | null>
}

type PopperState = {
  isOpen: boolean
  popperInstance: Instance | null
}

export default function usePopper({
  arrowPadding,
  emit,
  offsetDistance,
  offsetSkid,
  placement,
  popperNode,
  triggerNode,
}: PopperProps): {
  open: () => void
  close: () => void
  isOpen: Ref<boolean>
  popperInstance: Ref<Instance | null>
} {
  const state = reactive<PopperState>({
    isOpen: false,
    popperInstance: null,
  })

  const close = () => {
    if (!state.isOpen) {
      return
    }

    state.isOpen = false
    emit('close:popper')
  }

  const open = () => {
    if (state.isOpen) {
      return
    }

    state.isOpen = true

    if (popperNode.value) {
      popperNode.value.style.setProperty('inset', '')
    }
    emit('open:popper')
  }

  watch([() => state.isOpen, placement], ([isOpen]) => {
    if (isOpen) {
      initializePopper()
    }
  })

  const initializePopper = async () => {
    await nextTick()
    if (triggerNode.value && popperNode.value) {
      state.popperInstance = createPopper(triggerNode.value, popperNode.value, {
        placement: placement.value as Placement,
        modifiers: [
          preventOverflow,
          flip,
          arrow,
          hide,
          {
            name: 'arrow',
            options: {
              padding: toInt(arrowPadding.value),
            },
          },
          offset,
          {
            name: 'offset',
            options: {
              offset: [toInt(offsetSkid.value), toInt(offsetDistance.value)],
            },
          },
        ],
      })
    }

    state.popperInstance && state.popperInstance.update()
  }

  onBeforeUnmount(() => {
    state.popperInstance?.destroy()
  })

  return {
    ...toRefs(state),
    open,
    close,
  }
}
