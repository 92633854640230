import { convertCharacterSequenceToSymbol } from '@/utils/messages/convertSpecialCharacters'

export function getFirstLetters(dialogName: string): string {
  const words = convertCharacterSequenceToSymbol(dialogName)
    .replace(/[&<>'"]/g, '')
    .split(' ')
  const firstLetters =
    words.length > 1
      ? words
          .map((n) => n[0])
          .join('')
          .slice(0, 2)
      : words[0].slice(0, 2)
  return firstLetters.toUpperCase()
}
