<template>
  <ul class="message-dropdown">
    <li class="action" @mousedown="copy">
      <div class="action-icon">
        <Copy class="copy" />
      </div>
      <div class="action-text">
        <h5 class="action-text">{{ t('dropdowns.message.copy') }}</h5>
      </div>
    </li>
    <li v-if="showReply" class="action" @mousedown.prevent="reply">
      <div class="action-icon">
        <Reply class="reply" />
      </div>
      <div class="action-text">
        <h5 class="action-text">{{ t('dropdowns.message.reply') }}</h5>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Copy from '@/icons/copy.svg'
  import Reply from '@/icons/reply.svg'
  import { MessageType } from '@/types'
  import { convertCharacterSequenceToSymbol } from '@/utils/messages/convertSpecialCharacters'

  const MessageDropdown = defineComponent({
    components: {
      Copy,
      Reply,
    },
    props: {
      message: {
        type: Object as PropType<MessageType>,
      },
      showReply: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['hide', 'setReplyMessage', 'showCopyPopup'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { message } = toRefs(props)

      const copy = () => {
        if (message?.value) {
          let fakeEl = document.createElement('textarea')
          document.body.appendChild(fakeEl)
          fakeEl.value = convertCharacterSequenceToSymbol(message.value.body)
          fakeEl.select()
          document.execCommand('copy')
          document.body.removeChild(fakeEl)
          emit('hide')
          emit('showCopyPopup')
        }
      }

      const reply = () => {
        if (message?.value) {
          emit('setReplyMessage', message.value)
          emit('hide')
        }
      }

      return {
        copy,
        reply,
        t,
      }
    },
  })

  export default MessageDropdown
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .message-dropdown {
    position: absolute;
    flex-direction: column;
    padding: 3px;
    border-radius: 10px;
    background: $background;
    box-shadow: 0px 3px 10px $dropdown-shadow;
    box-sizing: border-box;
  }

  .action {
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 6px 10px;
    box-sizing: border-box;

    .action-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      fill: $secondary-text;

      .copy {
        width: 17px;
        height: 20px;
      }

      .reply {
        width: 17px;
        height: 11px;
      }
    }

    .action-text {
      @include font-body-2;

      color: $primary-text;
    }

    &:hover {
      .action-text {
        color: $secondary-text-hover;
      }

      svg {
        fill: $secondary-text-hover;
      }
    }

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }
  }
</style>
