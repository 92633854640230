<template>
  <div
    v-click-away="{ handler: closePopper, enabled: enableClickAway }"
    class="tooltip-container"
    @mouseleave="closePopper"
  >
    <div
      ref="triggerNode"
      class="inline-block"
      @mouseover="openPopper"
      @click="togglePopper"
      @focus="openPopper"
      @keyup.esc="closePopper"
    >
      <slot />
    </div>
    <transition name="fade">
      <div
        v-show="shouldShowPopper"
        ref="popperNode"
        :class="['popper', shouldShowPopper ? 'inline-block' : null]"
        @click="closePopper"
        @mouseenter="closePopper"
      >
        <slot name="content" :close="close" :isOpen="modifiedIsOpen">
          {{ content }}
        </slot>
        <div v-if="arrow" id="arrow" data-popper-arrow></div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import {
    ref,
    computed,
    defineComponent,
    toRefs,
    watch,
    watchEffect,
  } from 'vue'

  import useContent from '@/compositions/tooltip/useContent'
  import usePopper from '@/compositions/tooltip/usePopper'

  const Tooltip = defineComponent({
    directives: {
      clickAway: {
        beforeMount: (el, binding) => {
          el.clickAwayEvent = (event: MouseEvent) => {
            if (
              !(el == event.target || el.contains(event.target)) &&
              binding.value.enabled
            ) {
              binding.value.handler()
            }
          }
          document.addEventListener('click', el.clickAwayEvent)
        },
        unmounted: (el) => {
          document.removeEventListener('click', el.clickAwayEvent)
        },
      },
    },
    props: {
      placement: {
        type: String,
        default: 'bottom',
        validator: (value: string) => {
          return [
            'auto',
            'auto-start',
            'auto-end',
            'top',
            'top-start',
            'top-end',
            'bottom',
            'bottom-start',
            'bottom-end',
            'right',
            'right-start',
            'right-end',
            'left',
            'left-start',
            'left-end',
          ].includes(value)
        },
      },
      disableClickAway: {
        type: Boolean,
        default: false,
      },
      offsetSkid: {
        type: String,
        default: '0',
      },
      offsetDistance: {
        type: String,
        default: '12',
      },
      show: {
        type: Boolean,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      arrow: {
        type: Boolean,
        default: true,
      },
      arrowPadding: {
        type: String,
        default: '0',
      },
      content: {
        type: String,
        default: null,
      },
    },
    emits: ['open:popper', 'close:popper'],
    setup(props, { slots, emit }) {
      const popperNode = ref(null)
      const triggerNode = ref(null)
      const modifiedIsOpen = ref<boolean>(false)
      const {
        arrowPadding,
        content,
        disableClickAway,
        disabled,
        show,
        offsetDistance,
        offsetSkid,
        placement,
      } = toRefs(props)
      const { isOpen, open, close } = usePopper({
        arrowPadding,
        emit,
        offsetDistance,
        offsetSkid,
        placement,
        popperNode,
        triggerNode,
      })
      const { hasContent } = useContent(slots, popperNode, content)
      const manualMode = computed<boolean>(() => show.value !== null)
      const invalid = computed<boolean>(
        () => disabled.value || !hasContent.value,
      )
      const shouldShowPopper = computed<boolean>(
        () => isOpen.value && !invalid.value,
      )
      const enableClickAway = computed<boolean>(
        () => !disableClickAway.value && !manualMode.value,
      )

      const openPopper = () => {
        if (invalid.value || manualMode.value) {
          return
        }
        open()
      }

      const closePopper = () => {
        if (manualMode.value) {
          return
        }
        close()
      }

      const togglePopper = () => {
        isOpen.value ? closePopper() : openPopper()
      }

      watch([hasContent, disabled], ([hasContent, disabled]) => {
        if (isOpen.value && (!hasContent || disabled)) {
          close()
        }
      })

      watch(isOpen, (isOpen) => {
        if (isOpen) {
          modifiedIsOpen.value = true
        } else {
          modifiedIsOpen.value = false
        }
      })

      watchEffect(() => {
        if (manualMode.value) {
          show.value ? open() : close()
        }
      })

      return {
        popperNode,
        triggerNode,
        close,
        shouldShowPopper,
        enableClickAway,
        modifiedIsOpen,
        togglePopper,
        closePopper,
        openPopper,
      }
    },
  })

  export default Tooltip
</script>

<style scoped lang="scss">
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  #arrow,
  #arrow::before {
    transition: background 250ms ease-in-out;
    position: absolute;
    width: calc(10px - var(--popper-theme-border-width, 0px));
    height: calc(10px - var(--popper-theme-border-width, 0px));
    box-sizing: border-box;
    background: var(--popper-theme-background-color);
  }
  #arrow {
    visibility: hidden;
  }
  #arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  .popper[data-popper-placement^='top'] > #arrow {
    bottom: -5px;
  }
  .popper[data-popper-placement^='top'] > #arrow::before {
    border-right: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
    border-bottom: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
  }

  .popper[data-popper-placement^='bottom'] > #arrow {
    top: -5px;
  }
  .popper[data-popper-placement^='bottom'] > #arrow::before {
    border-left: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
    border-top: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
  }

  .popper[data-popper-placement^='left'] > #arrow {
    right: -5px;
  }
  .popper[data-popper-placement^='left'] > #arrow::before {
    border-right: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
    border-top: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
  }

  .popper[data-popper-placement^='right'] > #arrow {
    left: -5px;
  }
  .popper[data-popper-placement^='right'] > #arrow::before {
    border-left: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
    border-bottom: var(--popper-theme-border-width)
      var(--popper-theme-border-style) var(--popper-theme-border-color);
  }

  .popper {
    @include font-body-2;
    background: $primary-text;
    border-radius: 3px;
    padding: 6px 12px;
    color: $background;
    z-index: 10;
  }

  .popper > #arrow::before {
    background: $primary-text;
  }

  .popper[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  .popper[data-popper-reference-hidden] > #arrow::before {
    width: 0;
    height: 0;
    border: 1px solid transparent;
    visibility: hidden;
    pointer-events: none;
  }

  .inline-block {
    display: inline-block;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
