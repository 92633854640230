import { createI18n, LocaleMessageDictionary, VueMessageType } from 'vue-i18n'

import { locales } from './const'

import en from '../locales/en.yaml'
import fr from '../locales/fr.yaml'

const pluralizationRules = {
  en: (choice: number) => (choice === 1 ? 0 : 1),
  fr: (choice: number) => ([0, 1].includes(choice) ? 0 : 1),
}

export const setupI18n = (locale = locales.EN) =>
  createI18n({
    legacy: false,
    locale,
    fallbackLocale: locales.EN,
    messages: {
      en: en as Record<string, LocaleMessageDictionary<VueMessageType>>,
      fr: fr as Record<string, LocaleMessageDictionary<VueMessageType>>,
    },
    pluralizationRules: {
      ...pluralizationRules,
    },
  })
