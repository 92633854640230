import findLast from 'lodash/findLast'
import { Ref } from 'vue'

import {
  MessageUpdatedType,
  MessageReceivedType,
} from '@/graphql/messages/subscriptions'
import { Cursor, MessageType, UserType } from '@/types'

export const setReadStatus = (
  data: MessageUpdatedType,
  messages: Ref<Array<Cursor<MessageType>>>,
): void => {
  const message = messages.value.find(
    (message) => message.node.id === data.messageUpdated.node.id,
  )

  for (const conversationMessage of messages.value) {
    if (conversationMessage.node.status === 'SENT') {
      conversationMessage.node.status = data.messageUpdated.node.status
    } else if (
      conversationMessage.node.status === 'ERROR' ||
      conversationMessage.node.status === 'PROCESSING'
    ) {
      continue
    } else {
      break
    }
  }

  if (message) {
    message.cursor = data.messageUpdated.cursor
    message.node = data.messageUpdated.node
  }
}

export const setSentStatus = (
  data: MessageReceivedType,
  messages: Ref<Array<Cursor<MessageType>>>,
  currentUser: Ref<UserType>,
): Cursor<MessageType> | void => {
  const message = findLast(
    messages.value as Array<Cursor<MessageType>>,
    (message) => {
      return (
        message.node.status === 'PROCESSING' &&
        message.node.body === data.messageReceived.node.body &&
        message.node.user.id === currentUser?.value.id
      )
    },
  )
  if (message) {
    message.cursor = data.messageReceived.cursor
    message.node = data.messageReceived.node
    return message
  }
}

export const setErrorStatus = (
  messages: Ref<Array<Cursor<MessageType>>>,
): void => {
  const message = findLast(
    messages.value,
    (message) => message.node.id === 0 && message.node.status === 'PROCESSING',
  )

  if (message) {
    message.node.status = 'ERROR'
  }
}
