<template>
  <div v-if="!loading" class="chat-root-component">
    <keep-alive
      v-if="!conversationLoading"
      :exclude="['ConversationDisplay', 'NewChat']"
    >
      <component
        :is="currentComponent"
        :currentConversation="conversationRef"
        :conversations="conversations"
        :actionPermission="actionPermission"
        :chatVisible="chatVisible"
        :muteAll="muteAll"
        :toUpdate="toUpdate"
        :messageToJump="messageToJump"
        :searchMessageText="searchMessageText"
        @toggleMuteAll="$emit('toggleMuteAll')"
        @toContactsList="toComponent"
        @toNewChat="toNewChat"
        @toConversationsList="toComponent('ConversationsList')"
        @onSearchMessage="toComponent('SearchMessage')"
        @toConversation="toConversation"
        @setJumpingMessage="setJumpingMessage"
        @toGroupChatSettings="toComponent('GroupChatSettings')"
        @loadMoreConversations="loadMoreConversations"
        @onDragWidget="onDragWidget"
        @setConversationMute="setConversationMute"
        @refetchConversations="refetchConversations"
        @setSearchMessageText="setSearchMessageText"
      />
    </keep-alive>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    onMounted,
    ref,
    toRefs,
    watch,
    inject,
    Ref,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import useConversation from '@/compositions/conversation/useConversation'
  import useConversationMute from '@/compositions/conversation/useConversationMute'
  import useConversations from '@/compositions/conversation/useConversations'
  import {
    NotificationInfo,
    ComponentType,
    DialogType,
    MessageType,
    UserType,
    CursorPaginationData,
  } from '@/types'
  import {
    getConversationName,
    convertCharacterSequenceToSymbol,
  } from '@/utils'

  import ContactsList from './ContactsList.vue'
  import ConversationDisplay from './ConversationDisplay.vue'
  import ConversationsList from './ConversationsList.vue'
  import GroupChatSettings from './GroupChatSettings.vue'
  import NewChat from './NewChat.vue'
  import SearchMessage from './SearchMessage.vue'

  const WidgetRoot = defineComponent({
    components: {
      ContactsList,
      ConversationDisplay,
      ConversationsList,
      GroupChatSettings,
      NewChat,
      SearchMessage,
    },
    props: {
      actionPermission: Boolean,
      chatVisible: Boolean,
      conversationId: String,
      muteAll: Boolean,
    },
    emits: [
      'unreadValue',
      'notifications',
      'onDragWidget',
      'toggleMuteAll',
      'setConversationId',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const toUpdate = ref<boolean>()
      const messageToJump = ref<MessageType | null>(null)
      const searchMessageText = ref<string>('')
      const currentUser = inject<Ref<UserType>>('currentUser')

      const setSearchMessageText = (text: string) => {
        searchMessageText.value = text
      }

      const unreadValueCallback = (unreadValue: number) => {
        emit('unreadValue', unreadValue)
      }

      const {
        conversations,
        currentComponent,
        toComponent,
        toConversation,
        conversationRef,
        loadMoreConversations,
        refetchConversations,
        loading,
      } = useConversations({
        first: 6,
        after: '',
        unreadValueCallback,
        redirect: true,
      })

      const defaultConversationName = (
        users: CursorPaginationData<UserType>,
        id: string,
      ) => {
        const { name, count } = getConversationName(users, id)

        return count
          ? t('conversation.andMore', { name, count })
          : t('conversation.andYou', { name })
      }

      watch(
        () => conversations.value,
        () => {
          emit(
            'notifications',
            conversations.value.reduce<Array<NotificationInfo>>(
              (prev, conversation) => {
                if (
                  conversation.unreadMessagesCount &&
                  !conversation.mute &&
                  initialLoad.value
                ) {
                  const name =
                    conversation.name ||
                    (conversation.users &&
                      currentUser &&
                      defaultConversationName(
                        conversation.users,
                        currentUser.value.id,
                      )) ||
                    ''
                  prev.push({
                    id: conversation.id,
                    length: conversation.unreadMessagesCount,
                    createdAt: conversation.messages?.nodes[0].createdAt ?? '',
                    mutedAt: undefined,
                    userName: convertCharacterSequenceToSymbol(name),
                    userAvatar: conversation.avatarUrl ?? '',
                    type: conversation.type,
                  })
                }
                return prev
              },
              [],
            ),
          )
        },
        { deep: true },
      )

      const { setConversationMute } = useConversationMute({
        conversations,
        conversationRef,
      })

      const onDragWidget = (e: MouseEvent) => {
        emit('onDragWidget', e)
      }

      const setJumpingMessage = (message: MessageType) => {
        messageToJump.value = message
      }

      const { conversationId, chatVisible } = toRefs(props)

      watch(
        () => [chatVisible?.value, conversationId?.value],
        ([newVisible], [prevVisible]) => {
          if (newVisible !== prevVisible) {
            if (conversationId?.value && conversationId?.value !== '0') {
              toConversation({ conversationId: conversationId.value })
            }
          }
        },
      )

      watch(
        () => [conversationRef?.value?.id, currentComponent.value],
        ([id, component]) => {
          if (
            component !== 'ConversationDisplay' &&
            component !== 'SearchMessage'
          ) {
            emit('setConversationId', undefined)
          } else {
            emit('setConversationId', id)
          }
        },
      )

      const toNewChat = (update = false) => {
        toUpdate.value = update
        toComponent('NewChat')
      }

      watch(toUpdate, (newValue) =>
        localStorage.setItem('toUpdate', JSON.stringify(newValue)),
      )

      const { conversation, loadConversation, conversationLoading } =
        useConversation()

      onMounted(() => {
        const mainComponent = localStorage.getItem('currentComponent')
        const update = localStorage.getItem('toUpdate')

        if (mainComponent) {
          currentComponent.value = mainComponent as ComponentType
        }
        if (update) {
          toUpdate.value = JSON.parse(update) as boolean
        }

        const conversationId = localStorage.getItem('conversationId')
        if (conversationId) {
          const savedConversationId = JSON.parse(conversationId) as string
          loadConversation(savedConversationId)
        }
      })

      const initialLoad = ref<boolean>(false)
      watch(
        () => [loading.value, conversationLoading.value],
        ([newLoading, newConversationLoading]) => {
          if (!newLoading && !newConversationLoading) {
            initialLoad.value = true
          }
        },
      )

      watch(initialLoad, () => {
        if (conversation.value) {
          conversationRef.value = conversation.value as DialogType
          if (currentComponent.value === 'ConversationDisplay') {
            toComponent('ConversationDisplay')
          }
        } else {
          conversationRef.value = undefined
          conversations.value.length
            ? toComponent('ConversationsList')
            : toComponent('ContactsList')
        }
      })

      watch(
        () => conversationRef?.value?.id,
        () => setSearchMessageText(''),
      )

      return {
        currentComponent,
        conversationRef,
        conversations,
        loadMoreConversations,
        toConversation,
        onDragWidget,
        toComponent,
        setConversationMute,
        refetchConversations,
        toNewChat,
        toUpdate,
        loading,
        conversationLoading,
        setJumpingMessage,
        messageToJump,
        searchMessageText,
        setSearchMessageText,
        t,
      }
    },
  })

  export default WidgetRoot
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';

  .chat-root-component {
    display: flex;
    justify-content: center;
    width: 358px;
    height: 453px;
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: $background;
    margin: 8px 0;
  }
</style>
