<template>
  <div class="main-chat-layout">
    <MainHeader
      :title="t('conversations.header')"
      :buttons="['create', 'mute', 'fullscreen']"
      :muteAll="muteAll"
      @toNewChat="$emit('toNewChat')"
      @onDragWidget="(e: MouseEvent) => $emit('onDragWidget', e)"
      @search="searchCallback"
      @toggleMuteAll="$emit('toggleMuteAll')"
    />
    <div v-if="name && name.trim() !== ''" class="chat-sidebar">
      <div
        v-if="conversationsQuery?.length || contactsQuery?.length"
        class="search-results-layout"
      >
        <div
          v-if="conversationsQuery?.length"
          class="search-results-group-header"
        >
          {{ t('conversations.chats') }}
        </div>
        <Conversation
          v-for="(conversation, index) in conversationsQuery"
          :key="`${conversation.id}-${index}`"
          :conversation="conversation"
          :input="name"
          :fullScreen="fullScreen"
          @click="
            $emit('toConversation', {
              conversationId: conversation.id,
              conversationsQuery,
            })
          "
          @loadMoreConversations="() => $emit('loadMoreConversations')"
        />
        <div v-if="contactsQuery?.length" class="search-results-group-header">
          {{ t('conversations.contacts') }}
        </div>
        <Contact
          v-for="contact in contactsQuery"
          :key="contact.node.id"
          :contact="contact.node"
          :input="name"
          @click="$emit('toConversation', { user: contact.node })"
        />
        <Blur />
      </div>
      <div
        v-if="!conversationsQuery?.length && !contactsQuery?.length"
        class="no-results-label"
      >
        {{ t('common.noResults') }}
      </div>
    </div>
    <div v-else class="chat-sidebar">
      <div class="conversations-list-layout" @scroll="handleScroll">
        <Conversation
          v-for="conversation in conversations"
          :key="conversation.id"
          :conversation="conversation"
          :fullScreen="fullScreen"
          @click="$emit('toConversation', { conversationId: conversation.id })"
          @loadMoreConversations="() => $emit('loadMoreConversations')"
        />
        <Blur />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, onActivated } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Conversation from '@/components/conversation/Conversation.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useConversationSearch from '@/compositions/conversation/useConversationSearch'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import { DialogType } from '@/types'

  const ConversationsList = defineComponent({
    components: {
      Conversation,
      Contact,
      MainHeader,
      Blur,
    },
    props: {
      conversations: Object as PropType<Array<DialogType>>,
      muteAll: Boolean,
      currentConversation: Object as PropType<DialogType>,
    },
    emits: [
      'toContactsList',
      'toConversation',
      'toNewChat',
      'loadMoreConversations',
      'onDragWidget',
      'toggleMuteAll',
    ],
    setup(_, { emit }) {
      const { t } = useI18n()

      const toContactsList = () => {
        emit('toContactsList', 'ContactsList')
      }

      const {
        name,
        searchCallback,
        contactsQuery,
        conversationsQuery,
        refetch,
      } = useConversationSearch()

      const handleScroll = useHandleScroll(() => emit('loadMoreConversations'))
      const fullScreen = false

      onActivated(() => {
        name.value && refetch()
      })

      return {
        name,
        searchCallback,
        contactsQuery,
        conversationsQuery,
        toContactsList,
        handleScroll,
        fullScreen,
        t,
      }
    },
  })
  export default ConversationsList
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .main-chat-layout {
    width: 100%;
    display: flex;
    flex-direction: column;

    :deep(.mute-button) {
      margin-left: 8px;
      margin-right: 12px;
    }

    :deep(.search-row) {
      height: 37px;
    }

    .chat-sidebar {
      @include scroll;
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .search-results-layout {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    width: 100%;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .search-results-group-header {
    @include font-caption-2;
    color: $secondary-text;
    padding: 8px 16px;
  }

  .conversations-list-layout {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .no-results-label {
    @include font-body-1;
    line-height: 19px;
    width: 100%;
    padding: 24px 16px;
    color: $secondary-text;
  }
</style>
