// Widget size
export const fullWidgetHeight = 525
export const fullWidgetWidth = 360
export const smallWidgetSide = 60
export const border = 30

// Timeouts
export const sevenSeconds = 7000
export const minutes = 60000
export const days = 86400000
export const AwayIn = 10 * minutes
export const OfflineIn = 20 * minutes
export const dragStartTime = 200

// Offsets
export const scrollOffset = 1000
export const dropdownOffset = 15

export const titles = ['', 'Dr.', 'Doctor', 'Nurse', 'Mr.', 'Ms.', 'Mrs.']

// Notifications permissions
export enum notificationsPermissions {
  DEFAULT = 'default',
  DENIED = 'denied',
  GRANTED = 'granted',
}

// Tab states
export enum tabStates {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

// Message types
export const serviceType = 'SERVICE'
export const textType = 'TEXT'

// Statuses
export const awayStatus = 'INACTIVE'
export const onlineStatus = 'ACTIVE'
export const offlineStatus = 'OFFLINE'

// Locales
export enum locales {
  EN = 'en',
  FR = 'fr',
}
