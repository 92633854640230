import { useQuery, useSubscription } from '@vue/apollo-composable'
import { inject, ref, Ref, watch } from 'vue'

import { TAKE_CONVERSATION } from '@/graphql/conversations/queries'
import {
  SUBSCRIBE_LAST_SEEN_UPDATED,
  UserActivityChangedType,
} from '@/graphql/users/subscriptions'
import { ContactType, CursorPaginationData } from '@/types'

type GroupConversationType = {
  users: CursorPaginationData<ContactType>
}

export default function useGroupInfo(
  conversationId: string | undefined,
  first: number,
): {
  conversation: Ref<GroupConversationType | undefined>
  loadMore: () => void
  refetchData: (id: string | undefined) => void
  loading: Ref<boolean>
} {
  const conversation = ref<GroupConversationType>()
  let endCursor = String('')
  let hasNextPage = Boolean(false)
  const enabled = inject<Ref<boolean>>('enabled')

  const {
    onResult: onLoad,
    fetchMore,
    refetch,
    loading,
  } = useQuery(
    TAKE_CONVERSATION,
    {
      id: conversationId,
      first,
      after: '',
      includeCurrent: true,
    },
    {
      fetchPolicy: 'no-cache',
    },
  )

  const setEndCursorAndHasNextPage = (
    data: CursorPaginationData<ContactType>,
  ) => {
    endCursor = data.pageInfo.endCursor
    hasNextPage = data.pageInfo.hasNextPage
  }

  onLoad(({ data }) => {
    setEndCursorAndHasNextPage(data.conversation.users)
    conversation.value = data.conversation
  })

  const refetchData = (id: string | undefined) => {
    loading.value = true
    refetch({
      id,
      first,
      after: '',
      includeCurrent: true,
    })
      ?.then(({ data }) => {
        conversation.value = data.conversation
      })
      .finally(() => (loading.value = false))
  }

  const loadMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: { after: endCursor },
        updateQuery: () => {
          return
        },
      })?.then(({ data }) => {
        setEndCursorAndHasNextPage(data.conversation.users)
        if (conversation.value?.users) {
          conversation.value.users = {
            ...data.conversation.users,
            edges: [
              ...conversation.value.users.edges,
              ...data.conversation.users.edges,
            ],
          }
        }
      })
    }
  }

  const {
    onResult: onUpdateLastSeen,
    start: startLastSeenSubscription,
    stop: stopLastSeenSubscription,
  } = useSubscription(SUBSCRIBE_LAST_SEEN_UPDATED)

  onUpdateLastSeen(({ data }) => {
    const { userId, activity } = (data as UserActivityChangedType)
      .userActivityChanged
    conversation.value?.users.edges.forEach((user) => {
      if (user.node.id === userId) {
        user.node.lastSeenAt = activity.lastSeenAt
        user.node.status = activity.status
      }
    })
  })

  watch(
    () => enabled?.value,
    (value) => {
      value ? startLastSeenSubscription() : stopLastSeenSubscription()
    },
  )

  return {
    conversation,
    loadMore,
    refetchData,
    loading,
  }
}
