import { computed, ComputedRef, Ref } from 'vue'

const replaceAt = (
  result: string,
  index: number,
  characters: string,
  replacement: string,
) => {
  return (
    result.substr(0, index) +
    replacement +
    result.substr(index + characters.length)
  )
}

export const markStartPart = "<mark class='highlighted'>"
export const markEndPart = '</mark>'
const lengthOfMarkTag = (markStartPart + markEndPart).length

export default function useTextHighlight(
  text: string,
  input: Ref<string | undefined> | undefined,
): ComputedRef<string> {
  return computed(() => {
    if (input && input.value && input.value.trim() !== '') {
      const inputValue = input.value
        .trim()
        .normalize()
        .replace(/[^A-Za-z0-9À-ÿ`!№@#%&_/\-={};':",<>~$^*+()[\].?|\\\s]/g, '')
        .replace(/[$^*+()[\].?|\\]/gi, '\\$&')
      const regexpInput = new RegExp(inputValue, 'gi')
      const allMatches = text.matchAll(regexpInput)
      const matches = Array.from(allMatches)
      let result = text

      for (const match of matches) {
        const matchId = matches.indexOf(match)
        const newLine = markStartPart + match[0] + markEndPart

        if (match.index !== undefined) {
          match.index = match.index + lengthOfMarkTag * matchId
          result = replaceAt(result, match.index, match[0], newLine)
        }
      }

      return result
    }
    return text
  })
}
