import { useResult, useLazyQuery } from '@vue/apollo-composable'
import { computed, ComputedRef, ref, Ref, watch } from 'vue'

import { TAKE_FILTERED_CONVERSATION_MESSAGES } from '@/graphql/messages/queries'
import { MessageType, CursorPaginationData } from '@/types'

export default function useMessageSearch(
  conversationId: ComputedRef<string | undefined>,
): {
  searchCallback: (input: string) => void
  text: ComputedRef<string>
  messagesQuery: ComputedRef<{
    messages: MessageType[]
    totalCount: number | undefined
  }>
  loading: Ref<boolean>
} {
  const params = ref<{
    body: string
    conversationId: string | undefined
  }>({
    body: '',
    conversationId: '',
  })

  const searchCallback = (text: string) => {
    params.value = {
      body: text,
      conversationId: conversationId.value,
    }
  }

  const text = computed<string>(() => params.value.body)

  const { result, load, stop, start, restart, loading } = useLazyQuery(
    TAKE_FILTERED_CONVERSATION_MESSAGES,
    { body: text, conversationId },
    {
      fetchPolicy: 'no-cache',
    },
  )
  const data = useResult<{
    messages: CursorPaginationData<MessageType>
  }>(result)

  watch(
    () => [text.value],
    ([newText], [prevText]) => {
      if (newText === prevText) {
        restart()
      } else if (text.value && text.value.trim() !== '') {
        start()
        load()
      } else {
        stop()
      }
    },
  )

  const messagesQuery = computed(() => {
    if (!(text.value && text.value.trim() !== '')) {
      return {
        messages: [],
        totalCount: 0,
      }
    }
    const messages = data.value?.edges?.map((item) => item.node) || []
    const totalCount = data.value?.totalCount
    return {
      messages,
      totalCount,
    }
  })

  return {
    searchCallback,
    text,
    messagesQuery,
    loading,
  }
}
