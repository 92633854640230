<template>
  <div ref="conversationBodyContainerRef" class="conversation">
    <SidebarContainer
      :conversations="conversations"
      :currentConversation="currentConversationRef"
      :actionPermission="actionPermission"
      @toConversation="toConversation"
      @toContactsList="toContactsList"
      @toConversationsList="toConversationsList"
      @loadMoreConversations="$emit('loadMoreConversations')"
    />
    <div class="conversation-container">
      <div class="conversation-header">
        <ConversationHeader
          :currentConversation="currentConversationRef"
          :buttons="['kebab', 'fullscreen']"
          :conversationBodyContainerRef="conversationBodyContainerRef"
          @mousedown="onDragWidget"
          @toggleGroupInfo="toGroupChatSettings"
          @setConversationMute="setConversationMute"
          @onDialogOpen="onDialogOpen"
          @onSearchMessage="$emit('onSearchMessage')"
        />
      </div>
      <div class="conversation-body-container">
        <Messages
          v-if="messages.length && !loading"
          :messages="messages"
          :pageInfo="pageInfo"
          :conversationBodyContainerRef="conversationBodyContainerRef"
          :conversationId="conversationId"
          :currentConversation="currentConversationRef"
          :actionPermission="actionPermission"
          :initialLoad="initialLoad"
          :messageToJump="messageToJump"
          :searchMessageText="searchMessageText"
          @fetchAfter="fetchAfter"
          @fetchBefore="fetchBefore"
          @setReplyMessage="setReplyMessage"
          @setMessagesListRef="setMessagesListRef"
          @clickReplyMessage="clickReplyMessage"
        />
        <div v-else-if="loading" class="messages-empty"></div>
        <div v-else class="messages-empty">
          <Conversation />
          <span>{{ t('conversation.start') }}</span>
        </div>
        <div class="conversation-message-input-container">
          <transition name="reply" mode="out-in">
            <ReplyMessage
              v-if="replyMessage"
              :message="replyMessage"
              @click="clickReplyMessage(replyMessage)"
              @clearReplyMessage="setReplyMessage"
            />
          </transition>
          <ArchivedMessageInput
            v-if="
              currentConversation?.type === 'PRIVATE' &&
              (!currentConversation?.users?.edges[0] ||
                currentConversation?.users?.edges[0]?.node.archived)
            "
          />
          <MessageInput
            v-else
            :key="conversationId"
            :inputFocus="inputFocus"
            :draftMessages="draftMessages"
            :currentDialogId="conversationId"
            @focused="(focus: boolean) => setFocus(focus)"
            @changeDraftMessages="changeDraftMessages"
            @removeFromDraftMessages="removeFromDraftMessages"
            @scrollMessagesOnInput="scrollMessagesOnInput"
            @send="(message: string) => sendMessage(message)"
          />
        </div>
      </div>
    </div>
    <transition name="appear">
      <Dialog
        v-if="currentConversation?.creator?.id === currentUser?.id && dialog"
        :title="t('popups.delete.title')"
        :text="t('popups.delete.text')"
        :confirmText="t('popups.delete.confirmText')"
        :loading="removeLoading"
        @confirm="onDialogConfirm(removeConversation)"
        @cancel="onDialogClose"
      />
      <Dialog
        v-else-if="dialog"
        :title="t('popups.leave.title')"
        :confirmText="t('popups.leave.confirmText')"
        :loading="leaveLoading"
        @confirm="onDialogConfirm(leaveConversation)"
        @cancel="onDialogClose"
      />
    </transition>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    inject,
    PropType,
    ref,
    toRefs,
    Ref,
    watch,
    onBeforeMount,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Dialog from '@/components/Dialog.vue'
  import ConversationHeader from '@/components/conversation/ConversationHeader.vue'
  import ArchivedMessageInput from '@/components/message/ArchivedMessageInput.vue'
  import MessageInput from '@/components/message/MessageInput.vue'
  import Messages from '@/components/message/Messages.vue'
  import ReplyMessage from '@/components/message/ReplyMessage.vue'
  import useConversationDelete from '@/compositions/conversation/useConversationDelete'
  import useDraftMessage from '@/compositions/message/useDraftMessage'
  import useMessages from '@/compositions/message/useMessages'
  import useReplyMessage from '@/compositions/message/useReplyMessage'
  import useScrollMessage from '@/compositions/message/useScrollMessage'
  import useSendMessage from '@/compositions/message/useSendMessage'
  import useDialog from '@/compositions/useDialog'
  import Conversation from '@/icons/conversation.svg'
  import { DialogType, UserType, MessageType, DraftMessageType } from '@/types'
  import { fixMessagesOrder } from '@/utils'

  import SidebarContainer from './SidebarContainer.vue'

  const ConversationDisplay = defineComponent({
    name: 'ConversationDisplay',
    components: {
      Conversation,
      ConversationHeader,
      Dialog,
      Messages,
      MessageInput,
      ReplyMessage,
      SidebarContainer,
      ArchivedMessageInput,
    },
    props: {
      currentConversation: Object as PropType<DialogType>,
      conversations: Object as PropType<Array<DialogType>>,
      messageToJump: Object as PropType<MessageType>,
      actionPermission: Boolean,
      chatVisible: Boolean,
      searchMessageText: {
        type: String,
        default: '',
      },
    },
    emits: [
      'toContactsList',
      'toConversation',
      'toConversationsList',
      'loadMoreConversations',
      'onDragWidget',
      'toGroupChatSettings',
      'setConversationMute',
      'refetchConversations',
      'onSearchMessage',
      'setJumpingMessage',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const { currentConversation, chatVisible, conversations, messageToJump } =
        toRefs(props)

      const conversationId = computed(() => currentConversation?.value?.id)

      const onDragWidget = (e: MouseEvent) => {
        emit('onDragWidget', e)
      }

      const currentConversationRef = ref(currentConversation)
      const inputFocus = ref<boolean>(false)

      const { messagesListRef, setMessagesListRef, scrollMessagesOnInput } =
        useScrollMessage()

      const {
        messages,
        loading,
        initialLoad,
        pageInfo,
        fetchAfter,
        fetchBefore,
        highlightMessage,
        setTimestamp,
      } = useMessages({
        conversations,
        currentConversation,
        conversationId,
        chatVisible,
        inputFocus,
        paginationLength: 10,
        messageToJump,
        clearMessageToJump: () => {
          emit('setJumpingMessage', undefined)
        },
        errorCallback: () => {
          conversations?.value?.length
            ? emit('toConversationsList')
            : emit('toContactsList')
        },
      })

      const { replyMessage, replyMessageId, setReplyMessage, setFocus } =
        useReplyMessage(conversationId, inputFocus)

      const { toConversation, sendMessage } = useSendMessage(
        messages,
        currentConversation,
        conversationId,
        messagesListRef,
        inputFocus,
        replyMessage,
        replyMessageId,
        emit,
        pageInfo,
        (message) => {
          setTimestamp(message.createdAt)
        },
      )

      const {
        draftMessages,
        changeDraftMessages,
        removeFromDraftMessages,
        setDraftMessages,
      } = useDraftMessage()

      onBeforeMount(() => {
        const draft = localStorage.getItem('draft')
        if (draft) {
          setDraftMessages(JSON.parse(draft) as Array<DraftMessageType>)
        }
      })

      const toGroupChatSettings = () => {
        emit('setJumpingMessage', undefined)
        emit('toGroupChatSettings', 'GroupChatSettings')
      }

      const setConversationMute = () => {
        emit(
          'setConversationMute',
          currentConversation?.value?.id,
          !currentConversationRef?.value?.mute,
        )
      }

      const conversationBodyContainerRef = ref()

      const { dialog, onDialogOpen, onDialogClose, onDialogConfirm } =
        useDialog()

      const {
        leaveLoading,
        removeLoading,
        leaveGroupConversation,
        removeGroupConversation,
      } = useConversationDelete()

      const leaveConversation = () => {
        if (currentUser?.value && currentConversation?.value) {
          leaveGroupConversation(
            currentConversation.value.id,
            currentUser.value.id,
            () =>
              emit('refetchConversations', () => emit('toConversationsList')),
          )
        }
      }

      const removeConversation = () => {
        if (currentConversation?.value) {
          removeGroupConversation(currentConversation.value.id, () =>
            emit('refetchConversations', () => emit('toConversationsList')),
          )
        }
      }

      const clickReplyMessage = (message: MessageType | null) => {
        if (message) {
          highlightMessage(message)
          message.bodyWithHighlight = message.body
          emit('setJumpingMessage', message)
        }
      }

      const toContactsList = () => {
        emit('setJumpingMessage', undefined)
        emit('toContactsList', 'ContactsList')
      }

      const toConversationsList = () => {
        emit('setJumpingMessage', undefined)
        emit('toConversationsList')
      }

      watch(
        () => currentConversation?.value?.id,
        () => onDialogClose(),
      )

      watch(
        () => messages?.value,
        (newMessages) => {
          if (newMessages) {
            const order = fixMessagesOrder(newMessages)
            if (order.shouldAssign) {
              messages.value = order.messages
            }
          }
        },
        {
          deep: true,
        },
      )

      return {
        currentUser,
        conversationId,
        messages,
        loading,
        pageInfo,
        fetchAfter,
        fetchBefore,
        sendMessage,
        replyMessage,
        setReplyMessage,
        conversationBodyContainerRef,
        setMessagesListRef,
        toConversation,
        setFocus,
        inputFocus,
        draftMessages,
        changeDraftMessages,
        removeFromDraftMessages,
        scrollMessagesOnInput,
        currentConversationRef,
        toGroupChatSettings,
        setConversationMute,
        onDragWidget,
        dialog,
        onDialogOpen,
        onDialogConfirm,
        onDialogClose,
        leaveLoading,
        removeLoading,
        leaveConversation,
        removeConversation,
        initialLoad,
        clickReplyMessage,
        toContactsList,
        toConversationsList,
        t,
      }
    },
  })

  export default ConversationDisplay
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .conversation {
    display: flex;
    width: 100%;
    position: relative;
  }

  .conversation-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;

    .conversation-header {
      height: 66px;
    }
  }

  .conversation-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border;

    :deep(.conversation-header-container) {
      padding: 0 14px 0 16px;
    }

    :deep(.conversation-icons-container) {
      margin-bottom: 16px;
    }

    .heading-name {
      font-weight: 600;
    }
  }

  .conversation-body-container {
    height: 349px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    .messages-empty {
      color: $secondary-text;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .conversation-message-input-container {
      @include scroll;
      border-top: 1px solid $border;
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }

  .reply-enter-active,
  .reply-leave-active {
    transition: opacity 0.2s;
  }

  .reply-enter-from,
  .reply-leave-to {
    opacity: 0;
  }

  .appear-enter-from,
  .appear-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .appear-enter-active {
    transition: all 0.1s ease-out;
  }

  .appear-leave-active {
    transition: all 0.1s ease-out;
  }
</style>
