<template>
  <div class="group-settings-container">
    <div class="group-avatar-container">
      <div class="group-avatar">
        {{ conversationName && getFirstLetters(conversationName) }}
      </div>
      <div v-click-away:[conversationName]="cancelEdit" class="head-name">
        <div v-if="edit" class="head-edit">
          <div class="edit-input-wrapper">
            <input
              ref="titleInputRef"
              type="text"
              :class="{ 'edit-input': true, error: errors }"
              :value="text"
              @input="validateEdit"
              @keyup.esc="cancelEdit(conversationName)"
              @keyup.enter="chatTitleChange"
            />

            <DoneIcon
              v-if="!loading"
              :class="{ 'edit-done-button': true, disabled: !!errors }"
              @click="chatTitleChange"
            />
            <div v-else class="loading-icon">
              <Spinner primary size="14" />
            </div>
          </div>

          <div v-if="errors" class="edit-input-errors">
            {{ errors[0] }}
          </div>
        </div>
        <div v-show="!edit" class="head-label">
          <div class="group-name-container">
            <div v-if="currentConversation?.type === 'GROUP'" class="sync-icon">
              <SynchronizeIcon />
            </div>
            <span
              class="group-name"
              :class="{ mute: currentConversation?.mute }"
              v-html="conversationName"
            ></span>
            <div v-if="currentConversation?.mute" class="icon">
              <UnmuteIcon />
            </div>
          </div>
          <button
            v-if="currentConversation?.creator?.id === currentUser?.id"
            class="edit-button"
            @click="onEdit"
          >
            <EditIcon />
            {{ t('group.rename') }}
          </button>
        </div>
      </div>
      <div class="cross" @click="$emit('toggleGroupInfo', false)">
        <CrossIcon />
      </div>
    </div>
    <div class="notifications-container">
      <div class="notifications-status">
        <span class="title">{{ t('group.mute') }}</span>
        <div class="toggle">
          <ToggleButton
            v-model="currentConversation.mute"
            @change="toggleMute"
          />
        </div>
      </div>
    </div>
    <div class="participants-container">
      <div class="participants-info">
        <div class="participants-header">
          <span class="amount">
            {{
              t('group.participants', {
                count: (currentConversation?.users?.totalCount ?? 0) + 1,
              })
            }}
          </span>
          <div
            v-if="currentConversation?.creator?.id === currentUser?.id"
            @click="$emit('toAddMembers')"
          >
            <Tooltip :content="t('tooltips.add')" placement="left">
              <UserAddIcon />
            </Tooltip>
          </div>
        </div>
        <div class="participants-list">
          <div class="participants" @scroll="handleScroll">
            <Contact
              v-for="contact in conversation?.users.edges"
              :key="contact.node.id"
              :contact="contact.node"
              :input="''"
              :isCreator="currentConversation?.creator?.id === contact.node.id"
              :canRemove="
                currentConversation?.creator?.id === currentUser?.id &&
                contact.node.id !== currentUser?.id
              "
              :canLeave="
                contact.node.id === currentUser?.id &&
                currentConversation?.type === 'CUSTOM' &&
                currentConversation?.creator?.id !== currentUser?.id
              "
              :isCurrentUser="contact.node.id === currentUser?.id"
              @leave="onDialogOpen"
              @removeUser="
                removeUserFromConversation({
                  conversationId: currentConversation?.id ?? '',
                  removeId: contact.node.id,
                })
              "
              @click="
                currentUser?.id !== contact.node.id &&
                  $emit('toConversation', { user: contact.node })
              "
            />

            <Blur />
          </div>
          <div
            v-if="removeLoading || groupInfoLoading"
            class="spinner-container"
          >
            <Spinner primary />
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-if="dialog"
      :title="t('popups.leave.title')"
      :confirmText="t('popups.leave.confirmText')"
      modal
      :loading="leaveLoading"
      @confirm="onDialogConfirm(leaveConversation)"
      @cancel="onDialogClose"
    />
  </div>
</template>

<script lang="ts">
  import { useMutation } from '@vue/apollo-composable'
  import {
    defineComponent,
    PropType,
    Ref,
    toRefs,
    inject,
    watch,
    ref,
    computed,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Dialog from '@/components/Dialog.vue'
  import Spinner from '@/components/Spinner.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import ToggleButton from '@/components/button/ToggleButton.vue'
  import useConversationCreate from '@/compositions/conversation/useConversationCreate'
  import useConversationDelete from '@/compositions/conversation/useConversationDelete'
  import useGroupInfo from '@/compositions/conversation/useGroupInfo'
  import useDialog from '@/compositions/useDialog'
  import useEditableText from '@/compositions/useEditableText'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import {
    UpdateConversationType,
    UPDATE_CONVERSATION,
  } from '@/graphql/conversations/mutations'
  import CrossIcon from '@/icons/cross.svg'
  import DoneIcon from '@/icons/done.svg'
  import EditIcon from '@/icons/edit.svg'
  import SynchronizeIcon from '@/icons/synchronize.svg'
  import UnmuteIcon from '@/icons/unmute.svg'
  import UserAddIcon from '@/icons/userAdd.svg'
  import { DialogType, UserType } from '@/types'
  import { getConversationName, getFirstLetters } from '@/utils'
  import {
    convertCharacterSequenceToSymbol,
    convertSymbolToCharacterSequence,
  } from '@/utils/messages/convertSpecialCharacters'

  const GroupChatSettings = defineComponent({
    directives: {
      clickAway: {
        beforeMount: (el, binding) => {
          el.clickAwayEvent = (event: MouseEvent) => {
            if (!(el == event.target || el.contains(event.target))) {
              binding.value(binding.arg)
            }
          }
          document.addEventListener('click', el.clickAwayEvent)
        },
        beforeUpdate: (el) => {
          document.removeEventListener('click', el.clickAwayEvent)
        },
        updated: (el, binding) => {
          el.clickAwayEvent = (event: MouseEvent) => {
            if (!(el == event.target || el.contains(event.target))) {
              binding.value(binding.arg)
            }
          }
          document.addEventListener('click', el.clickAwayEvent)
        },
        unmounted: (el) => {
          document.removeEventListener('click', el.clickAwayEvent)
        },
      },
    },
    components: {
      Contact,
      DoneIcon,
      EditIcon,
      ToggleButton,
      CrossIcon,
      UnmuteIcon,
      Blur,
      UserAddIcon,
      Spinner,
      Tooltip,
      Dialog,
      SynchronizeIcon,
    },
    props: {
      currentConversation: {
        type: Object as PropType<DialogType>,
        required: true,
      },
      conversations: Object as PropType<Array<DialogType>>,
    },
    emits: [
      'toConversation',
      'toggleGroupInfo',
      'setConversationMute',
      'toAddMembers',
      'refetchConversations',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const { currentConversation, conversations } = toRefs(props)

      const {
        conversation,
        refetchData,
        loadMore,
        loading: groupInfoLoading,
      } = useGroupInfo(currentConversation?.value?.id, 11)

      const handleScroll = useHandleScroll(loadMore)

      const {
        updateConversation: removeUserFromConversation,
        updateLoading: removeLoading,
      } = useConversationCreate({
        currentConversation,
        conversations,
      })

      watch(
        () => currentConversation?.value?.id,
        (newId) => refetchData(newId),
      )

      watch(
        () => currentConversation?.value?.users?.totalCount,
        () => {
          refetchData(currentConversation?.value?.id)
        },
      )

      const toggleMute = (e: { target: { checked: boolean } }) => {
        emit(
          'setConversationMute',
          currentConversation?.value?.id,
          e.target.checked,
        )
      }

      const titleInputRef = ref()
      const currentTitle = computed(() => currentConversation?.value?.name)
      const { mutate: updateGroupConversation, loading } =
        useMutation<UpdateConversationType>(UPDATE_CONVERSATION)

      const { text, edit, errors, onEdit, doneEdit, cancelEdit, validateEdit } =
        useEditableText(titleInputRef, currentTitle)

      const chatTitleChange = () => {
        doneEdit(async () => {
          const name = titleInputRef.value.value
          await updateGroupConversation({
            input: {
              conversationId: currentConversation?.value?.id,
              name: convertCharacterSequenceToSymbol(name),
            },
          })
          if (currentConversation?.value) {
            currentConversation.value.name =
              convertSymbolToCharacterSequence(name)
          }
        })
      }

      const { dialog, onDialogOpen, onDialogClose, onDialogConfirm } =
        useDialog()

      const { leaveLoading, leaveGroupConversation } = useConversationDelete()

      const leaveConversation = () => {
        if (currentUser?.value && currentConversation?.value) {
          leaveGroupConversation(
            currentConversation.value.id,
            currentUser.value.id,
            () =>
              emit('refetchConversations', () =>
                emit('toConversation', { conversationId: undefined }),
              ),
          )
        }
      }

      const defaultConversationName = computed(() => {
        if (currentConversation?.value?.users && currentUser?.value.id) {
          const { name, count } = getConversationName(
            currentConversation?.value?.users,
            currentUser?.value.id,
          )

          return count
            ? t('conversation.andMore', { name, count })
            : t('conversation.andYou', { name })
        }

        return ''
      })

      const conversationName = computed(() => {
        return currentConversation?.value?.name || defaultConversationName.value
      })

      return {
        currentUser,
        conversation,
        conversationName,
        handleScroll,
        toggleMute,
        removeUserFromConversation,
        titleInputRef,
        text,
        edit,
        errors,
        onEdit,
        cancelEdit,
        validateEdit,
        loading,
        chatTitleChange,
        dialog,
        onDialogOpen,
        onDialogClose,
        onDialogConfirm,
        leaveLoading,
        leaveConversation,
        groupInfoLoading,
        removeLoading,
        t,
      }
    },
    methods: {
      getFirstLetters,
    },
  })

  export default GroupChatSettings
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_scroll.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_variables.scss';

  .group-settings-container {
    position: relative;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;
    height: 100vh;

    .group-avatar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .group-avatar {
        @include font-caption-1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        background-color: $conversation-avatar;
        border-radius: 50%;
        line-height: 22px;
        color: rgba($primary-text, 0.3);
      }
      .group-name-container {
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 19px;
        margin-top: 8px;

        .group-name {
          @include font-caption-2;
          line-height: 19px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;

          &.mute {
            color: $secondary-text;
          }
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          fill: $secondary-text;
          width: 14px;
          height: 14px;
        }
      }

      .cross {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        fill: $secondary-text;

        svg {
          width: 11px;
          height: 11px;
        }

        &:hover {
          fill: $secondary-text-hover;
        }
      }
    }

    .notifications-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      border-bottom: 1px solid $border;
      border-top: 1px solid $border;

      .notifications-status {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 26px 24px;

        .title {
          @include font-caption-2;
        }
      }

      .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .participants-container {
      width: 100%;
      overflow: hidden;

      :deep(.contact-info-section) {
        max-width: 160px;
      }

      .participants-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        .participants-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px 8px 24px;
          box-sizing: border-box;
          width: 100%;

          .amount {
            @include font-caption-2;
            color: $secondary-text;
          }

          svg {
            cursor: pointer;
            width: 22px;
            height: 16px;
            fill: $dark-icon;

            &:hover {
              fill: $secondary-text-hover;
            }
          }
        }

        .participants :deep(.contact-container) {
          .contact-item {
            padding: 14px 24px;
          }
        }

        .participants-list {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: hidden;

          .spinner-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: absolute;
            background-color: rgba($background, 0.8);
            width: 100%;
            height: 100%;
          }
        }

        .participants {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
        }

        @include scroll;
      }
    }
  }

  @media (max-width: $small-screen) {
    .group-settings-container {
      width: 250px;

      .participants :deep(.contact-container) {
        .contact-item {
          flex-wrap: wrap;
          justify-content: flex-end;

          .contact-info-section {
            flex: 1;

            .contact-name {
              max-width: 90px;
            }
          }
        }
      }
    }
  }

  .edit-input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
  }

  .head-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sync-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      fill: $secondary-text;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .head-name {
    padding: 0 12px;
  }

  .edit-input {
    @include font-caption-2;
    width: 100%;
    border: 1px solid $secondary-text-hover;
    border-radius: 3px;
    padding: 0 8px;
    outline: none;

    &.error {
      border: 1px solid $error;
    }
  }

  .edit-button {
    @include font-caption-3;
    color: $secondary-text;
    background: none;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 4px;

    &:hover {
      color: $secondary-text-hover;

      svg {
        fill: $secondary-text-hover;
      }
    }

    svg {
      fill: $secondary-text;
      width: 12px;
      height: 12px;
      padding: 6px;
      box-sizing: content-box;
    }
  }

  .edit-done-button {
    cursor: pointer;
    width: 18px;
    height: 18px;

    &:hover {
      fill: $secondary-text-hover;
    }
    &.disabled {
      fill: $secondary-text;
    }
  }

  .edit-input-errors {
    @include font-caption-3;
    color: $error;
  }

  .loading-icon {
    cursor: progress;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
</style>
