<template>
  <div class="spinner">
    <div
      class="spinner-icon"
      :class="{ primary, secondary }"
      :style="{ width: `${size}px`, height: `${size}px` }"
    ></div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  const Spinner = defineComponent({
    props: {
      size: {
        type: Number,
        default: 24,
      },
      primary: Boolean,
      secondary: Boolean,
    },
  })

  export default Spinner
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';

  .spinner {
    position: absolute;
    animation: spinner-animation 600ms linear infinite;

    .spinner-icon {
      border: 2px solid transparent;
      border-radius: 50%;

      &.primary {
        border-top-color: $secondary-text-hover;
        border-left-color: $secondary-text-hover;
      }

      &.secondary {
        border-top-color: $background;
        border-left-color: $background;
      }
    }
  }

  @keyframes spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
