import { Ref, ref, ComputedRef, computed } from 'vue'

import { dropdownOffset } from '@/const'
import { MessageType, WidgetPosition, UIEvent } from '@/types'

type Style = {
  left: string
  top: string
}

export default function useContextMenu(
  conversationBodyContainerRef: Ref<HTMLDivElement | undefined> | undefined,
  dropdownRef: ComputedRef<HTMLDivElement | undefined> | undefined,
): [
  Ref<boolean>,
  Ref<WidgetPosition>,
  Ref<MessageType | undefined>,
  (event: UIEvent, message?: MessageType) => void,
  () => void,
  ComputedRef<Style>,
] {
  const popupVisible = ref(false)
  const selectedMessage = ref<MessageType | undefined>()
  const widgetPosition = ref<WidgetPosition>({
    x: 0,
    y: 0,
    widgetX: 0,
    widgetY: 0,
  })

  const onOpenDropdown = (event: UIEvent, message?: MessageType) => {
    if (conversationBodyContainerRef && dropdownRef) {
      const bodyPosition =
        conversationBodyContainerRef?.value?.getBoundingClientRect()
      const dropdownPosition = dropdownRef?.value?.getBoundingClientRect()

      if (bodyPosition && dropdownPosition) {
        widgetPosition.value.widgetX =
          event.clientX + dropdownPosition.width >
          bodyPosition.x + bodyPosition.width
            ? bodyPosition.x +
              (dropdownPosition.width -
                bodyPosition.x +
                event.clientX -
                bodyPosition.width +
                dropdownOffset)
            : bodyPosition.x + dropdownOffset

        widgetPosition.value.widgetY = bodyPosition.y
      }
    }

    widgetPosition.value.x = event.clientX
    widgetPosition.value.y = event.clientY
    selectedMessage.value = message
    popupVisible.value = true
  }

  const onHideDropdown = () => {
    popupVisible.value = false
    widgetPosition.value.widgetX = 0
    widgetPosition.value.widgetY = 0
    widgetPosition.value.x = 0
    widgetPosition.value.y = 0
  }

  const style = computed(() => ({
    left: `${widgetPosition.value.x - widgetPosition.value.widgetX + 5}px`,
    top: `${widgetPosition.value.y - widgetPosition.value.widgetY + 5}px`,
  }))

  return [
    popupVisible,
    widgetPosition,
    selectedMessage,
    onOpenDropdown,
    onHideDropdown,
    style,
  ]
}
