<template>
  <div class="dialog" :class="{ modal }">
    <div class="dialog-body">
      <div class="dialog-title">{{ title }}</div>
      <div v-if="text" class="dialog-text">{{ text }}</div>
      <div class="dialog-actions">
        <Button secondary @click="$emit('cancel')">{{ cancelLabel }}</Button>
        <Button danger @click="$emit('confirm')">
          <div v-if="loading" class="spinner-container">
            <Spinner secondary :size="20" />
          </div>
          <span v-else>
            {{ confirmLabel }}
          </span>
        </Button>
      </div>
    </div>
  </div>
  <div v-if="modal" class="overlay" @click="$emit('cancel')" />
</template>

<script lang="ts">
  import { defineComponent, computed, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Spinner from '@/components/Spinner.vue'
  import Button from '@/components/button/Button.vue'

  const Dialog = defineComponent({
    components: {
      Button,
      Spinner,
    },
    props: {
      title: String,
      text: String,
      confirmText: {
        type: String,
      },
      cancelText: {
        type: String,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['cancel', 'confirm'],
    setup(props) {
      const { t } = useI18n()
      const { cancelText, confirmText } = toRefs(props)

      const cancelLabel = computed(
        () => cancelText.value ?? t('popups.defaultCancelText'),
      )

      const confirmLabel = computed(
        () => confirmText.value ?? t('popups.defaultConfirmText'),
      )

      return { cancelLabel, confirmLabel, t }
    },
  })

  export default Dialog
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .dialog {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      rgba($black, 0) 0%,
      rgba($black, 0.4) 100%
    );
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    .dialog-body {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 16px 16px 0 0;
      background-color: $background;
      box-sizing: border-box;
    }

    .dialog-title {
      @include font-caption-1;
      margin-bottom: 12px;
    }

    .dialog-text {
      @include font-body-1;
      margin-bottom: 16px;
    }

    .dialog-actions {
      display: flex;
      gap: 16px;

      .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.modal {
      position: fixed;
      width: 400px;
      height: auto;
      background: $background;
      z-index: 20;
      border-radius: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: unset;

      .dialog-body {
        display: flex;
        flex-direction: column;
        padding: 24px;
        background-color: $background;
      }

      .dialog-actions {
        width: 275px;
        align-self: flex-end;
        margin-top: 12px;
      }
    }
  }

  .overlay {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba($black, 0.8);
    z-index: 15;
    text-align: center;
    cursor: pointer;
  }
</style>
