import { gql } from '@apollo/client/core'

import { PAGE_INFO_FRAGMENT } from '@/graphql/common'

export const CONVERSATION_FRAGMENT = gql`
  fragment conversationFields on Conversation {
    id
    name
    type
    avatarUrl
    unreadMessagesCount
    mute
    lastReadAt
    creator {
      id
    }
    users(
      pagination: { first: 4 }
      filters: { onlyActive: true, usersSortFilter: JOINED_AT }
    ) {
      edges {
        node {
          id
          lastSeenAt
          avatar
          name
          archived
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`

export const TAKE_ALL_CONVERSATIONS = gql`
  query ($first: Int!, $after: String) {
    conversations(pagination: { first: $first, after: $after }) {
      totalCount
      pageInfo {
        ...pageInfoFields
      }
      edges {
        cursor
        node {
          ...conversationFields
          messages(pagination: { first: 1 }) {
            nodes {
              id
              body
              createdAt
              type
              user {
                id
                name
                avatar
                archived
              }
              bodyParameters
            }
          }
        }
      }
      totalUnreadMessages
    }
  }
  ${CONVERSATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`

export const QUERY_CONVERSATIONS = gql`
  query ($name: SearchString) {
    conversations(filters: { name: $name }) {
      totalCount
      pageInfo {
        ...pageInfoFields
      }
      edges {
        cursor
        node {
          ...conversationFields
          messages(pagination: { first: 1 }) {
            nodes {
              id
              body
              createdAt
              type
              user {
                id
                name
                avatar
                archived
              }
              bodyParameters
            }
          }
        }
      }
    }
    users(filters: { name: $name, onlyActive: true }) {
      pageInfo {
        ...pageInfoFields
      }
      edges {
        cursor
        node {
          id
          name
          avatar
          lastSeenAt
          archived
          privateConversation {
            ...conversationFields
            messages(pagination: { first: 1 }) {
              nodes {
                id
                body
                createdAt
                user {
                  id
                  name
                  avatar
                  archived
                }
              }
            }
          }
        }
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`

export const TAKE_CONVERSATION = gql`
  query ($id: ID!, $first: Int, $after: String, $includeCurrent: Boolean) {
    conversation(id: $id) {
      id
      name
      type
      avatarUrl
      unreadMessagesCount
      mute
      lastReadAt
      creator {
        id
      }
      users(
        pagination: { first: $first, after: $after }
        filters: { includeCurrent: $includeCurrent, onlyActive: true }
      ) {
        edges {
          cursor
          node {
            id
            lastSeenAt
            avatar
            name
            archived
            privateConversation {
              ...conversationFields
              messages(pagination: { first: 1 }) {
                nodes {
                  id
                  body
                  createdAt
                  user {
                    id
                    name
                    avatar
                    archived
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      messages(pagination: { first: 1 }) {
        nodes {
          id
          body
          createdAt
          type
          user {
            id
            name
            avatar
          }
          bodyParameters
        }
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`

export const TAKE_CONVERSATION_USERS_IDS = gql`
  query ($id: ID!) {
    conversation(id: $id) {
      users(filters: { includeCurrent: true }) {
        edges {
          cursor
          node {
            id
          }
        }
      }
    }
  }
`
