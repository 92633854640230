<template>
  <ul class="header-dropdown">
    <li
      v-if="showMute"
      class="action"
      @mousedown="onItemClick('setConversationMute')"
    >
      <div class="action-icon">
        <Mute v-if="mute" class="mute" />
        <Unmute v-else class="mute" />
      </div>
      <span class="action-text">
        {{
          mute
            ? t('dropdowns.conversation.unmute')
            : t('dropdowns.conversation.mute')
        }}
      </span>
    </li>
    <li
      v-if="group && !hideGroupInfo"
      class="action"
      @mousedown="onItemClick('toggleGroupInfo')"
    >
      <div class="action-icon">
        <Information class="information" />
      </div>
      <span class="action-text">{{ t('dropdowns.conversation.group') }}</span>
    </li>
    <li
      v-if="group && creator"
      class="action"
      @mousedown="onItemClick('onDialogOpen')"
    >
      <div class="action-icon">
        <DeleteIcon class="leave" />
      </div>
      <span class="action-text">{{ t('dropdowns.conversation.delete') }}</span>
    </li>
    <li
      v-else-if="group && !hideLeaveButton"
      class="action"
      @mousedown="onItemClick('onDialogOpen')"
    >
      <div class="action-icon">
        <ChatExitIcon class="leave" />
      </div>
      <span class="action-text">{{ t('dropdowns.conversation.leave') }}</span>
    </li>
    <li
      v-if="!hideSearch"
      class="action"
      @mousedown.stop.prevent="onItemClick('onSearchMessage')"
    >
      <div class="action-icon">
        <SearchIcon class="information" />
      </div>
      <span class="action-text">{{ t('dropdowns.conversation.search') }}</span>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import ChatExitIcon from '@/icons/chatBubbleExit.svg'
  import DeleteIcon from '@/icons/delete.svg'
  import Information from '@/icons/information.svg'
  import Mute from '@/icons/mute.svg'
  import SearchIcon from '@/icons/search.svg'
  import Unmute from '@/icons/unmute.svg'

  type Event =
    | 'toggleGroupInfo'
    | 'setConversationMute'
    | 'onDialogOpen'
    | 'onSearchMessage'

  const ConversationDropdown = defineComponent({
    components: {
      ChatExitIcon,
      DeleteIcon,
      Information,
      Mute,
      SearchIcon,
      Unmute,
    },
    props: {
      creator: Boolean,
      group: Boolean,
      mute: Boolean,
      hideGroupInfoButton: Boolean,
      hideSearchButton: Boolean,
      hideLeaveButton: Boolean,
      showMute: Boolean,
      conversationId: String,
    },
    emits: [
      'toggleGroupInfo',
      'hide',
      'setConversationMute',
      'onDialogOpen',
      'onSearchMessage',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const onItemClick = (event: Event) => {
        emit(event)
        emit('hide')
      }

      const { hideGroupInfoButton, hideSearchButton } = toRefs(props)

      const hideGroupInfo = computed(() => hideGroupInfoButton?.value)
      const hideSearch = computed(() => hideSearchButton?.value)

      return {
        onItemClick,
        hideGroupInfo,
        hideSearch,
        t,
      }
    },
  })

  export default ConversationDropdown
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .header-dropdown {
    position: absolute;
    padding: 3px;
    border-radius: 10px;
    background: $background;
    box-shadow: 0px 3px 10px $dropdown-shadow;
    z-index: 1;
  }

  .action {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
    justify-content: flex-start;

    .action-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      fill: $secondary-text;

      .mute {
        width: 17px;
        height: 17px;
      }

      .information {
        width: 17px;
        height: 17px;
      }

      .leave {
        width: 20px;
        height: 20px;
      }
    }

    .action-text {
      @include font-body-2;
      white-space: nowrap;
      color: $primary-text;
      align-items: center;
      margin-left: 10px;
    }

    &:hover {
      .action-text {
        color: $secondary-text-hover;
      }

      svg {
        fill: $secondary-text-hover;
      }
    }

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }
  }
</style>
