import { Cursor } from '@/types'

export function toCollection<T>(collection: Array<Cursor<T>>): Array<T> {
  return collection.map((el: Cursor<T>) => {
    return {
      cursor: el.cursor,
      ...el.node,
    }
  })
}
