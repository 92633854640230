<template>
  <div class="conversation-list">
    <MainHeader
      :title="t('conversations.header')"
      :buttons="['create', 'contacts', 'mute']"
      :muteAll="muteAll"
      @toContactsList="$emit('toContactsList')"
      @toNewChat="$emit('toNewChat')"
      @search="searchCallback"
      @toggleMuteAll="$emit('toggleMuteAll')"
    />
    <div v-if="name && name.trim() !== ''" class="chat-sidebar">
      <div
        v-if="conversationsQuery?.length || contactsQuery?.length"
        class="search-results-layout"
      >
        <div
          v-if="conversationsQuery?.length"
          class="search-results-group-header"
        >
          {{ t('conversations.chats') }}
        </div>
        <Conversation
          v-for="(conversation, index) in conversationsQuery"
          :key="`${conversation.id}-${index}`"
          :conversation="conversation"
          :currentConversationId="currentConversation?.id"
          :input="name"
          :fullScreen="fullScreen"
          @click="
            $emit('toConversation', {
              conversationId: conversation.id,
              conversationsQuery,
            })
          "
          @loadMoreConversations="() => $emit('loadMoreConversations')"
        />
        <div v-if="contactsQuery?.length" class="search-results-group-header">
          {{ t('conversations.contacts') }}
        </div>
        <Contact
          v-for="contact in contactsQuery"
          :key="contact.node.id"
          :contact="contact.node"
          :input="name"
          @click="$emit('toConversation', { user: contact.node })"
        />
        <Blur />
      </div>
      <div
        v-if="!conversationsQuery?.length && !contactsQuery?.length"
        class="no-results-label"
      >
        {{ t('common.noResults') }}
      </div>
    </div>
    <div v-else class="chat-sidebar">
      <div class="conversations-list-layout" @scroll="handleScroll">
        <Conversation
          v-for="conversation in conversations"
          :key="conversation.id"
          :conversation="conversation"
          :currentConversationId="currentConversation?.id"
          :fullScreen="fullScreen"
          @click="$emit('toConversation', { conversationId: conversation.id })"
          @loadMoreConversations="() => $emit('loadMoreConversations')"
        />
        <Blur />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, toRefs, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Conversation from '@/components/conversation/Conversation.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useConversationSearch from '@/compositions/conversation/useConversationSearch'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import { DialogType } from '@/types'
  import { updateConversationMute } from '@/utils'

  const ConversationsList = defineComponent({
    components: {
      MainHeader,
      Contact,
      Conversation,
      Blur,
    },
    props: {
      conversations: Object as PropType<Array<DialogType>>,
      currentConversation: Object as PropType<DialogType>,
      muteAll: Boolean,
    },
    emits: [
      'toContactsList',
      'toConversation',
      'toNewChat',
      'loadMoreConversations',
      'toggleMuteAll',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { currentConversation } = toRefs(props)

      const {
        name,
        searchCallback,
        contactsQuery,
        conversationsQuery,
        refetch,
      } = useConversationSearch()

      const handleScroll = useHandleScroll(() => emit('loadMoreConversations'))
      const fullScreen = true

      watch(
        () => currentConversation,
        (updateConversation) => {
          if (currentConversation?.value) {
            updateConversationMute(
              conversationsQuery,
              updateConversation?.value,
              currentConversation,
            )
          }
          if (name && !updateConversation) {
            refetch()
          }
        },
        { deep: true },
      )

      return {
        name,
        searchCallback,
        contactsQuery,
        conversationsQuery,
        handleScroll,
        fullScreen,
        t,
      }
    },
  })

  export default ConversationsList
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';
  @import '@/styles/_variables.scss';

  .conversation-list {
    height: 100%;
    width: 400px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border;

    :deep(.header-container) {
      min-height: 93px;
    }

    :deep(.heading-row) {
      padding: 10px 24px;
    }

    :deep(.search-row) {
      padding: 0 8px;
    }

    .chat-sidebar {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
  }

  @media (max-width: $small-screen) {
    .conversation-list {
      width: 250px;
      min-width: 250px;

      :deep(.conversation-info) {
        .conversation-name.truncate.full-screen {
          max-width: 120px;
        }

        .last-message-body.truncate.full-screen {
          max-width: 65px;
        }
      }

      :deep(.contacts-button) {
        margin-right: 12px;
      }
      :deep(.mute-button) {
        margin-left: 12px;
      }
    }
  }

  .search-results-layout {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    overflow-y: auto;
    width: 100%;
  }

  .search-results-group-header {
    @include font-caption-2;
    color: $secondary-text;
    padding: 8px 16px;
  }

  .conversations-list-layout {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
  }

  .no-results-label {
    @include font-body-1;
    line-height: 19px;
    width: 100%;
    padding: 24px 16px;
    color: $secondary-text;
  }

  @include scroll;
</style>
