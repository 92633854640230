<template>
  <div class="message-input-container">
    <textarea
      ref="messageInput"
      v-model="state.message"
      :placeholder="t('messages.input.placeholder')"
      class="message-input"
      :class="{
        bordered,
      }"
      :style="style"
      rows="1"
      type="textarea"
      @keyup.enter.exact="submit"
      @keydown.enter.exact.prevent=""
      @blur="$emit('focused', false)"
      @focus="$emit('focused', true)"
    />
    <div class="send-button-container">
      <button
        :class="{
          'send-button': true,
          disabled: !state.message.length,
        }"
        @click="submit"
      >
        <PlaneIcon alt="Send message" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import PlaneIcon from '@/icons/plane.svg'
  import { DraftMessageType } from '@/types'

  const MessageInput = defineComponent({
    components: {
      PlaneIcon,
    },
    props: {
      inputFocus: Boolean,
      draftMessages: Object as PropType<Array<DraftMessageType>>,
      currentDialogId: String,
      bordered: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'input',
      'changeDraftMessages',
      'removeFromDraftMessages',
      'scrollMessagesOnInput',
      'send',
      'focused',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { inputFocus, draftMessages, currentDialogId } = toRefs(props)

      const state = reactive({
        message: '',
        initialHeight: '20px',
        previousHeight: 20,
        height: 20,
        maxHeight: 180,
        minHeight: 20,
      })
      const messageInput = ref<HTMLInputElement>()

      const submit = () => {
        if (!state.message.trim()) {
          return
        }
        emit('send', state.message.trim())
        state.message = ''
        focusInputField()
        let unsendMessage = draftMessages?.value?.find(
          (el: DraftMessageType) => el.dialog == currentDialogId?.value,
        )
        if (unsendMessage) {
          emit('removeFromDraftMessages', unsendMessage.dialog)
        }

        nextTick(() => {
          if (messageInput.value) {
            messageInput.value.style.height = state.initialHeight
            state.height = 20
          }
        })
      }

      const resize = () => {
        if (messageInput.value) {
          state.previousHeight = state.height
          messageInput.value.style.height = ''
          messageInput.value.style.height =
            messageInput.value.scrollHeight - 24 + 'px'
          state.height = messageInput.value.scrollHeight - 24
          emit('scrollMessagesOnInput', state.height, state.previousHeight)
        }
      }

      const style = computed(() => ({
        maxHeight: state.maxHeight + 'px',
        minHeight: state.minHeight + 'px',
        transition: 'height 0.3s ease-in',
      }))

      const focusInputField = () => {
        messageInput?.value?.focus()
      }

      watch(
        () => state.message,
        (newMessage) => {
          emit('input', newMessage)
          resize()
          if (state.message) {
            emit('changeDraftMessages', state.message, currentDialogId?.value)
          } else {
            emit('removeFromDraftMessages', currentDialogId?.value)
          }
        },
      )

      watch(
        () => inputFocus.value,
        (newInputFocus) => {
          if (newInputFocus) {
            focusInputField()
            emit('focused', false)
          }
        },
      )

      onMounted(() => {
        const unsendMessage = draftMessages?.value?.find(
          (el: DraftMessageType) => el.dialog == currentDialogId?.value,
        )
        if (unsendMessage && !state.message) {
          state.message = unsendMessage.message
          nextTick(() => {
            resize()
          })
        }
        focusInputField()
      })

      return { state, submit, messageInput, style, t }
    },
  })
  export default MessageInput
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .message-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-input {
    @include font-body-1;
    outline: none;
    width: 100%;
    resize: none;
    border: none;
    padding: 12px 16px;
    margin: 4px 0;
    line-height: 19px;
    box-sizing: content-box;
    cursor: auto;

    &::placeholder {
      color: $secondary-text;
    }
  }

  .bordered {
    padding: 12px 16px;
    border: 1px solid $input-border;
    border-radius: 4px;
    max-width: 526px;
    margin: 16px 0 32px 0;

    &:focus {
      border-color: $secondary-text-hover;
    }

    & + .send-button-container {
      padding: 12px 0 42px 0;
    }
  }

  .send-button {
    background: none;
    border: none;
    fill: $primary-text;

    svg {
      width: 20px;
      height: 18px;
    }

    &:hover {
      fill: $secondary-text-hover;
    }

    &.disabled {
      fill: $border;
    }
  }

  .send-button-container {
    width: 50px;
    display: flex;
    align-self: flex-end;
    padding: 12px 0;
  }
</style>
