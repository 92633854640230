import { nextTick, ref, Ref, watch } from 'vue'

type EditableTextProps = {
  text: Ref<string>
  edit: Ref<boolean>
  errors: Ref<Array<string>>
  onEdit: () => void
  doneEdit: (callback: () => void) => void
  cancelEdit: (defaultTitle?: string) => void
  validateEdit: (e: Event) => void
}

export default function useEditableText(
  inputRef: Ref<HTMLInputElement | undefined>,
  defaultValue?: Ref<string | undefined>,
): EditableTextProps {
  const text = ref(defaultValue?.value ?? '')
  const edit = ref(false)
  const errors = ref()

  watch(
    () => defaultValue?.value,
    () => {
      text.value = defaultValue?.value ?? ''
    },
  )

  const onEdit = () => {
    edit.value = true
    nextTick(() => {
      if (inputRef?.value) {
        inputRef.value.focus()
        inputRef.value.select()
      }
    })
  }

  const doneEdit = (callback: () => void) => {
    if (!errors.value) {
      callback()
      edit.value = false
    }
  }

  const cancelEdit = (defaultTitle?: string) => {
    edit.value = false
    errors.value = undefined
    text.value = defaultTitle ? defaultTitle : defaultValue?.value ?? ''
  }

  const validateEdit = (e: Event) => {
    const inputValue = (e.target as unknown as { value: string }).value
    if (inputValue.trim() === '') {
      errors.value = ['Enter chat name']
    } else {
      errors.value = undefined
    }
    text.value = inputValue
  }

  return {
    text,
    edit,
    errors,
    onEdit,
    doneEdit,
    cancelEdit,
    validateEdit,
  }
}
