<template>
  <div class="sidebar-container">
    <div
      class="back-arrow-button-container"
      @mousedown="(e: MouseEvent) => $emit('onDragWidget', e)"
    >
      <BackArrowButton
        @click="
          conversations?.length
            ? $emit('toConversationsList')
            : $emit('toContactsList')
        "
      />
    </div>
    <div
      :class="
        actionPermission ? 'conversations-list' : 'conversations-list-strict'
      "
      @scroll="handleScroll"
    >
      <div
        v-for="conversation in conversations"
        :key="conversation.id"
        class="conversation-preview"
        :conversationName="conversation.name"
        @mouseover.stop="mouseOverAvatar"
        @mouseleave="mouseLeaveAvatar"
        @click="$emit('toConversation', conversation.id)"
      >
        <Tooltip placement="left" offsetDistance="5">
          <Avatar
            sidebar
            v-bind:class="{ active: avatarHovered }"
            :name="
              (conversation.type === 'GROUP' ||
                conversation.type === 'CUSTOM') &&
              (conversation.name ||
                defaultConversationName(
                  conversation.users,
                  currentUser?.id ?? '0',
                ))
            "
            :src="
              conversation.type === 'GROUP' || conversation.type === 'CUSTOM'
                ? conversation.messages?.nodes[0]?.user.avatar
                : conversation.avatarUrl
            "
            :group="
              conversation.type === 'GROUP' || conversation.type === 'CUSTOM'
            "
            :unreadCount="conversation.unreadMessagesCount"
            :lastSeen="
              conversation.type === 'PRIVATE' && conversation.users?.edges[0]
                ? conversation.users?.edges[0].node.lastSeenAt
                : ''
            "
            :status="
              conversation.type === 'PRIVATE' && conversation.users?.edges[0]
                ? conversation.users?.edges[0].node.status
                : ''
            "
            :border="currentConversation?.id === conversation.id"
            :mute="conversation.mute"
            :archived="
              (conversation.type === 'PRIVATE' &&
                !conversation.users?.edges[0]) ||
              conversation.users?.edges[0]?.node?.archived ||
              conversation.messages?.nodes[0]?.user.archived
            "
          />
          <template v-slot:content>
            <span
              v-html="
                conversation.name ||
                defaultConversationName(
                  conversation.users,
                  currentUser?.id ?? '0',
                )
              "
            />
          </template>
        </Tooltip>
      </div>
    </div>
    <Tooltip :content="t('tooltips.contacts')" placement="left">
      <button class="go-to-contacts-button" @click="$emit('toContactsList')">
        <PeopleIcon alt="Go back" />
      </button>
    </Tooltip>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, PropType, Ref, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import BackArrowButton from '@/components/button/BackArrowButton.vue'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import PeopleIcon from '@/icons/people.svg'
  import { DialogType, UserType, CursorPaginationData } from '@/types'
  import { getConversationName } from '@/utils/conversation/getConversationName'

  const SidebarContainer = defineComponent({
    components: {
      Avatar,
      BackArrowButton,
      PeopleIcon,
      Tooltip,
    },
    props: {
      conversations: Object as PropType<Array<DialogType>>,
      currentConversation: Object as PropType<DialogType>,
      actionPermission: Boolean,
    },
    emits: [
      'toContactsList',
      'toConversation',
      'toConversationsList',
      'loadMoreConversations',
      'onDragWidget',
    ],
    setup(_, { emit }) {
      const { t } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const avatarHovered = ref<boolean>(false)
      const avatarPosition = ref<EventTarget | null>()

      const mouseOverAvatar = (event: Event) => {
        if (avatarHovered.value !== true) {
          avatarHovered.value = true
          avatarPosition.value = event?.currentTarget
        }
      }

      const mouseLeaveAvatar = () => {
        if (avatarHovered.value !== false) {
          avatarHovered.value = false
          avatarPosition.value = null
        }
      }

      const handleScroll = useHandleScroll(() => emit('loadMoreConversations'))

      const defaultConversationName = (
        users: CursorPaginationData<UserType> | undefined,
        id: string,
      ) => {
        const { name, count } = getConversationName(users, id)

        return count
          ? t('conversation.andMore', { name, count })
          : t('conversation.andYou', { name })
      }

      return {
        currentUser,
        avatarHovered,
        avatarPosition,
        mouseOverAvatar,
        mouseLeaveAvatar,
        handleScroll,
        defaultConversationName,
        t,
      }
    },
  })

  export default SidebarContainer
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';

  .sidebar-container {
    display: flex;
    flex-direction: column;
    width: 58px;
    border-right: 1px solid $border;

    .conversations-list-strict,
    .conversations-list {
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      overflow-x: hidden;
    }

    .conversations-list::-webkit-scrollbar {
      display: none;
    }

    .conversations-list {
      overflow-y: auto;
    }

    .conversations-list-strict {
      overflow-y: hidden;
    }

    .conversation-preview {
      cursor: pointer;
      margin: 11px 6px 0;
    }

    .back-arrow-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 66px;
      border-bottom: 1px solid $border;

      svg {
        padding: 10px;
        height: 14px;
        width: 20px;
        box-sizing: content-box;
      }
    }

    .go-to-contacts-button {
      color: $secondary-text;
      fill: $secondary-text;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: none;
      border: none;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      width: 58px;
      height: 30px;

      svg {
        width: 18px;
        height: 12px;
      }

      svg {
        width: 18px;
        height: 12px;
      }

      &:hover {
        color: $secondary-text-hover;
        fill: $secondary-text-hover;
      }
    }
  }

  :deep(.popper) {
    max-width: 400px;
    word-break: break-all;
  }
</style>
