<template>
  <div class="notifications">
    <transition-group tag="div" name="appear">
      <Notification
        v-for="notification in displayNotifications"
        :key="notification.id"
        :notification="notification"
        @open="() => $emit('open', notification.id)"
        @close="() => onClose(notification.id)"
      />
      <div v-if="displayNotifications?.length" class="notifications-buttons">
        <Button class="skip-button" primary @click="onCloseAll">
          {{ t('notifications.skip') }}
        </Button>
        <Button class="mute-button" @click="onMuteAll">
          <div class="mute-button-content">
            <UnmuteIcon />
            <div>{{ t('notifications.mute') }}</div>
          </div>
        </Button>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onUnmounted, PropType, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Notification from '@/components/Notification.vue'
  import Button from '@/components/button/Button.vue'
  import UnmuteIcon from '@/icons/unmute.svg'
  import { NotificationInfo } from '@/types'

  export default defineComponent({
    components: {
      Button,
      Notification,
      UnmuteIcon,
    },
    props: {
      notifications: Object as PropType<Array<NotificationInfo>>,
    },
    emits: ['open', 'toggleMuteAll'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { notifications } = toRefs(props)

      const displayNotifications = computed(() => {
        return notifications?.value?.reduceRight<Array<NotificationInfo>>(
          (prev, notification) => {
            if (prev.length === 3) {
              onClose(prev[0].id)
              prev.shift()
            }
            if (
              prev.length < 3 &&
              notification.mutedAt !== notification.createdAt
            ) {
              prev.push(notification)
            }
            return prev
          },
          [],
        )
      })

      const onClose = (notificationId: string) => {
        const notification = notifications?.value?.find(
          (item) => item.id === notificationId,
        )
        if (notification) {
          notification.mutedAt = notification.createdAt
        }
      }

      const onCloseAll = () => {
        displayNotifications?.value?.forEach((notification) =>
          onClose(notification.id),
        )
      }

      const onMuteAll = () => {
        onCloseAll()
        emit('toggleMuteAll')
      }

      onUnmounted(() => onCloseAll())

      return {
        displayNotifications,
        onClose,
        onCloseAll,
        onMuteAll,
        t,
      }
    },
  })
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';

  .notifications {
    width: 300px;
    z-index: 10;
  }

  .notifications-buttons {
    display: flex;
    width: 100%;
    margin: 8px 0;
    gap: 12px;

    .skip-button {
      flex: 1;
    }

    .mute-button {
      width: auto;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }

    .mute-button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      fill: $secondary-text;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .appear-enter-from,
  .appear-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
  .appear-enter-active {
    transition: all 0.1s ease-out;
  }
  .appear-leave-active {
    transition: all 0.2s ease-out;
  }
</style>
