<template>
  <div class="search-bar-container">
    <SearchIcon class="search-icon" />
    <input
      ref="searchInput"
      v-model="searchField"
      autofocus
      type="text"
      class="search-bar-input"
      :placeholder="placeholder"
      @keyup.enter="search"
      @keyup.esc="escapeSearchInput"
    />
    <SearchCross
      v-if="searchField"
      class="cross-icon"
      role="button"
      @click="clearSearchInput"
    />
  </div>
</template>

<script lang="ts">
  import debounce from 'lodash/debounce'
  import { defineComponent, ref, onActivated, toRefs, watch } from 'vue'

  import SearchCross from '@/icons/cross.svg'
  import SearchIcon from '@/icons/search.svg'

  const SearchBar = defineComponent({
    components: {
      SearchIcon,
      SearchCross,
    },
    props: {
      placeholder: {
        type: String,
      },
      shouldClear: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['search'],
    setup(props, { emit }) {
      const { shouldClear } = toRefs(props)
      const searchInput = ref()
      const searchField = ref<string>('')

      onActivated(() => {
        if (shouldClear.value) {
          clearInput()
        }
        searchInput.value.focus()
      })

      const search = () => {
        emit('search', searchField.value)
      }

      const debouncedSearch = debounce(search, 500)

      const clearInput = () => {
        searchField.value = ''
        emit('search', searchField.value)
      }

      const clearSearchInput = () => {
        clearInput()
        searchInput.value.focus()
      }

      const escapeSearchInput = () => {
        clearInput()
        searchInput.value.blur()
      }

      watch(searchField, () => debouncedSearch())

      return {
        searchField,
        searchInput,
        search,
        clearSearchInput,
        escapeSearchInput,
      }
    },
  })
  export default SearchBar
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .search-bar-container {
    padding: 0 16px;
    color: $secondary-text;
    fill: $secondary-text;
    display: flex;
    align-items: center;
    flex: 1;
    user-select: none;

    .search-icon {
      width: 18px;
      height: 18px;
    }

    &:focus-within {
      .search-icon {
        fill: $secondary-text-hover;
      }
    }

    .cross-icon {
      cursor: pointer;
      width: 11px;
      height: 11px;
    }
  }

  .search-bar-input {
    @include font-body-2;
    flex: 1;
    border: none;
    padding: 0 1em;
    outline: none;
    line-height: 17px;
    width: 100%;

    &::placeholder {
      color: $secondary-text;
    }
  }
</style>
