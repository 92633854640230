<template>
  <div class="conversation-header-container">
    <div class="conversation-info-container">
      <div class="conversation-avatar">
        <Avatar
          :name="isGroup && conversationName"
          :src="
            isGroup
              ? currentConversation?.messages?.nodes[0]?.user.avatar
              : currentConversation?.avatarUrl
          "
          :lastSeen="!isGroup ? lastSeenAt : ''"
          :status="!isGroup ? status : ''"
          :group="isGroup"
          :mute="currentConversation?.mute"
          :archived="
            (currentConversation?.type === 'PRIVATE' &&
              !currentConversation?.users?.edges[0]) ||
            currentConversation?.users?.edges[0]?.node.archived ||
            currentConversation?.messages?.nodes[0]?.user.archived
          "
        />
      </div>
      <div class="conversation-info">
        <div class="conversation-name-container">
          <div v-if="currentConversation?.type === 'GROUP'" class="sync-icon">
            <SynchronizeIcon />
          </div>
          <Tooltip placement="left">
            <span
              class="conversation-name"
              :class="{
                mute: currentConversation?.mute,
                truncate: !buttons.includes('fullscreen'),
                'fullscreen-name': !buttons.includes('fullscreen'),
              }"
              v-html="conversationName"
            ></span>
            <template v-slot:content>
              <span v-html="conversationName" />
            </template>
          </Tooltip>
          <div v-if="currentConversation?.mute" class="icon">
            <UnmuteIcon />
          </div>
        </div>
        <div v-if="isGroup">
          <span class="conversation-status">
            {{
              t('group.participants', {
                count: (currentConversation?.users?.totalCount ?? 0) + 1,
              })
            }}
          </span>
        </div>
        <div v-else>
          <span
            v-if="
              !currentConversation?.users?.edges[0] ||
              currentConversation?.users?.edges[0]?.node?.archived
            "
            class="conversation-status"
          >
            {{ t('status.deactivated') }}
          </span>
          <span v-else-if="isOffline" class="conversation-status">
            {{
              `${
                lastSeenAt
                  ? t('status.offline', {
                      date: dateFormat(lastSeenAt, locale),
                    })
                  : ''
              }`
            }}
          </span>
          <span v-else-if="isAway" class="conversation-status">
            {{ t('status.away') }}
          </span>
          <span v-else class="conversation-status">
            {{ t('status.online') }}
          </span>
        </div>
      </div>
    </div>
    <div class="conversation-icons-container">
      <Tooltip
        v-if="buttons.includes('fullscreen')"
        :content="t('tooltips.fullScreen')"
        placement="bottom"
      >
        <div class="conversation-icon fullscreen-icon" @click="onFullscreen">
          <FullscreenIcon />
        </div>
      </Tooltip>

      <div
        v-if="currentConversation?.id !== '0'"
        class="conversation-icon"
        :class="{ kebab: true }"
        @click.stop="onOpenDropdown($event)"
      >
        <KebabIcon />
      </div>
    </div>
    <transition name="dropdown" mode="out-in">
      <ConversationDropdown
        v-if="popupVisible"
        v-mousedown-outside="onHideDropdown"
        :style="style"
        :mute="currentConversation?.mute"
        :group="isGroup"
        :creator="currentConversation?.creator?.id === currentUser?.id"
        :hideGroupInfoButton="hideGroupInfoButton"
        :hideSearchButton="hideSearchButton"
        :hideLeaveButton="currentConversation?.type !== 'CUSTOM'"
        :showMute="showMute"
        :conversationId="currentConversation?.id"
        @hide="onHideDropdown"
        @toggleGroupInfo="$emit('toggleGroupInfo')"
        @setConversationMute="$emit('setConversationMute')"
        @onDialogOpen="$emit('onDialogOpen')"
        @onSearchMessage="$emit('onSearchMessage')"
      />
    </transition>
    <ConversationDropdown
      ref="headerDropdownRef"
      v-mousedown-outside="onHideDropdown"
      class="chat-dropdown-hidden"
      :style="style"
      :mute="currentConversation?.mute"
      :group="isGroup"
      :creator="currentConversation?.creator?.id === currentUser?.id"
      :hideGroupInfoButton="hideGroupInfoButton"
      :hideSearchButton="hideSearchButton"
      :hideLeaveButton="currentConversation?.type !== 'CUSTOM'"
      :showMute="showMute"
      @hide="onHideDropdown"
      @toggleGroupInfo="$emit('toggleGroupInfo')"
      @onSearchMessage="$emit('onSearchMessage')"
    />
  </div>
</template>

<script lang="ts">
  import { Emitter } from 'mitt'
  import {
    defineComponent,
    PropType,
    toRefs,
    computed,
    Ref,
    ref,
    inject,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Avatar from '@/components/Avatar.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import useContextMenu from '@/compositions/message/useContextMenu'
  import useStatus from '@/compositions/useStatus'
  import FullscreenIcon from '@/icons/fullscreen.svg'
  import KebabIcon from '@/icons/kebab.svg'
  import SynchronizeIcon from '@/icons/synchronize.svg'
  import UnmuteIcon from '@/icons/unmute.svg'
  import { DialogType, UserType, EmitterEvents } from '@/types'
  import { dateFormat, getConversationName } from '@/utils'

  import ConversationDropdown from './ConversationDropdown.vue'

  const ConversationHeader = defineComponent({
    components: {
      Avatar,
      FullscreenIcon,
      KebabIcon,
      ConversationDropdown,
      UnmuteIcon,
      Tooltip,
      SynchronizeIcon,
    },
    directives: {
      'mousedown-outside': {
        beforeMount: (el, binding) => {
          el.clickOutsideEvent = (event: MouseEvent) => {
            if (!(el == event.target || el.contains(event.target))) {
              binding.value()
            }
          }
          document.addEventListener('mousedown', el.clickOutsideEvent)
        },
        unmounted: (el) => {
          document.removeEventListener('mousedown', el.clickOutsideEvent)
        },
      },
    },
    props: {
      currentConversation: {
        type: Object as PropType<DialogType>,
      },
      buttons: {
        type: Array,
        default: () => [],
      },
      conversationBodyContainerRef: HTMLDivElement,
      hideGroupInfoButton: {
        type: Boolean,
      },
      hideSearchButton: {
        type: Boolean,
      },
    },
    emits: [
      'toggleGroupInfo',
      'setConversationMute',
      'onDialogOpen',
      'onSearchMessage',
      'onFullscreen',
    ],
    setup(props) {
      const { t, locale } = useI18n()
      const { currentConversation, conversationBodyContainerRef } =
        toRefs(props)
      const emitter = inject<Emitter<EmitterEvents>>('emitter')

      const lastSeenAt = computed<string | undefined>(
        () => currentConversation?.value?.users?.edges[0]?.node?.lastSeenAt,
      )
      const status = computed<string | undefined>(
        () => currentConversation?.value?.users?.edges[0]?.node?.status,
      )
      const headerDropdownRef = ref()
      const dropdownContainer = computed(() => headerDropdownRef?.value?.$el)

      const [isAway, isOffline] = useStatus(lastSeenAt, status)

      const [
        popupVisible,
        widgetPosition,
        selectedMessage,
        onOpenDropdown,
        onHideDropdown,
        style,
      ] = useContextMenu(conversationBodyContainerRef, dropdownContainer)

      const currentUser = inject<Ref<UserType>>('currentUser')

      const showMute = computed<boolean>(() => {
        if (
          currentConversation?.value?.type === 'PRIVATE' &&
          (!currentConversation?.value?.users?.edges[0] ||
            currentConversation?.value?.users?.edges[0]?.node?.archived)
        ) {
          return false
        }

        return true
      })

      const defaultConversationName = computed(() => {
        if (currentConversation?.value?.users && currentUser?.value.id) {
          const { name, count } = getConversationName(
            currentConversation?.value?.users,
            currentUser?.value.id,
          )

          return count
            ? t('conversation.andMore', { name, count })
            : t('conversation.andYou', { name })
        }

        return ''
      })

      const conversationName = computed(() => {
        return currentConversation?.value?.name || defaultConversationName.value
      })

      const onFullscreen = () => {
        if (emitter) {
          emitter.emit('onFullscreen')
        }
      }

      const isGroup = computed<boolean>(() => {
        return (
          currentConversation?.value?.type === 'GROUP' ||
          currentConversation?.value?.type === 'CUSTOM'
        )
      })

      return {
        isAway,
        isOffline,
        lastSeenAt,
        status,
        popupVisible,
        widgetPosition,
        onOpenDropdown,
        onHideDropdown,
        selectedMessage,
        headerDropdownRef,
        style,
        currentUser,
        showMute,
        conversationName,
        onFullscreen,
        isGroup,
        locale,
        t,
      }
    },
    methods: {
      dateFormat,
    },
  })

  export default ConversationHeader
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .conversation-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 14px 0 24px;

    .conversation-info-container {
      display: flex;
      width: calc(100% - 110px);

      .conversation-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .conversation-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 0 4px 8px;
        width: 100%;

        .conversation-name-container {
          display: flex;
          align-items: center;
          width: 100%;

          .conversation-name {
            @include font-body-1;
            font-weight: $font-weight-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            display: block;
            width: 100%;
            max-width: unset;

            &.fullscreen-name {
              display: block;
              width: 100%;
              max-width: unset;
            }

            &.mute {
              color: $secondary-text;
            }
          }

          .sync-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            fill: $secondary-text;
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }

          .icon {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            fill: $secondary-text;
            transform: scale(0.7);
            width: 19px;
            height: 19px;
            min-width: 19px;
          }
        }

        .conversation-status {
          @include font-body-2;
          line-height: 17px;
          color: $secondary-text;
          white-space: nowrap;
        }
      }
    }

    .conversation-icons-container {
      display: flex;

      .conversation-icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: content-box;

        &:hover {
          fill: $secondary-text-hover;
        }

        &.fullscreen-icon {
          width: 14px;
          height: 14px;
        }

        &.kebab {
          width: 4px;
          height: 16px;
        }
      }
    }
  }

  .chat-dropdown-hidden {
    visibility: hidden;
    opacity: 0;
  }

  :deep(.tooltip-container) {
    overflow: hidden;
  }

  :deep(.inline-block) {
    width: 100%;
  }

  :deep(.popper) {
    width: unset;
    max-width: 400px;
    word-break: break-all;
  }

  .dropdown-enter-from,
  .dropdown-leave-to {
    transform: scale(0.1);
    transform-origin: top left;
  }

  .dropdown-enter-active {
    transition: all 0.2s ease-out;
  }

  .dropdown-leave-active {
    transition: all 0.2s ease-in;
  }
</style>
