<template>
  <div :class="{ 'header-container': true, error: !withSearch && errors }">
    <div
      v-click-away="cancelEdit"
      class="heading-row"
      @mousedown="(e) => $emit('onDragWidget', e)"
    >
      <span v-if="backAction" class="back-arrow-container">
        <BackArrowButton data-testid="back-arrow" @click="onBackArrowClick" />
      </span>
      <div class="head-info">
        <div v-if="isSyncGroup" data-testid="sync-icon" class="sync-icon">
          <SynchronizeIcon />
        </div>
        <div v-if="edit" class="edit-input-wrapper">
          <input
            ref="titleInputRef"
            type="text"
            :class="{ 'edit-input': true, error: errors }"
            :value="text"
            @input="validateEdit"
            @keyup.esc="() => cancelEdit()"
            @keyup.enter="rename"
            @mousedown.stop=""
          />
          <div v-if="errors" class="edit-input-errors">
            {{ errors[0] }}
          </div>
        </div>
        <div
          v-else
          class="head-label"
          :class="{
            mute,
          }"
          v-html="title"
        ></div>
        <div
          v-if="!edit && !loading && mute"
          data-testid="mute-icon"
          class="unmute-icon"
        >
          <UnmuteIcon />
        </div>
        <button
          v-show="editable && !edit && !loading"
          class="edit-button"
          @click="onEdit"
        >
          <EditIcon />
          {{ t('group.rename') }}
        </button>
      </div>

      <div
        v-if="edit && !loading"
        :class="{
          'edit-done-button': true,
          disabled: !!errors,
        }"
      >
        <DoneIcon @click="rename" />
      </div>

      <div v-if="loading" data-testid="loading-icon" class="loading-icon">
        <Spinner primary size="20" />
      </div>

      <div
        v-if="!edit && !loading && contactsIds && contactsIds.length"
        class="selected"
      >
        {{ t('add.selected', { count: contactsIds.length }) }}
      </div>

      <div
        v-if="!edit && !loading && buttons.includes('contacts')"
        class="contacts-button"
      >
        <Tooltip :content="t('tooltips.contacts')" placement="bottom">
          <PeopleIcon
            data-testid="contacts-button"
            @click="$emit('toContactsList')"
          />
        </Tooltip>
      </div>

      <div
        v-if="!edit && buttons.includes('create')"
        class="new-chat-button"
        @click="$emit('toNewChat')"
      >
        <Tooltip :content="t('tooltips.new')" placement="bottom">
          <NewMessageIcon data-testid="new-chat-button" />
        </Tooltip>
      </div>

      <div
        v-if="!edit && !loading && buttons.includes('mute')"
        class="mute-button"
        @click="$emit('toggleMuteAll', !muteAll)"
      >
        <Tooltip
          :content="
            muteAll
              ? t('tooltips.notificationsDisabled')
              : t('tooltips.notificationsEnabled')
          "
          data-testid="mute-button"
          placement="bottom"
        >
          <MuteIcon v-if="!muteAll" />
          <UnmuteIcon v-else />
        </Tooltip>
      </div>

      <div
        v-if="!edit && !loading && buttons.includes('fullscreen')"
        class="fullscreen-button"
        @click="onFullscreen"
      >
        <Tooltip :content="t('tooltips.fullScreen')" placement="bottom">
          <FullscreenIcon data-testid="fullscreen-button" />
        </Tooltip>
      </div>
    </div>
    <div v-if="withSearch" class="search-row">
      <SearchBar
        :placeholder="placeholder"
        shouldClear
        @search="(text: string) => $emit('search', text)"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { Emitter } from 'mitt'
  import { defineComponent, PropType, ref, toRefs, inject, computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Spinner from '@/components/Spinner.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import BackArrowButton from '@/components/button/BackArrowButton.vue'
  import useEditableText from '@/compositions/useEditableText'
  import DoneIcon from '@/icons/done.svg'
  import EditIcon from '@/icons/edit.svg'
  import FullscreenIcon from '@/icons/fullscreen.svg'
  import MuteIcon from '@/icons/mute.svg'
  import NewMessageIcon from '@/icons/newMessage.svg'
  import PeopleIcon from '@/icons/people.svg'
  import SynchronizeIcon from '@/icons/synchronize.svg'
  import UnmuteIcon from '@/icons/unmute.svg'
  import { HeaderType, EmitterEvents } from '@/types'

  import SearchBar from './SearchBar.vue'

  const MainHeader = defineComponent({
    directives: {
      clickAway: {
        beforeMount: (el, binding) => {
          el.clickAwayEvent = (event: MouseEvent) => {
            if (!(el == event.target || el.contains(event.target))) {
              binding.value()
            }
          }
          document.addEventListener('click', el.clickAwayEvent)
        },
        unmounted: (el) => {
          document.removeEventListener('click', el.clickAwayEvent)
        },
      },
    },
    components: {
      BackArrowButton,
      DoneIcon,
      EditIcon,
      FullscreenIcon,
      NewMessageIcon,
      PeopleIcon,
      MuteIcon,
      UnmuteIcon,
      SearchBar,
      Spinner,
      Tooltip,
      SynchronizeIcon,
    },
    props: {
      backAction: String,
      title: String as PropType<HeaderType>,
      searchPlaceholder: String || undefined,
      buttons: {
        type: Array,
        default: () => [],
      },
      withSearch: {
        type: Boolean,
        default: true,
      },
      muteAll: Boolean,
      mute: Boolean,
      contactsIds: {
        type: Array as PropType<Array<string>>,
      },
      editable: Boolean,
      loading: Boolean,
      shouldClear: {
        type: Boolean,
        default: false,
      },
      isSyncGroup: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'search',
      'rename',
      'toContactsList',
      'toConversation',
      'toConversationsList',
      'toNewChat',
      'onDragWidget',
      'toggleMuteAll',
      'onFullscreen',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const { title, searchPlaceholder } = toRefs(props)
      const titleInputRef = ref()
      const emitter = inject<Emitter<EmitterEvents>>('emitter')

      const { text, edit, errors, onEdit, doneEdit, cancelEdit, validateEdit } =
        useEditableText(titleInputRef, title)

      const rename = () => {
        doneEdit(() => {
          emit('rename', text.value)
        })
      }

      const { backAction } = toRefs(props)
      const onBackArrowClick = () => {
        edit.value = false
        if (backAction?.value) {
          emit(
            backAction.value as
              | 'toConversationsList'
              | 'toContactsList'
              | 'toConversation',
          )
        }
      }

      const placeholder = computed<string>(() => {
        if (searchPlaceholder?.value) {
          return searchPlaceholder?.value
        }
        return title?.value === t('conversations.header')
          ? t('conversations.searchPlaceholder')
          : t('contacts.searchPlaceholder')
      })

      const onFullscreen = () => {
        if (emitter) {
          emitter.emit('onFullscreen')
        }
      }

      return {
        titleInputRef,
        text,
        edit,
        errors,
        onEdit,
        cancelEdit,
        validateEdit,
        rename,
        onBackArrowClick,
        placeholder,
        onFullscreen,
        t,
      }
    },
  })

  export default MainHeader
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .header-container {
    border-bottom: 1px solid $border;
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;

    &.error {
      height: 70px;
    }

    & .heading-row {
      display: flex;
      align-items: center;
      padding: 10px 16px;

      .selected {
        @include font-caption-3;
        font-weight: $font-weight-bold;
        color: $secondary-text;
        white-space: nowrap;
      }
    }

    & .search-row {
      height: 42px;
      display: flex;
      align-items: center;
    }
  }

  .head-info {
    flex: 1;
    display: flex;
    align-items: center;
    width: 230px;

    .sync-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      fill: $secondary-text;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .head-label {
      @include font-caption-1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      &.mute {
        color: $secondary-text;
      }
    }

    .unmute-icon {
      display: flex;
      width: 19px;
      height: 19px;
      margin-left: 4px;
      fill: $secondary-text;
    }
  }

  .new-chat-button {
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;

      &:hover {
        fill: $secondary-text-hover;
      }
    }
  }

  .fullscreen-button {
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      fill: $secondary-text-hover;
    }
  }

  .contacts-button {
    cursor: pointer;
    margin-right: 22px;

    svg {
      width: 20px;
      height: 14px;

      &:hover {
        fill: $secondary-text-hover;
      }
    }
  }

  .mute-button {
    cursor: pointer;
    margin-left: 24px;

    svg {
      width: 24px;
      height: 24px;

      &:hover {
        fill: $secondary-text-hover;
      }
    }
  }

  .back-arrow-container {
    margin-right: 1em;

    svg {
      width: 20px;
      height: 14px;
    }
  }

  .edit-input {
    @include font-caption-1;
    width: 100%;
    border: 1px solid $secondary-text-hover;
    border-radius: 3px;
    padding: 0 8px;
    outline: none;

    &.error {
      border: 1px solid $error;
    }
  }

  .edit-done-button {
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      fill: $secondary-text-hover;
    }
    &.disabled {
      fill: $secondary-text;
    }
  }

  .edit-button {
    @include font-caption-3;
    color: $secondary-text;
    background: none;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 4px;

    &:hover {
      color: $secondary-text-hover;

      svg {
        fill: $secondary-text-hover;
      }
    }

    svg {
      fill: $secondary-text;
      width: 12px;
      height: 12px;
      padding: 6px;
      box-sizing: content-box;
    }
  }

  .edit-input-errors {
    @include font-caption-3;
    position: absolute;
    color: $error;
  }

  .loading-icon {
    cursor: progress;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
</style>
