<template>
  <div class="group-settings-container">
    <MainHeader
      :title="conversationName"
      backAction="toConversation"
      :withSearch="false"
      :buttons="['fullscreen']"
      :mute="currentConversation?.mute"
      :editable="currentConversation?.creator?.id === currentUser?.id"
      :loading="loading"
      :isSyncGroup="currentConversation?.type === 'GROUP'"
      @toConversation="toConversation"
      @onDragWidget="(e: MouseEvent) => $emit('onDragWidget', e)"
      @rename="chatTitleChange"
    />
    <div class="group-settings-info">
      <div class="notifications-container">
        <div class="notifications-status">
          <span class="title">{{ t('group.mute') }}</span>
          <div class="toggle">
            <ToggleButton v-model="currentConversation.mute" />
          </div>
        </div>
      </div>
      <div class="participants-container">
        <div class="participants-header">
          <span class="amount">
            {{
              t('group.participants', {
                count: (currentConversation?.users?.totalCount ?? 0) + 1,
              })
            }}
          </span>
          <div
            v-if="currentConversation?.creator?.id === currentUser?.id"
            @click="$emit('toNewChat', true)"
          >
            <Tooltip :content="t('tooltips.add')" placement="left">
              <UserAddIcon />
            </Tooltip>
          </div>
        </div>
        <div class="participants-list">
          <div class="participants" @scroll="handleScroll">
            <Contact
              v-for="contact in conversation?.users.edges"
              :key="contact.node.id"
              :contact="contact.node"
              :input="''"
              :isCreator="currentConversation?.creator?.id === contact.node.id"
              :canRemove="
                currentConversation?.creator?.id === currentUser?.id &&
                contact.node.id !== currentUser?.id
              "
              :canLeave="
                contact.node.id === currentUser?.id &&
                currentConversation?.type === 'CUSTOM' &&
                currentConversation?.creator?.id !== currentUser?.id
              "
              :isCurrentUser="contact.node.id === currentUser?.id"
              @leave="onDialogOpen"
              @removeUser="
                removeUserFromConversation({
                  conversationId: currentConversation?.id ?? '0',
                  removeId: contact.node.id,
                })
              "
              @click="
                currentUser?.id !== contact.node.id &&
                  $emit('toConversation', { user: contact.node })
              "
            />
            <Blur />
          </div>
          <div
            v-if="removeLoading || groupInfoLoading"
            class="spinner-container"
          >
            <Spinner primary />
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-if="dialog"
      :title="t('popups.leave.title')"
      :confirmText="t('popups.leave.confirmText')"
      :loading="leaveLoading"
      @confirm="onDialogConfirm(leaveConversation)"
      @cancel="onDialogClose"
    />
  </div>
</template>

<script lang="ts">
  import { useMutation } from '@vue/apollo-composable'
  import {
    defineComponent,
    PropType,
    Ref,
    toRefs,
    inject,
    watch,
    computed,
  } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Blur from '@/components/Blur.vue'
  import Contact from '@/components/Contact.vue'
  import Dialog from '@/components/Dialog.vue'
  import Spinner from '@/components/Spinner.vue'
  import Tooltip from '@/components/Tooltip.vue'
  import ToggleButton from '@/components/button/ToggleButton.vue'
  import MainHeader from '@/components/header/MainHeader.vue'
  import useConversationCreate from '@/compositions/conversation/useConversationCreate'
  import useConversationDelete from '@/compositions/conversation/useConversationDelete'
  import useGroupInfo from '@/compositions/conversation/useGroupInfo'
  import useDialog from '@/compositions/useDialog'
  import useHandleScroll from '@/compositions/useHandleScroll'
  import {
    UpdateConversationType,
    UPDATE_CONVERSATION,
  } from '@/graphql/conversations/mutations'
  import UserAddIcon from '@/icons/userAdd.svg'
  import { DialogType, UserType } from '@/types'
  import { getConversationName } from '@/utils'
  import {
    convertCharacterSequenceToSymbol,
    convertSymbolToCharacterSequence,
  } from '@/utils/messages/convertSpecialCharacters'

  const GroupChatSettings = defineComponent({
    components: {
      Blur,
      Contact,
      MainHeader,
      ToggleButton,
      Tooltip,
      UserAddIcon,
      Dialog,
      Spinner,
    },
    props: {
      currentConversation: {
        type: Object as PropType<DialogType>,
        required: true,
      },
      conversations: Object as PropType<Array<DialogType>>,
    },
    emits: [
      'toConversation',
      'toggleGroupInfo',
      'setConversationMute',
      'toNewChat',
      'refetchConversations',
      'toConversationsList',
      'onDragWidget',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const currentUser = inject<Ref<UserType>>('currentUser')
      const { currentConversation, conversations } = toRefs(props)

      const {
        conversation,
        refetchData,
        loadMore,
        loading: groupInfoLoading,
      } = useGroupInfo(currentConversation?.value?.id, 5)

      const handleScroll = useHandleScroll(loadMore)

      const {
        updateConversation: removeUserFromConversation,
        updateLoading: removeLoading,
      } = useConversationCreate({
        currentConversation,
        conversations,
      })

      watch(
        () => currentConversation?.value?.id,
        (newId) => refetchData(newId),
      )

      watch(
        () => currentConversation?.value?.users?.totalCount,
        () => {
          refetchData(currentConversation?.value?.id)
        },
      )

      const toConversation = () => {
        emit('toConversation', {
          conversationId: currentConversation?.value?.id,
        })
        if (dialog.value) {
          onDialogClose()
        }
      }

      watch(
        currentConversation,
        (newValue, prevValue) => {
          if (newValue?.id === prevValue?.id) {
            emit(
              'setConversationMute',
              currentConversation?.value?.id,
              newValue?.mute,
            )
          }
        },
        {
          deep: true,
        },
      )

      const { mutate: updateGroupConversation, loading } =
        useMutation<UpdateConversationType>(UPDATE_CONVERSATION)

      const chatTitleChange = async (name: string) => {
        if (currentConversation?.value) {
          currentConversation.value.name =
            convertSymbolToCharacterSequence(name)
        }
        await updateGroupConversation({
          input: {
            conversationId: currentConversation?.value?.id,
            name: convertCharacterSequenceToSymbol(name),
          },
        })
        if (conversations && conversations.value) {
          const conversation = conversations.value.find(
            (conversation) =>
              conversation.id === currentConversation?.value?.id,
          )

          if (conversation) {
            conversation.name = convertSymbolToCharacterSequence(name)
          }
        }
      }

      const { dialog, onDialogOpen, onDialogClose, onDialogConfirm } =
        useDialog()

      const { leaveLoading, leaveGroupConversation } = useConversationDelete()

      const leaveConversation = () => {
        if (currentUser?.value && currentConversation?.value) {
          leaveGroupConversation(
            currentConversation.value.id,
            currentUser.value.id,
            () =>
              emit('refetchConversations', () => emit('toConversationsList')),
          )
        }
      }

      const defaultConversationName = computed(() => {
        if ((currentConversation.value?.users, currentUser?.value?.id ?? '0')) {
          const { name, count } = getConversationName(
            currentConversation.value?.users,
            currentUser?.value?.id ?? '0',
          )

          return count
            ? t('conversation.andMore', { name, count })
            : t('conversation.andYou', { name })
        }

        return ''
      })

      const conversationName = computed(() => {
        return currentConversation?.value?.name || defaultConversationName.value
      })

      return {
        currentUser,
        conversation,
        handleScroll,
        toConversation,
        removeUserFromConversation,
        loading,
        chatTitleChange,
        dialog,
        onDialogOpen,
        onDialogClose,
        onDialogConfirm,
        leaveLoading,
        leaveConversation,
        getConversationName,
        groupInfoLoading,
        removeLoading,
        conversationName,
        t,
      }
    },
  })

  export default GroupChatSettings
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';
  @import '@/styles/_scroll.scss';

  .group-settings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    .group-settings-info {
      width: 100%;
      height: 100%;

      .notifications-container {
        border-bottom: 1px solid $border;
        margin-bottom: 16px;

        .notifications-status {
          display: flex;
          justify-content: space-between;
          padding: 26px 16px;

          .title {
            @include font-caption-2;
          }

          .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .participants-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 300px;

        .participants-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px 8px 16px;
          box-sizing: border-box;
          width: 100%;

          .amount {
            @include font-caption-2;
            color: $secondary-text;
          }

          svg {
            cursor: pointer;
            width: 22px;
            height: 16px;
            fill: $dark-icon;

            &:hover {
              fill: $secondary-text-hover;
            }
          }
        }

        .participants :deep(.contact-container) {
          .contact-item {
            padding: 14px 16px;
          }
        }

        .participants-list {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: hidden;

          .spinner-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: absolute;
            background-color: rgba($background, 0.8);
            width: 100%;
            height: 100%;
          }
        }

        .participants {
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow-y: auto;
        }

        @include scroll;
      }
    }
  }
</style>
