import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { ref, Ref, watch } from 'vue'

import { UPDATE_NOTIFICATION_SETTINGS } from '@/graphql/users/mutations'
import { TAKE_NOTIFICATION_SETTINGS } from '@/graphql/users/queries'
import { NotificationSettingsType } from '@/types'

export default function useMuteAll(): {
  muteAll: Ref<boolean>
  toggleMuteAll: (mute: boolean) => void
} {
  const muteAll = ref(false)

  const { result: notificationResult } = useQuery(
    TAKE_NOTIFICATION_SETTINGS,
    null,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const notificationSettings =
    useResult<NotificationSettingsType>(notificationResult)

  watch(notificationSettings, () => {
    muteAll.value = notificationSettings?.value?.muteAll ?? true
  })

  const { mutate: updateNotificationSettings } = useMutation(
    UPDATE_NOTIFICATION_SETTINGS,
  )

  const toggleMuteAll = (mute: boolean) => {
    muteAll.value = mute
    updateNotificationSettings({ input: { muteAll: mute } })
  }

  return {
    muteAll,
    toggleMuteAll,
  }
}
