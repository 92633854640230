import { gql } from '@apollo/client/core'

import { Cursor, MessageType } from '@/types'

export type MessageReceivedType = {
  messageReceived: Cursor<MessageType>
}

export type MessageUpdatedType = {
  messageUpdated: Cursor<MessageType>
}

export const UPDATE_MESSAGES_IN_CONVERSATION = gql`
  subscription messageReceived($input: MessageFilters) {
    messageReceived(input: $input) {
      cursor
      node {
        id
        conversation {
          id
        }
        user {
          id
          name
          avatar
          archived
        }
        body
        createdAt
        status
        type
        parent {
          id
          body
          createdAt
          user {
            id
            name
            avatar
            archived
          }
        }
        bodyParameters
      }
    }
  }
`

export const UPDATE_MESSAGE = gql`
  subscription messageUpdated($input: MessageFilters) {
    messageUpdated(input: $input) {
      cursor
      node {
        id
        conversation {
          id
        }
        user {
          id
          name
          avatar
          archived
        }
        body
        createdAt
        status
        type
        parent {
          id
          body
          createdAt
          user {
            id
            name
            avatar
            archived
          }
        }
        bodyParameters
      }
    }
  }
`
