<template>
  <div class="reply-container" :class="{ large }">
    <div class="reply-icon-container"><ReplyIcon /></div>
    <div class="body-container">
      <div class="body-user">{{ message.user.name }}</div>
      <span class="body-message" :class="{ large }" v-html="message.body" />
    </div>
    <div class="close-icon-container" :class="{ large }">
      <button class="close-icon" @click="clearReplyMessage">
        <CrossIcon />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  import CrossIcon from '@/icons/cross.svg'
  import ReplyIcon from '@/icons/reply.svg'
  import { MessageType } from '@/types'

  const ReplyMessage = defineComponent({
    components: {
      ReplyIcon,
      CrossIcon,
    },
    props: {
      message: {
        type: Object as PropType<MessageType>,
        required: true,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['clearReplyMessage'],

    setup(_, { emit }) {
      const clearReplyMessage = () => {
        emit('clearReplyMessage', null)
      }

      return {
        clearReplyMessage,
      }
    },
  })

  export default ReplyMessage
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .reply-container {
    display: flex;
    width: 100%;
    max-width: 560px;
    margin-top: 16px;
    cursor: pointer;

    &.large {
      margin-right: 55px;
    }

    .reply-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px 20px 16px;
      border-right: 1px solid $toggle-button;

      svg {
        width: 17px;
        height: 11px;
        fill: $toggle-button;
        transform: rotateY(180deg);
      }
    }

    .body-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 4px 4px 8px;
      width: 100%;

      .body-user {
        @include font-caption-3;
        color: $toggle-button;
        font-weight: $font-weight-bold;
      }

      .body-message {
        @include font-body-2;
        color: $secondary-text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 195px;

        &.large {
          max-width: 450px;
        }
      }
    }

    .close-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 16px;

      &.large {
        padding-right: 0;
      }

      .close-icon {
        cursor: pointer;
        width: 16px;
        height: 16px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        background-color: $secondary-text;

        svg {
          width: 8px;
          height: 8px;
          fill: $background;
        }
      }
    }
  }
</style>
