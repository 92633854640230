import { gql } from '@apollo/client/core'

export const UPDATE_UNREAD_CONVERSATIONS = gql`
  subscription {
    unreadMessages {
      totalUnreadMessagesCount
      unreadByConversation {
        conversationId
        unreadMessagesCount
      }
    }
  }
`
