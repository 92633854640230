<template>
  <div class="archived-input">
    <div class="icon"><InformationIcon /></div>
    <span class="archived-text">{{ t('messages.input.archived') }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'

  import InformationIcon from '@/icons/information.svg'

  const ArchivedMessageInput = defineComponent({
    components: { InformationIcon },
    setup() {
      const { t } = useI18n()

      return { t }
    },
  })

  export default ArchivedMessageInput
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_typography.scss';

  .archived-input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 12px;
    box-sizing: border-box;
    height: 52px;

    .icon {
      width: 17px;
      height: 17px;
      fill: $secondary-text;
    }

    .archived-text {
      @include font-body-2;
      margin-left: 10px;
    }
  }
</style>
