import { ref, onMounted, onBeforeUnmount, watch, Ref, Slots } from 'vue'

export default function useContent(
  slots: Slots,
  popperNode: Ref<HTMLElement | null>,
  content: Ref<string>,
): {
  hasContent: Ref<boolean>
} {
  let observer: MutationObserver | null = null
  const hasContent = ref<boolean>(false)

  onMounted(() => {
    if (slots.content !== undefined || content.value) {
      hasContent.value = true
    }
    if (popperNode.value) {
      observer = new MutationObserver(checkContent)
      observer.observe(popperNode.value, {
        childList: true,
        subtree: true,
      })
    }
  })

  onBeforeUnmount(() => observer && observer.disconnect())

  watch(content, (content) => {
    if (content) {
      hasContent.value = true
    } else {
      hasContent.value = false
    }
  })

  const checkContent = () => {
    if (slots.content) {
      hasContent.value = true
    } else {
      hasContent.value = false
    }
  }

  return {
    hasContent,
  }
}
