import { Ref, ref } from 'vue'

type ScrollMessageProps = {
  messagesListRef: Ref<HTMLDivElement | undefined>
  setMessagesListRef: (
    messageList: HTMLDivElement,
    firstUnread: HTMLDivElement,
  ) => void
  scrollMessagesOnInput: (
    curInputHeight: number,
    prevInputHeight: number,
  ) => void
}

export default function useScrollMessage(): ScrollMessageProps {
  const messagesListRef = ref<HTMLDivElement>()

  const setMessagesListRef = (
    messageList: HTMLDivElement,
    firstUnread: HTMLDivElement,
  ) => {
    messagesListRef.value = messageList
    if (messageList && firstUnread) {
      messageList.scrollTo({
        top: firstUnread.offsetTop - messageList.offsetHeight / 2,
      })
    }
  }

  const maxInputHeight = 180
  const scrollMessagesOnInput = (
    curInputHeight: number,
    prevInputHeight: number,
  ) => {
    if (messagesListRef.value && messagesListRef.value.scrollTop !== 0) {
      if (curInputHeight <= maxInputHeight) {
        const resizeHeight = curInputHeight - prevInputHeight
        messagesListRef.value.scrollTo({
          top: messagesListRef.value.scrollTop + resizeHeight,
        })
      }
    }
  }

  return {
    messagesListRef,
    setMessagesListRef,
    scrollMessagesOnInput,
  }
}
